import { getSearchParam } from '../../../../../helpers/navigation';
import { VIEWS } from '../constants/view';

export const getViewType = (search) => {
  return getSearchParam(search, 'view', VIEWS.table);
};

export const isMapView = (type) => {
  return type === VIEWS.map;
};
