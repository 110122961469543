import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import Select from '../../../../../components/Select';
import { getProductUnitOptions } from '../../../../../helpers/functions/units/productUnit';
import { getPurposeItems } from '../../../../../helpers/analysis';
import type { ProductUnit } from '../../../../../helpers/constants/units/productUnit';
import type { VectorAnalysisMapType } from '../../../../../helpers/constants/entities/vectorAnalysisMap';
import GridSizeAdjustment from '../GridSizeAdjustment';
import UseInterpolationCheckbox from '../../../../../components/OpsPanel/UseInterpolationCheckbox';

import './index.scss';

export default function EquationMapSettings({
  productUnit,
  purpose,
  gridX,
  gridY,
  useInterpolatedData,
  onUseInterpolatedDataChange,
  onProductUnitChange,
  onPurposeChange,
  onGridXChange,
  onGridYChange,
}: {
  productUnit: ProductUnit | '',
  purpose: VectorAnalysisMapType,
  gridX: number,
  gridY: number,
  useInterpolatedData: boolean,
  onUseInterpolatedDataChange: (v: boolean) => void,
  onProductUnitChange: (o: ProductUnit | '' | null) => void,
  onPurposeChange: (o: VectorAnalysisMapType | null) => void,
  onGridXChange: (g: number) => void,
  onGridYChange: (g: number) => void,
}) {
  const { t } = useTranslation();

  const productUnitsOptions = useMemo(() => {
    return getProductUnitOptions(true);
  }, []);

  const purposeOptions = useMemo(() => {
    return getPurposeItems();
  }, []);

  return (
    <div className="equation-map-settings">
      <div className="equation-map-settings__row">
        <Select
          id="product-unit"
          classes={{
            root: 'equation-map-settings__select',
          }}
          displayEmpty
          label={t('zones-ops.equation-based.steps.4.panel.product-unit')}
          options={productUnitsOptions}
          value={productUnit}
          onChange={onProductUnitChange}
        />
        <Select
          id="purpose"
          classes={{
            root: 'equation-map-settings__select',
          }}
          label={t('general.controls.purpose')}
          options={purposeOptions}
          value={purpose}
          onChange={onPurposeChange}
        />
        <GridSizeAdjustment
          gridX={gridX}
          gridY={gridY}
          onGridXChange={onGridXChange}
          onGridYChange={onGridYChange}
        />
      </div>
      <UseInterpolationCheckbox
        checked={useInterpolatedData}
        onChange={onUseInterpolatedDataChange}
      />
    </div>
  );
}
