import React from 'react';
import { useTranslation } from 'react-i18next';

import TextField from '../../TextField';

import './index.scss';

const Panel = ({
  name,
  onPropChange = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <div className="create-3d-panel">
      <TextField
        required
        value={name}
        className="create-3d-panel__title"
        title={t('zones-ops.common.title')}
        onChange={(e) => onPropChange('name', e.target.value)}
      />
    </div>
  );
};

export default Panel;
