import React, { Suspense } from 'react';
import { connect } from 'react-redux';

import Stepper from '../../../../../../../components/Stepper';
import Loading from '../../../../../../../components/Loading';
import { setStep } from '../../../../create3dSlice';
import Panel from '../../../Panel/ZonesMap';
import { getSteps } from '../../../../helpers/functions/steps';
import { selectZonesMapUuid } from '../../../../create3dSelectors';

const Map = React.lazy(() => import('../../../Map'));

const ZonesMap = ({
  nextDisabled,
  onClickNext,
  onClickBack,
}) => {
  const steps = getSteps();

  return (
    <Stepper
      showInactive
      activeStep={2}
      steps={steps}
      nextDisabled={nextDisabled}
      onClickNext={onClickNext}
      onClickBack={onClickBack}
    >
      <Panel key="panel" />
      <Suspense
        key="map"
        fallback={<Loading />}
      >
        <Map />
      </Suspense>
    </Stepper>
  );
};

const mapStateToProps = (state) => {
  const zonesMapUuid = selectZonesMapUuid(state);

  return {
    nextDisabled: !zonesMapUuid,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClickNext: () => {
    dispatch(setStep('preview'));
  },
  onClickBack: () => {
    dispatch(setStep('shapeDataSource'));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ZonesMap);
