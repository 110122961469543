import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import BackdropLoading from '../components/Loading/BackdropLoading';
import FieldTitle from '../features/field/containers/FieldTitle';
import Panel from '../features/ui/compareLayers/containers/Panel';
import ToolsPanel from '../features/ui/compareLayers/containers/ToolsPanel';
import Legend from '../features/ui/compareLayers/containers/Legend';
import {
  getFieldLink,
  PAGES_ROOTS,
  getRootLink,
} from '../helpers/navigation';
import { getDocumentTitle } from '../helpers';
import AuthenticationRedirector from '../routes/redirectors/Authentication';
import PricingRedirector from '../routes/redirectors/Pricing';
import {
  selectField,
  selectNotFound,
  selectAllLoading,
} from '../features/field/fieldSelectors';
import NotFound from '../components/NotFound';
import Link from '../components/Link';
import BaseTemplate from './BaseTemplate.jsx';
import useDidMount from '../hooks/useDidMount';
import { reset } from '../features/ui/compareLayers/compareLayersSlice';

const CompareLayers = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loading = useSelector(selectAllLoading);
  const notFound = useSelector(selectNotFound);
  const field = useSelector(selectField);

  useDidMount(() => {
    document.title = getDocumentTitle(t('field.tools-panel.compare-layers'));

    return () => {
      dispatch(reset());
    };
  });

  let headerProps;
  let content = [
    <ToolsPanel key="tools-panel" />,
    <Panel key="panel" />,
    <Legend key="legend" />,
    <Fragment key="loading">
      {loading && <BackdropLoading />}
    </Fragment>,
  ];

  if (field) {
    headerProps = {
      text: t('field.tools-panel.compare-layers'),
      secondaryText: (
        <FieldTitle
          fieldArea={field.area}
          fieldName={<Link to={getFieldLink(field.farmUuid, field.uuid)}>{field.name}</Link>}
        />
      ),
      backUrl: getFieldLink(field.farmUuid, field.uuid),
    };
  }

  if (notFound) {
    headerProps = {};
    content = [
      <NotFound
        key="panel"
        description={t('field.not-found-description')}
        link={
          <Link to={getRootLink(PAGES_ROOTS.fields)}>{t('field.not-found-link')}</Link>
        }
      />,
    ];
  }
  return (
    <AuthenticationRedirector>
      <PricingRedirector>
        <BaseTemplate
          route={PAGES_ROOTS.compareLayers}
          headerProps={headerProps}
        >
          { content }
        </BaseTemplate>
      </PricingRedirector>
    </AuthenticationRedirector>
  );
};

export default CompareLayers;
