import React, { ReactElement } from 'react';

import './index.scss';

export default function PricingTable({
  children,
}: {
  children: ReactElement[],
}) {
  return (
    <div className="pricing-table">
      <div className="pricing-table__container">
        {children}
      </div>
    </div>
  );
}
