import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import { getContactsLink } from '../../../../../../helpers/functions/utils/appConfig';

const NoAccess = ({
  description,
}) => {
  const { t } = useTranslation();
  const DEFAULT_DESCRIPTION = 'restricted-access.no-access.app';

  return (
    <Paper
      square
      classes={{
        root: 'restricted-access-card',
      }}
    >
      <div className="restricted-access-card__container">
        <Typography
          variant="h1"
          classes={{
            root: 'restricted-access-card__title',
          }}
        >
          {t(description || DEFAULT_DESCRIPTION)}
        </Typography>
        <div className="restricted-access-card__info">
          <Typography
            classes={{
              root: 'restricted-access-card__text',
            }}
          >
            <Trans i18nKey="restricted-access.no-access.description">
              Text
              {' '}
              <a
                className="link"
                target="_blank"
                href={getContactsLink()}
              >
                contact us
              </a> text
            </Trans>
          </Typography>
        </div>
      </div>
      <InfoIcon className="restricted-access-card__icon"/>
    </Paper>
  );
};

export default NoAccess;
