import React from 'react';

import FieldsListFilter from '../../../../fieldsList/containers/FieldsListFilter';
import FieldsListTable from '../FieldsListTable';

import './index.scss';

export default function SelectFieldPanel() {
  return (
    <div className="select-field-workflow-panel">
      <FieldsListFilter />
      <FieldsListTable />
    </div>
  );
}
