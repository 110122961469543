import CreateMultiLayerAnalysis from '../../../createMultiLayerAnalysis/containers/Stepper';
import CreateSingleLayerAnalysis from '../../../createSingleLayerAnalysis/containers/Stepper';
import CreateFieldPotentialZones from '../../../createFieldPotentialZones/containers/Stepper';
import CreateStabilityZones from '../../../createStabilityZones/containers/Stepper';
import CrossLayer from '../../../crossLayer/containers/Stepper';
import DrawManually from '../../../drawManuallyWorkflow/containers/Stepper';
import CloneZonesMap from '../../../cloneZonesMap/containers/Stepper';
import EquationBased from '../../../createEquationBasedAnalysis/containers/Stepper';
import { ZonesOpsWorkflow } from '../../helpers/constants/workflows';

const config = {
  createMultiLayerAnalysis: CreateMultiLayerAnalysis,
  createSingleLayerAnalysis: CreateSingleLayerAnalysis,
  createFieldPotentialZones: CreateFieldPotentialZones,
  createStabilityZones: CreateStabilityZones,
  crossLayer: CrossLayer,
  drawManually: DrawManually,
  cloneZonesMap: CloneZonesMap,
  equationBased: EquationBased,
};

const getStepper = (workflow: ZonesOpsWorkflow) => {
  // TODO: fix types when 3d moved to zones-ops
  return config[workflow as keyof typeof config];
};

export default getStepper;
