import React from 'react';
import { useTranslation } from 'react-i18next';

import AuthenticationRedirector from '../routes/redirectors/Authentication';
import PricingRedirector from '../routes/redirectors/Pricing';
import BackdropLoading from '../components/Loading/BackdropLoading';
import ToolsPanel from '../features/ui/uploadData/containers/ToolsPanel';
import Panel from '../features/ui/uploadData/containers/Panel';
import {
  getRootLink,
  PAGES_ROOTS,
} from '../helpers/navigation';
import BaseTemplate from './BaseTemplate.jsx';
import { getDocumentTitle } from '../helpers';
import useDidMount from '../hooks/useDidMount';
import { useGetFarmsQuery } from '../features/farms/farmsAPI';

const UploadData = () => {
  const { t } = useTranslation();
  const {
    isFetching: isFarmsFetching,
  } = useGetFarmsQuery();

  useDidMount(() => {
    document.title = getDocumentTitle(t('field-profiler.tools-panel.upload-data'));
  });

  return (
    <AuthenticationRedirector>
      <PricingRedirector>
        <BaseTemplate
          route={PAGES_ROOTS.upload}
          headerProps={{
            text: t('upload-data.title'),
            backUrl: getRootLink(PAGES_ROOTS.fields),
          }}
        >
          <ToolsPanel key="tools-panel" />
          <Panel key="panel" />
          { isFarmsFetching && <BackdropLoading key="loading" />}
        </BaseTemplate>
      </PricingRedirector>
    </AuthenticationRedirector>
  );
};

export default UploadData;
