import React, { Fragment, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import Typography from '@material-ui/core/Typography';
import EmailSharpIcon from '@material-ui/icons/EmailSharp';

import Button from '../../../../../../../components/Button';
import TextField from '../../../../../../../components/TextField';
import Link from '../../../../../../../components/Link';
import { errorNotify } from '../../../../../../notifications/helpers/functions/notify';
import { CustomError } from '../../../../../../../helpers/functions/utils/errorHandling';
import { View } from '../../../../helpers/constants/authentication';
import { FORGOT_PASSWORD_ERRORS } from '../../../../helpers/constants/errors';
import { getAuthLink } from '../../../../../../../helpers/navigation';
import { prepareEmailAttribute } from '../../../../helpers/functions/authentication';

import '../common/index.scss';
import './index.scss';

const ForgotPasswordPanel = ({
  onLoading,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [email, setEmail] = useState('');
  const [helperText, setHelperText] = useState('');

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setHelperText('');
  };

  const onRecoverClick = () => {
    const preparedEmail = prepareEmailAttribute(email);

    onLoading(true);
    setHelperText('');

    Auth.forgotPassword(preparedEmail)
      .then(() => {
        history.push({
          pathname: getAuthLink(View.forgotPasswordSent),
          state: {
            email: preparedEmail,
          },
        });
      })
      .catch((e) => {
        const error = FORGOT_PASSWORD_ERRORS.find((err) => {
          return err.regexp.test(e.message);
        });

        if (error) {
          setHelperText(error.helperText);
        } else {
          errorNotify({
            error: new CustomError('[UI Authentication] Unable to request forgot password.', {
              cause: e,
            }),
            message: e.message,
            dispatch,
          });
        }
      })
      .finally(() => {
        onLoading(false);
      });
  };

  const recoverButtonDisabled = () => {
    return !email || !!helperText;
  };

  return (
    <Fragment>
      <div className="authentication-panel forgot-password-panel">
        <Typography
          classes={{
            root: 'authentication-panel__header',
          }}
          variant="h1"
        >
          Password recovery
        </Typography>
        <div className="forgot-password-panel__container">
          <Typography
            classes={{
              root: 'authentication-panel__tip',
            }}
          >
            Enter your Email below and we will send a message to reset your password.
          </Typography>
          <div className="forgot-password-panel__textfield-wrapper">
            <TextField
              value={email}
              error={!!helperText}
              helperText={helperText}
              placeholder="Email"
              InputProps={{
                classes: {
                  error: 'textfield__input_error',
                },
                startAdornment: (
                  <EmailSharpIcon
                    fontSize="small"
                    className="authentication-panel__input-icon authentication-panel__input-icon_start"
                  />
                ),
              }}
              FormHelperTextProps={{
                classes: {
                  root: 'authentication-panel__input-helper-text',
                },
              }}
              onChange={handleEmailChange}
            />
            <Button
              className="forgot-password-panel__button"
              variant="contained"
              color="primary"
              disabled={recoverButtonDisabled()}
              onClick={onRecoverClick}
            >
              Recover
            </Button>
          </div>
          <Typography
            classes={{
              root: 'authentication-panel__tip authentication-panel__tip_extra',
            }}
          >
            Please note that password recovery is feasible only for accounts registered with an email address,
            excluding custom methods like MyJohnDeere account, Microsoft Active Directory, or others.
          </Typography>
          <Link
            className="authentication-panel__link forgot-password-panel__link"
            to={getAuthLink(View.logIn)}
          >
            Back to Log in
          </Link>
        </div>
      </div>
    </Fragment>
  );
};

export default ForgotPasswordPanel;
