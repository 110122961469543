import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import { useSelector } from 'react-redux';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import addMonths from 'date-fns/addMonths';
import format from 'date-fns/format';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import Link from '../../../../../../components/Link';
import {
  selectLastPaidOrder,
  selectOrders,
  selectDebitedOrganization,
  selectUuid,
} from '../../../../../user/userSelectors';
import { getEndDate, hasStripePayment } from '../../../../../user/helpers/functions/order';
import { getBrandName, getContactsLink } from '../../../../../../helpers/functions/utils/appConfig';
import { getCustomerPortalLink } from '../../../../../../helpers/functions/utils/vendorConfigs/stripe';
import { PAGES_ROOTS, getRootLink } from '../../../../../../helpers/navigation';

import './index.scss';

const DASH = '—';

export default function NoSubscription() {
  const { t } = useTranslation();
  const lastPaidOrder = useSelector(selectLastPaidOrder);
  const orders = useSelector(selectOrders);
  const debitedOrganization = useSelector(selectDebitedOrganization);
  const userUuid = useSelector(selectUuid);
  const deleteDate = lastPaidOrder ? addMonths(getEndDate(lastPaidOrder)!, 1) : null;
  const formattedDeleteDate = deleteDate ? format(deleteDate, 'LLL d, Y') : null;
  const brandName = getBrandName();
  const isDebitedOrganizationOwner = debitedOrganization?.ownerUuid === userUuid;

  let cardInfoContent;
  let renewSubscriptionLink;

  if (hasStripePayment(orders)) {
    renewSubscriptionLink = (
      <a className="link" target="_blank" href={getCustomerPortalLink()}>link</a>
    );
  } else {
    renewSubscriptionLink = (
      <Link to={getRootLink(PAGES_ROOTS.planSubscription)}>link</Link>
    );
  }

  if (debitedOrganization && !isDebitedOrganizationOwner) {
    const ownerName = debitedOrganization.givenName || debitedOrganization.surname
      ? `${debitedOrganization.givenName} ${debitedOrganization.surname}`
      : DASH;

    cardInfoContent = (
      <>
        <Typography className="restricted-access-card__text">
          {t('restricted-access.no-subscription.description.organization-user.p1')}
        </Typography>
        <Typography className="restricted-access-card__text">
          {t('restricted-access.no-subscription.description.organization-user.p2', {
            brandName,
          })}
        </Typography>
        <div className="no-subscription-card__org-info">
          <Typography className="no-subscription-card__org-info-text">
            {t('restricted-access.no-subscription.description.organization-user.organization')}: {debitedOrganization.name ?? DASH}
          </Typography>
          <Typography className="no-subscription-card__org-info-text">
            {t('user-organizations.user.administrator')}: {ownerName}
          </Typography>
          <Typography className="no-subscription-card__org-info-text">
            {t('user-organizations.user.email')}: {debitedOrganization.email ?? DASH}
          </Typography>
          <Typography className="no-subscription-card__org-info-text">
            {t('user-organizations.user.phone')}: {debitedOrganization.phone ?? DASH}
          </Typography>
        </div>
      </>
    );
  } else {
    cardInfoContent = (
      <>
        <Typography className="restricted-access-card__text">
          {t('restricted-access.no-subscription.description.organization-admin.p1')}
        </Typography>
        <Typography className="restricted-access-card__text">
          <Trans i18nKey="restricted-access.no-subscription.description.organization-admin.p2">
            text
            {{ brandName }}
            text
            {renewSubscriptionLink}
          </Trans>
        </Typography>
        <Typography className="restricted-access-card__text">
          {t('restricted-access.no-subscription.description.organization-admin.p3', {
            deleteDate: formattedDeleteDate,
          })}
        </Typography>
      </>
    );
  }

  return (
    <Paper className="restricted-access-card" square>
      <div className="restricted-access-card__container">
        <Typography variant="h1" className="restricted-access-card__title">
          {t('restricted-access.no-subscription.title')}
        </Typography>
        <div className="restricted-access-card__info">
          {cardInfoContent}
        </div>
        <Typography variant="body2" className="restricted-access-card__disclaimer">
          <Trans i18nKey="restricted-access.contact-us">
            text <a className="link" target="_blank" href={getContactsLink()}>link</a> text
          </Trans>
        </Typography>
      </div>
      <InfoIcon className="restricted-access-card__icon"/>
    </Paper>
  );
}
