import React from 'react';
import { Trans } from 'react-i18next';
import { convertArea } from '@turf/helpers';
import i18n from 'i18next';

import { AreaUnit } from '../features/user/helpers/constants/user';
import { getBrandFullName } from './functions/utils/appConfig';
import { DataClassificationType } from './constants/entities/vectorAnalysisMap';
import { AssetType } from './constants/entities/asset';

export const DEFAULT_POLYGON_MIN_AREA = 1000;
export const DEFAULT_ZONES_CREATION_SETTINGS = {
  name: '',
  numberOfZones: 5,
  polygonMinArea: DEFAULT_POLYGON_MIN_AREA,
  dataClassificationType: DataClassificationType.equalCount,
  useInterpolatedData: true,
};

export const getDocumentTitle = (title) => {
  const prefix = title ? `${title} | ` : '';

  return `${prefix}${getBrandFullName()}`;
};

export const getCopyName = (name, copied) => {
  return copied
    ? `${name} (${i18n.t('zones-ops.common.copy')})`
    : name;
};

export const getSatelliteImagesZonesMapName = (images, index) => {
  let firstYear = '2999';
  let lastYear = '';

  images.forEach((img) => {
    const year = img.satelliteImage.acquisitionDate.slice(0, 4);

    if (year > lastYear) {
      lastYear = year;
    }

    if (year < firstYear) {
      firstYear = year;
    }
  });

  const yearPrefix = firstYear === lastYear
    ? firstYear
    : `${firstYear}-${lastYear}`;

  return `${yearPrefix} ${images.length} ${index}`;
};

export const getZonesMapName = (dataLayer) => {
  const { type } = dataLayer;
  let result = '';

  if (type === AssetType.satelliteImage) {
    result = `${dataLayer.dateStrings.join(', ')} ${dataLayer.index}`;
  } else if (
    type === AssetType.soilDataset
    || type === AssetType.yieldDataset
    || type === AssetType.asAppliedDataset
    || type === AssetType.topographyMap
  ) {
    result = `${dataLayer.name} ${dataLayer.attribute}`;
  }

  return result;
};

export const getI18nAreaUnit = (unit) => {
  let unitPath = 'ac';

  if (unit === AreaUnit.hectares) {
    unitPath = 'ha';
  }

  return unitPath;
};

export const getUserUnitArea = (area, userUnit, areaUnit = AreaUnit.squareMeters) => {
  if (areaUnit === AreaUnit.hectares && userUnit === AreaUnit.acres) {
    return convertArea(area / 100, 'kilometers', 'acres');
  } if (areaUnit === AreaUnit.acres && userUnit === AreaUnit.hectares) {
    return convertArea(area, 'acres', 'kilometers') * 100;
  } if (areaUnit === AreaUnit.squareMeters && userUnit === AreaUnit.hectares) {
    return convertArea(area, 'meters', 'kilometers') * 100;
  } if (areaUnit === AreaUnit.squareMeters && userUnit === AreaUnit.acres) {
    return convertArea(area, 'meters', 'acres');
  }

  return area;
};

export const getAreaUnitLabel = (rawUnit) => {
  const unit = i18n.t(`general.area-unit.${getI18nAreaUnit(rawUnit)}`);

  return (
    <Trans
      i18nKey="general.shared.area"
      unit={unit}
    >
      Area <span className="text_secondary">({{ unit }})</span>
    </Trans>
  );
};

export const isAccessible = (currStatus, minStatus) => {
  return minStatus <= currStatus;
};

export const downloadAsFile = (content, name) => {
  const file = new Blob([content]);
  const anchorNode = document.createElement('a');

  document.body.appendChild(anchorNode);
  anchorNode.setAttribute('href', URL.createObjectURL(file));
  anchorNode.setAttribute('download', name);
  anchorNode.click();
  anchorNode.remove();
};

/**
 * Case-insensitive filtering of Objects array against specified property
 * @param {string} key Item property name to be filtered against
 * @param {Object[]} items Array of items to be filtered
 * @param {string} entry Query string
 * @returns Filtered array of items
 */
export const filterByKey = (key, items, entry) => {
  let filteredItems;

  if (entry) {
    const normalizedEntry = entry.toLowerCase();

    filteredItems = items.filter((item) => {
      return item[key]?.toLowerCase().includes(normalizedEntry);
    });
  } else {
    filteredItems = items;
  }

  return filteredItems;
};
