import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import Panel from '../../../Panel/Settings';
import Stepper from '../../../../../../../components/Stepper';
import Loading from '../../../../../../../components/Loading';
import BackdropLoading from '../../../../../../../components/Loading/BackdropLoading';
import ZonesCreation from '../../../../../../../components/OpsPanel/ZonesCreation';
import { updateSettings } from '../../../../createStabilityZonesSlice';
import {
  goToPrevStep,
} from '../../../../../zonesOps/zonesOpsSlice';
import {
  updateVectorAnalysisMap,
} from '../../../../../../createAnalysis/createAnalysisSlice';
import {
  selectGeneratedVectorAnalysisMap,
  selectSaveInProgress,
} from '../../../../../../createAnalysis/createAnalysisSelectors';
import { selectSettings } from '../../../../createStabilityZonesSelectors';
import useGenerateStabilityZonesVamap from '../../../../hooks/useGenerateStabilityZonesVamap';
import { isNumberOfZonesValueValid } from '../../../../../../createAnalysis/helpers/functions/vamap';

const Map = React.lazy(() => import('../../../Map'));

const SettingsStepper = ({
  steps,
  stepIndex,
  step,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    name,
    numberOfZones,
    polygonMinArea,
    dataClassificationType,
    useInterpolatedData,
  } = useSelector(selectSettings);
  const generatedVectorAnalysisMap = useSelector(selectGeneratedVectorAnalysisMap);
  const saveInProgress = useSelector(selectSaveInProgress);
  const generateVamap = useGenerateStabilityZonesVamap();
  const runAnalysisDisabled = !name || !isNumberOfZonesValueValid(numberOfZones);

  const onClickBack = () => {
    dispatch(goToPrevStep());
  };
  const onClickNext = () => {
    dispatch(updateVectorAnalysisMap({ name }));
  };
  const onZonesOpsPropChange = (prop, value) => {
    dispatch(updateSettings({
      prop,
      value,
    }));
  };
  const onRunAnalysis = () => {
    generateVamap();
  };

  return (
    <>
      <Stepper
        activeStep={stepIndex}
        steps={steps}
        nextDisabled={!generatedVectorAnalysisMap}
        nextLabel={t('zones-ops.common.finish')}
        onClickBack={onClickBack}
        onClickNext={onClickNext}
      >
        <ZonesCreation
          key="tools-panel"
          name={name}
          numberOfZones={numberOfZones}
          dataClassificationType={dataClassificationType}
          polygonMinArea={polygonMinArea}
          useInterpolatedData={useInterpolatedData}
          runAnalysisDisabled={runAnalysisDisabled}
          onPropChange={onZonesOpsPropChange}
          onRunAnalysis={onRunAnalysis}
        />
        <Panel key="panel" />
        <Suspense
          key="map"
          fallback={<Loading />}
        >
          <Map step={step} />
        </Suspense>
      </Stepper>
      {saveInProgress && <BackdropLoading />}
    </>
  );
};

export default SettingsStepper;
