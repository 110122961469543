import React from 'react';
import { connect } from 'react-redux';

import Stepper from '../../../../../../../components/Stepper';
import { setStep } from '../../../../create3dSlice';
import { selectAssetType } from '../../../../create3dSelectors';
import Panel from '../../../Panel/ShapeDataType';
import { getSteps } from '../../../../helpers/functions/steps';

const ShapeDataType = ({
  nextDisabled,
  onClickNext,
}) => {
  const steps = getSteps();

  return (
    <Stepper
      showInactive
      activeStep={0}
      steps={steps}
      nextDisabled={nextDisabled}
      onClickNext={onClickNext}
    >
      <Panel key="panel" />
    </Stepper>
  );
};

const mapStateToProps = (state) => {
  const assetType = selectAssetType(state);

  return {
    nextDisabled: !assetType,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClickNext: () => {
    dispatch(setStep('shapeDataSource'));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShapeDataType);
