import { sendFeatureUsage } from '../../../../../helpers/analytics';
import { Actions, FEATURE } from '../constants/analytics';

export const sendSelectOrganizationUsage = () => {
  sendFeatureUsage(FEATURE, Actions.selectOrganization);
};

export const sendSelectFieldsUsage = () => {
  sendFeatureUsage(FEATURE, Actions.selectFields);
};

export const sendSelectImportTypeUsage = () => {
  sendFeatureUsage(FEATURE, Actions.selectImportType);
};

export const sendSelectImportFiltersTypeUsage = () => {
  sendFeatureUsage(FEATURE, Actions.selectImportFiltersType);
};

export const sendSelectImportFiltersPeriodUsage = () => {
  sendFeatureUsage(FEATURE, Actions.selectImportFiltersPeriod);
};

export const sendImportFieldsDataFilesUsage = () => {
  sendFeatureUsage(FEATURE, Actions.importFieldsWithDataFiles);
};

export const sendImportFieldsFieldBoundariesUsage = () => {
  sendFeatureUsage(FEATURE, Actions.importFieldsFieldBoundaries);
};

export const sendCancelWorkflowUsage = () => {
  sendFeatureUsage(FEATURE, Actions.cancelWorkflow);
};
