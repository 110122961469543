import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  fetchFields,
  loadMoreFields,
} from '../../../../fieldsList/fieldsListSlice';
import {
  selectFields,
  selectIsLoaded,
  selectLastEvaluatedKey,
} from '../../../../fieldsList/fieldsListSelectors';
import { selectLabelsExpanded } from '../../../fieldProfiler/fieldProfilerSelectors';
import { selectApiKey, selectAreaUnit } from '../../../../user/userSelectors';
import useDidMount from '../../../../../hooks/useDidMount';
import { useGetFarmsQuery } from '../../../../farms/farmsAPI';
import {
  getFieldsTableRows,
} from '../../../fieldProfiler/helpers/functions/tableRows';
import FieldProfilerTable from '../../../fieldProfiler/components/Table';
import { getBaseTableColumns } from '../../../fieldProfiler/helpers/functions/tableColumns';
import {
  selectSelectedFieldsUuids,
} from '../../batchAnalyticsSelectors';
import { getRowsSelection } from '../../../tableView/helpers/functions/rows';
import { setFieldsSelection, toggleFieldSelection } from '../../batchAnalyticsSlice';
import type { TransformedField } from '../../../../field/types/field';
import { DefaultFieldTableRow } from '../../../fieldProfiler/types/tableRow';

export default function FieldsListTable() {
  const dispatch = useDispatch();
  const {
    data: farms,
    isFetching: isFarmsFetching,
  } = useGetFarmsQuery();

  const selectedFieldsUuids = useSelector(selectSelectedFieldsUuids);
  const apiKey = useSelector(selectApiKey);
  const areaUnit = useSelector(selectAreaUnit);
  const isFieldsLoaded = useSelector(selectIsLoaded);
  const lastEvaluatedKey = useSelector(selectLastEvaluatedKey);
  const fields = useSelector(selectFields);
  const labelsExpanded = useSelector(selectLabelsExpanded);

  const handleHeadCheckboxClick = (selected: boolean) => {
    const uuids = fields.map((field: TransformedField) => {
      return {
        fieldUuid: field.uuid,
        farmUuid: field.farmUuid,
      };
    });

    dispatch(setFieldsSelection({
      uuids,
      selected,
    }));
  };

  const handleRowCheckboxClick = ({ id, farmUuid }: DefaultFieldTableRow) => {
    dispatch(toggleFieldSelection({
      fieldUuid: id,
      farmUuid: farmUuid || '',
    }));
  };

  const handleLoadMoreRows = () => {
    dispatch(loadMoreFields());
  };

  useDidMount(() => {
    dispatch(fetchFields());
  });

  const rows = getFieldsTableRows({
    fields,
    farms: farms || [],
    apiKey,
    checkedUuids: selectedFieldsUuids,
  });
  const rowsSelection = getRowsSelection(rows);
  const columns = getBaseTableColumns({
    selectable: true,
    areaUnit,
    rowsSelection,
    onHeadCheckboxClick: handleHeadCheckboxClick,
    onRowCheckboxClick: handleRowCheckboxClick,
  });

  return (
    <FieldProfilerTable
      rows={rows}
      columns={columns}
      labelsExpanded={labelsExpanded}
      loadComplete={!lastEvaluatedKey}
      loading={!isFieldsLoaded || isFarmsFetching}
      onLoadMoreRows={handleLoadMoreRows}
    />
  );
}
