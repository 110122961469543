import React from 'react';
import { withTranslation, Trans } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import clsx from 'clsx';

import PopupHeader from '../PopupHeader';
import Button from '../../Button';
import TextField from '../../TextField';

import './index.scss';

const DeleteEntity = ({
  t,
  i18nKey = '',
  i18nDescriptionKey,
  entityName = '',
  shouldDisplayPinsWarning,
  onCancel = () => {},
  onConfirm = () => {},
}) => {
  const confirmWord = t('general.popups.delete-confirm-word');
  const [confirmButtonDisabled, setConfirmButtonDisabled] = React.useState(true);

  const handleConfirmInputChange = (e) => {
    setConfirmButtonDisabled(e.target.value !== confirmWord);
  };

  return (
    <Dialog open>
      <PopupHeader
        title={t(`general.popups.delete-entity.title.${i18nKey}`)}
        onCancel={onCancel}
      />
      <DialogContent>
        <DialogContentText
          classes={{
            root: clsx('delete-entity-popup__content-text', {
              'delete-entity-popup__content-text_first_line': shouldDisplayPinsWarning,
            }),
          }}
        >
          <Trans i18nKey={`general.popups.delete-entity.description.${i18nDescriptionKey || 'default'}`}>
            Text <span className="delete-entity-popup__content-text_highlighted">{{ entityName }}</span> {{ confirmWord }} text
          </Trans>
        </DialogContentText>

        {shouldDisplayPinsWarning
          && (
            <DialogContentText
              classes={{
                root: 'delete-entity-popup__content-text',
              }}
            >
              {t('general.popups.delete-entity.pins-warning')}
            </DialogContentText>
          )}
        <TextField
          placeholder={confirmWord}
          onChange={handleConfirmInputChange}
        />
      </DialogContent>
      <DialogActions
        classes={{
          root: 'delete-entity-popup__actions',
        }}
      >
        <Button
          variant="outlined"
          onClick={onCancel}
        >
          {t('general.controls.cancel')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={confirmButtonDisabled}
          onClick={onConfirm}
        >
          {t('general.controls.delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withTranslation()(DeleteEntity);
