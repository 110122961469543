import React, { Suspense, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import AuthenticationRedirector from '../routes/redirectors/Authentication';
import PricingRedirector from '../routes/redirectors/Pricing';
import BackdropLoading from '../components/Loading/BackdropLoading';
import Loading from '../components/Loading';
import NotFound from '../components/NotFound';
import Link from '../components/Link';
import FieldTitle from '../features/field/containers/FieldTitle';
import ToolsPanel from '../features/ui/zonesMap/containers/ToolsPanel';
import Panel from '../features/ui/zonesMap/containers/Panel';
import { reset } from '../features/ui/zonesMap/zonesMapSlice';
import { getDocumentTitle } from '../helpers';
import {
  getFieldLink,
  getRootLink,
  PAGES_ROOTS,
} from '../helpers/navigation';
import BaseTemplate from './BaseTemplate.jsx';
import {
  selectArea,
  selectName,
  selectVectorAnalysisMap,
} from '../features/field/fieldSelectors';
import { fetchAllAssets } from '../features/field/fieldSlice';
import {
  selectBackTo,
  selectIsLoaded,
  selectNotFound,
} from '../features/ui/zonesMap/zonesMapSelectors';
import useDidMount from '../hooks/useDidMount';
import { resetOverlayLayers } from '../features/ui/overlayLayers/overlayLayersSlice';

const Map = React.lazy(() => import('../features/ui/zonesMap/containers/Map'));

const ZonesMap = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    farmUuid,
    fieldUuid,
    uuid,
  } = useParams();
  const history = useHistory();
  const zonesMap = useSelector((state) => selectVectorAnalysisMap(state, uuid));
  const notFound = useSelector(selectNotFound);
  const isLoaded = useSelector(selectIsLoaded);
  const fieldName = useSelector(selectName);
  const fieldArea = useSelector(selectArea);
  const backTo = useSelector(selectBackTo);
  const zonesMapName = zonesMap?.name;

  useEffect(() => {
    document.title = getDocumentTitle(zonesMapName);
  }, [zonesMapName]);

  useDidMount(() => {
    dispatch(fetchAllAssets({
      farmUuid,
      fieldUuid,
    }));

    return () => {
      dispatch(reset());
      dispatch(resetOverlayLayers());
    };
  });

  const onBackButtonClick = () => {
    if (backTo === 'field') {
      history.push(getFieldLink(farmUuid, fieldUuid));
    } else if (backTo === 'zonesMaps') {
      history.push(getRootLink(PAGES_ROOTS.zonesMaps));
    }
  };

  let content = [
    <ToolsPanel key="tools-panel" />,
    <Panel key="panel" />,
    <Suspense
      key="map"
      fallback={<Loading />}
    >
      <Map />
    </Suspense>,
  ];
  let headerProps = {
    text: zonesMapName,
    secondaryText: (
      <FieldTitle
        fieldArea={fieldArea}
        fieldName={<Link to={getFieldLink(farmUuid, fieldUuid)}>{fieldName}</Link>}
      />
    ),
    onBackButtonClick,
  };

  if (notFound) {
    headerProps = {};
    content = [
      <NotFound
        key="panel"
        description={t('zones-map.not-found-description')}
        link={
          <Link to={getRootLink(PAGES_ROOTS.zonesMaps)}>{t('zones-map.not-found-link')}</Link>
        }
      />,
    ];
  }

  return (
    <AuthenticationRedirector>
      <PricingRedirector>
        <BaseTemplate
          route={PAGES_ROOTS.zonesMap}
          headerProps={headerProps}
        >
          {content}
          {!isLoaded && <BackdropLoading key="loading" />}
        </BaseTemplate>
      </PricingRedirector>
    </AuthenticationRedirector>
  );
};

export default ZonesMap;
