import { useState } from 'react';
import { sendPageView, sendTiming } from '../helpers/analytics';
import { getRootLink } from '../helpers/navigation';
import useDidMount from './useDidMount';

export default function useAnalytics(route) {
  const [startTime] = useState(performance.now());

  useDidMount(() => {
    sendPageView(getRootLink(route), route);

    return () => {
      sendTiming(Math.round(performance.now() - startTime), route);
    };
  });
}
