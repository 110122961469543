import { createSlice } from '@reduxjs/toolkit';

import {
  DEFAULT_ZONES_CREATION_SETTINGS,
} from '../../../helpers';
import {
  selectChanged,
  selectCloned,
  selectSettings,
} from './createMultiLayerAnalysisSelectors';
import { getIsValidSetup } from './helpers/functions';

const initialState = {
  _changed: false,
  _cloned: false,
  activeImageUuid: '',
  activeDataLayerIndex: -1,
  isValidSetup: false,
  dataLayers: [],
  settings: {
    ...DEFAULT_ZONES_CREATION_SETTINGS,
  },
};

export const navigateToSettings = () => (dispatch, getState) => {
  const state = getState();
  const changed = selectChanged(state);
  const cloned = selectCloned(state);
  let { name } = selectSettings(state);

  if (changed && cloned) {
    name = '';
  }

  dispatch(goToSettings({
    name,
    changed,
  }));
};

export const createMultiLayerAnalysisSlice = createSlice({
  name: 'createMultiLayerAnalysis',
  initialState,
  reducers: {
    goToSettings(state, action) {
      state._changed = false;
      state._cloned = false;
      state.settings.name = action.payload.name;
    },
    reset() {
      return initialState;
    },
    addDataLayer(state, action) {
      const dataLayers = state.dataLayers.concat(action.payload);

      state.dataLayers = dataLayers;
      state.isValidSetup = getIsValidSetup(dataLayers);
      state.activeDataLayerIndex = dataLayers.length - 1;
    },
    updateDataLayer(state, action) {
      const dataLayers = state.dataLayers.map((dataLayer, index) => {
        if (index === action.payload.dataLayerIndex) {
          return {
            ...dataLayer,
            ...action.payload.updates,
          };
        }

        return dataLayer;
      });

      state._changed = true;
      state.dataLayers = dataLayers;
      state.isValidSetup = getIsValidSetup(dataLayers);
    },
    deleteDataLayer(state, action) {
      const dataLayers = state.dataLayers.filter((_dataLayer, index) => {
        return index !== action.payload;
      });
      let { activeDataLayerIndex } = state;

      if (action.payload <= state.activeDataLayerIndex) {
        activeDataLayerIndex = dataLayers.length > 0
          ? Math.max(state.activeDataLayerIndex - 1, 0)
          : -1;
      }

      state._changed = true;
      state.dataLayers = dataLayers;
      state.isValidSetup = getIsValidSetup(dataLayers);
      state.activeDataLayerIndex = activeDataLayerIndex;
    },
    activateImage(state, action) {
      state.activeImageUuid = action.payload;
    },
    changeActiveDataLayer(state, action) {
      state.activeDataLayerIndex = action.payload;
    },
    goBackFromSettings(state, action) {
      state.activeDataLayerIndex = action.payload;
    },
    updateSettings(state, action) {
      state.settings[action.payload.prop] = action.payload.value;
    },
    cloneWorkflow(state, action) {
      state._cloned = true;
      state.settings = {
        ...state.settings,
        ...action.payload.settings,
      };
      state.dataLayers = action.payload.dataLayers;
      state.isValidSetup = getIsValidSetup(action.payload.dataLayers);
      state.activeDataLayerIndex = 0;
    },
  },
});

export default createMultiLayerAnalysisSlice.reducer;

export const {
  goToSettings,
  reset,
  addDataLayer,
  updateDataLayer,
  deleteDataLayer,
  activateImage,
  changeActiveDataLayer,
  goBackFromSettings,
  updateSettings,
  cloneWorkflow,
} = createMultiLayerAnalysisSlice.actions;
