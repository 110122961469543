import { CustomError } from '../../../../../helpers/functions/utils/errorHandling';
import { AreaUnit } from '../../../../user/helpers/constants/user';
import { AREA_UNIT_TO_PRICING_TABLE_TYPE } from '../constants';

export const transformAreaUnitToPricingTableType = (areaUnit: AreaUnit) => {
  const pricingTableType = AREA_UNIT_TO_PRICING_TABLE_TYPE[areaUnit];

  if (!pricingTableType) {
    throw new CustomError(`[Pricing table] No pricing table for ${areaUnit} area unit`);
  }

  return pricingTableType;
};
