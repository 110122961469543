import React, { Fragment, ReactNode } from 'react';
import { Auth, CognitoUser } from '@aws-amplify/auth';
import type { CognitoUserSession } from 'amazon-cognito-identity-js';

import { HelperTextType } from '../constants/helperTexts';
import { Provider } from '../constants/authentication';
import type { HelperText } from '../../types/ui';

export const getMultilineHelperText = (helperTexts: HelperText[]): ReactNode => {
  return helperTexts.map(({ label, type }, index) => {
    const className = type === HelperTextType.error
      ? 'textfield__input-helper-line_error'
      : 'textfield__input-helper-line_success';

    return (
      <Fragment key={index}>
        <span className={className}>{label}</span>
        <br />
      </Fragment>
    );
  });
};

export const hasErrors = (helperTexts: HelperText[]): boolean => {
  return Object.values(helperTexts).flat()
    .some(({ type }) => {
      return type === HelperTextType.error;
    });
};

export const prepareEmailAttribute = (email: string): string => {
  return email.toLowerCase().trim();
};

export const prepareAttribute = (attribute: string): string => {
  return attribute.trim();
};

const EMAIL_PROVIDER_LIST = [
  {
    regexp: /@mhp.com.ua/gi,
    provider: Provider.mhp,
  },
];

export const matchEmailToProvider = (email: string): Provider | null => {
  const emailProvider = EMAIL_PROVIDER_LIST.find(({ regexp }) => {
    return regexp.test(email);
  });

  return emailProvider?.provider || null;
};

export const refreshUserSession = async (): Promise<CognitoUserSession> => {
  const cognitoUser: CognitoUser = await Auth.currentAuthenticatedUser();
  const currentSession = await Auth.currentSession();

  return new Promise<CognitoUserSession>((resolve, reject) => {
    cognitoUser.refreshSession(
      currentSession.getRefreshToken(),
      (error: unknown, session: CognitoUserSession) => {
        if (error) {
          reject(error);
        } else {
          resolve(session);
        }
      },
    );
  });
};
