import { nanoid } from '@reduxjs/toolkit';
import {
  init as initSentry,
  captureMessage,
  captureUserFeedback,
  browserTracingIntegration,
  replayIntegration,
  replayCanvasIntegration,
  getCurrentScope,
} from '@sentry/react';
import type { Attachment } from '@sentry/types';

import { readFileAsArrayBuffer } from '../../../../../helpers/functions/utils/uploadFiles';
import { isMonitoringEnabled } from '../../../../../helpers/functions/utils/vendorConfigs/sentry';

const BUG_REPORT_MESSAGE = 'Bug Report';

export const init = () => {
  initSentry({
    dsn: 'https://6dc4f8903ab24648a7235e23c6137b1c@o678255.ingest.sentry.io/5769177',
    integrations: [
      browserTracingIntegration(),
      replayIntegration({
        maskAllInputs: false,
        maskAllText: false,
        blockAllMedia: false,
        networkDetailAllowUrls: [
          'graphql',
        ],
      }),
      replayCanvasIntegration(),
    ],
    ignoreErrors: [
      /ResizeObserver loop/,
      /cssVersion/,
    ],
    beforeSend(event) {
      if (!isMonitoringEnabled()) {
        return null;
      }

      return event;
    },
    beforeSendTransaction(event) {
      if (!isMonitoringEnabled()) {
        return null;
      }

      return event;
    },
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.45,
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,
  });
};

/**
 * Generate a unique title for the user's report
 * in order to avoid stacking multiple bug reports into one.
 *
 * @returns eventId
 */
const generateMessage = () => {
  const id = nanoid();

  return captureMessage(`${BUG_REPORT_MESSAGE} (${id})`);
};

export const sendUserReport = async (
  feedback: {
    name: string,
    email: string,
    comment: string,
    files: File[],
  },
) => {
  const scope = getCurrentScope();
  const eventId = generateMessage();
  let attachments: Attachment[] = [];

  if (feedback.files.length !== 0) {
    attachments = await Promise.all(
      feedback.files.map(async (file) => {
        const arrayBuffer = await readFileAsArrayBuffer(file);

        return {
          data: new Uint8Array(arrayBuffer),
          filename: file.name,
        };
      }),
    );
  }

  scope.clearAttachments();
  attachments.forEach((attachment) => {
    scope.addAttachment(attachment);
  });

  captureUserFeedback({
    event_id: eventId,
    name: feedback.name,
    email: feedback.email,
    comments: feedback.comment,
  });
};
