import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import AuthenticationRedirector from '../routes/redirectors/Authentication';
import PricingRedirector from '../routes/redirectors/Pricing';
import Panel from '../features/ui/integrations/containers/Panel';
import { PAGES_ROOTS } from '../helpers/navigation';
import { getDocumentTitle } from '../helpers';
import BaseTemplate from './BaseTemplate.jsx';
import useDidMount from '../hooks/useDidMount';
import { getHeaderProps } from '../features/ui/integrations/helpers/functions/integrations';

export default function UserIntegrations() {
  const { t } = useTranslation();
  const location = useLocation();

  useDidMount(() => {
    document.title = getDocumentTitle(t('general.navigation.integrations'));
  });

  return (
    <AuthenticationRedirector>
      <PricingRedirector>
        <BaseTemplate
          route={PAGES_ROOTS.userIntegrations}
          headerProps={getHeaderProps(location.pathname)}
        >
          <Panel key="panel" />
        </BaseTemplate>
      </PricingRedirector>
    </AuthenticationRedirector>
  );
}
