import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { singleAtTheTime } from '../../app/store/mixins/singleAtTheTime';
import { errorNotify } from '../notifications/helpers/functions/notify';
import { getUserDataFetcher } from '../ui/applicationShell/applicationShellSlice';
import { selectAreaUnit } from '../user/userSelectors';
import {
  renameField as renameFieldAPI,
  setFieldLabels as setFieldLabelsAPI,
} from '../field/fieldAPI';
import {
  deleteFields as deleteFieldsAPI,
  addFieldsLabels as addFieldsLabelsAPI,
  deleteFieldsLabels as deleteFieldsLabelsAPI,
  getFields,
} from './fieldsListAPI';
import {
  selectFarm,
  selectFilter,
  selectLastEvaluatedKey,
} from './fieldsListSelectors';
import { getFieldsFilter } from './helpers/functions/filter';
import { FIELDS_BATCH_SIZE } from './helpers/constants/fields';
import { CustomError } from '../../helpers/functions/utils/errorHandling';

const initialState = {
  isLoaded: false,
  lastEvaluatedKey: null,
  farm: null,
  filter: {},
  fields: [],
};

export const fetchFields = singleAtTheTime(createAsyncThunk(
  'fieldsList/fetchFields',
  async (payload, { getState, dispatch }) => {
    const {
      farm,
      filter,
    } = payload || {};

    await getUserDataFetcher();

    try {
      const state = getState();
      const requestFilter = getFieldsFilter({
        pageSize: FIELDS_BATCH_SIZE,
        filter: filter || selectFilter(state),
        lastEvaluatedKey: selectLastEvaluatedKey(state),
        areaUnit: selectAreaUnit(state),
        farm: farm || selectFarm(state),
      });

      return await getFields(requestFilter);
    } catch (error) {
      errorNotify({
        error: new CustomError('[Fields] Unable to fetch fields.', {
          cause: error,
        }),
        dispatch,
      });

      return {
        fields: [],
        lastEvaluatedKey: null,
        totalCount: 0,
      };
    }
  },
  {
    condition: (payload, { getState }) => {
      const {
        farm,
        filter,
      } = payload || {};
      const state = getState();
      const farmJson = JSON.stringify(farm);
      const filterJson = JSON.stringify(filter);
      const oldFarmJson = JSON.stringify(selectFarm(state));
      const oldFilterJson = JSON.stringify(selectFilter(state));

      if (
        (farmJson === oldFarmJson && (!filter || filterJson === oldFilterJson))
        || (filterJson === oldFilterJson && (!farm || farmJson === oldFarmJson))
      ) {
        return false;
      }
    },
  },
));

export const loadMoreFields = singleAtTheTime(createAsyncThunk(
  'fieldsList/loadMoreFields',
  async (_payload, { getState, dispatch }) => {
    const state = getState();

    try {
      return await getFields(getFieldsFilter({
        pageSize: FIELDS_BATCH_SIZE,
        filter: selectFilter(state),
        lastEvaluatedKey: selectLastEvaluatedKey(state),
        areaUnit: selectAreaUnit(state),
        farm: selectFarm(state),
      }));
    } catch (error) {
      errorNotify({
        error: new CustomError('[Fields] Unable to load more fields.', {
          cause: error,
        }),
        dispatch,
      });
    }
  },
  {
    condition: (_payload, { getState }) => {
      const state = getState();
      const lastEvaluatedKey = selectLastEvaluatedKey(state);

      return !!lastEvaluatedKey;
    },
  },
));

export const deleteFields = createAsyncThunk(
  'fieldsList/deleteFields',
  async (payload, { dispatch }) => {
    return deleteFieldsAPI(payload)
      .then(() => {
        dispatch(fetchFields());
      })
      .catch((error) => {
        errorNotify({
          error: new CustomError('[Fields] Unable to delete fields.', {
            cause: error,
          }),
          dispatch,
        });
      });
  },
);

export const renameField = createAsyncThunk(
  'fieldsList/renameField',
  async ({
    farmUuid,
    fieldUuid,
    name,
  }, { dispatch }) => {
    return renameFieldAPI(farmUuid, fieldUuid, name)
      .catch((error) => {
        errorNotify({
          error: new CustomError('[Fields] Unable to rename field.', {
            cause: error,
          }),
          dispatch,
        });
      });
  },
);

export const setFieldLabels = createAsyncThunk(
  'fieldsList/setFieldLabels',
  async ({
    fieldUuid,
    farmUuid,
    labels,
  }, { dispatch }) => {
    return setFieldLabelsAPI(farmUuid, fieldUuid, labels)
      .catch((error) => {
        errorNotify({
          error: new CustomError('[Fields] Unable to set field labels.', {
            cause: error,
          }),
          dispatch,
        });
      });
  },
);

export const addFieldsLabels = createAsyncThunk(
  'fieldsList/addFieldsLabels',
  async (payload, { dispatch }) => {
    return addFieldsLabelsAPI(payload)
      .catch((error) => {
        errorNotify({
          error: new CustomError('[Fields] Unable to add fields labels.', {
            cause: error,
          }),
          dispatch,
        });
      });
  },
);

export const deleteFieldsLabels = createAsyncThunk(
  'fieldsList/deleteFieldsLabels',
  async (payload, { dispatch }) => {
    return deleteFieldsLabelsAPI(payload)
      .catch((error) => {
        errorNotify({
          error: new CustomError('[Fields] Unable to delete fields labels.', {
            cause: error,
          }),
          dispatch,
        });
      });
  },
);

export const subscription = (parsedEvent) => async (dispatch) => {
  const {
    action,
    pathLength,
    farmUuid,
    fieldUuid,
  } = parsedEvent;

  if (
    farmUuid !== ''
    && fieldUuid !== ''
    && pathLength === 2
    && action === 'MODIFY'
  ) {
    dispatch(fetchFields());
  }
};

export const fieldsListSlice = createSlice({
  name: 'fieldsList',
  initialState,
  reducers: {
    resetFilters(state) {
      state.filter = {};
      state.farm = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchFields.pending, (state, action) => {
        const {
          filter,
          farm,
        } = action.meta.arg || {};

        state.isLoaded = false;
        state.page = 0;
        state.totalCount = 0;
        state.lastEvaluatedKey = null;
        state.farm = farm || farm === null ? farm : state.farm;
        state.filter = filter || state.filter;
      })
      .addCase(fetchFields.fulfilled, (state, action) => {
        if (!action.payload) {
          return state;
        }

        const {
          fields,
          totalCount,
          lastEvaluatedKey,
        } = action.payload;

        state.isLoaded = true;
        state.fields = fields;
        state.totalCount = totalCount;
        state.lastEvaluatedKey = lastEvaluatedKey;
      })
      .addCase(loadMoreFields.fulfilled, (state, action) => {
        state.lastEvaluatedKey = action.payload.lastEvaluatedKey;
        state.fields = [
          ...state.fields,
          ...action.payload.fields,
        ];
      })
      .addCase(renameField.fulfilled, (state, action) => {
        state.fields = state.fields.map((field) => {
          if (field.uuid === action.payload.uuid) {
            return {
              ...field,
              name: action.payload.name,
            };
          }

          return field;
        });
      })
      .addCase(setFieldLabels.fulfilled, (state, action) => {
        state.fields = state.fields.map((field) => {
          if (field.uuid === action.payload.uuid) {
            return {
              ...field,
              labels: action.payload.labels,
            };
          }

          return field;
        });
      })
      .addMatcher(
        ({ type }) => {
          return type === addFieldsLabels.fulfilled.type
        || type === deleteFieldsLabels.fulfilled.type;
        },
        (state, action) => {
          state.fields = state.fields.map((field) => {
            const labels = action.payload[field.uuid];

            if (labels) {
              return {
                ...field,
                labels,
              };
            }

            return field;
          });
        },
      );
  },
});

export const {
  resetFilters,
} = fieldsListSlice.actions;

export default fieldsListSlice.reducer;
