import type { Organization } from '../../../../user/types/api';
import type { UserAction } from '../../types/api';

export const transformAction = (
  actions: UserAction[],
  organizations: Organization[],
  currentUser: {
    uuid: string,
    email: string,
  },
) => {
  return actions.map((action) => {
    const organization = organizations.find(({ uuid }) => uuid === action.organizationUuid);
    let userEmail: string | undefined;

    if (action.userUuid === currentUser.uuid) {
      userEmail = currentUser.email;
    } else {
      userEmail = organization?.users?.find(({ userUuid }) => userUuid === action.userUuid)?.email;
    }

    return {
      ...action,
      organization: organization?.name,
      user: userEmail || '',
    };
  });
};
