import React from 'react';
import { useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/InfoOutlined';

const InitializingCard = () => {
  const { t } = useTranslation();

  return (
    <Paper
      square
      classes={{
        root: 'restricted-access-card',
      }}
    >
      <div className="restricted-access-card__container">
        <Typography
          variant="h1"
          classes={{
            root: 'restricted-access-card__title',
          }}
        >
          {t('restricted-access.initializing')}
        </Typography>
      </div>
      <InfoIcon className="restricted-access-card__icon"/>
    </Paper>
  );
};

export default InitializingCard;
