import React, {
  ReactElement,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  ASSET_TYPE_TO_ASSET_GROUP,
  AssetGroupType,
  AssetType,
} from '../../../../helpers/constants/entities/asset';
import {
  findNodeById,
  traverseTree,
} from '../helpers/functions/tree';
import { generateDataLayersTree } from '../helpers/functions/generateDataLayersTree';
import {
  generateAssetNodeId,
  getModeValue,
  isAttributesDisplayed,
  isDatasetsGroupingEnabled,
} from '../helpers/functions/dataLayersTree';
import { isInvalid as isEquationMapInvalid } from '../../../../helpers/functions/entities/equationMap';
import SatelliteImageNode from '../components/DataLayersTree/Nodes/SatelliteImageNode';
import DatasetNode from '../components/DataLayersTree/Nodes/DatasetNode';
import ZonesMapNode from '../components/DataLayersTree/Nodes/ZonesMapNode';
import AssetGroupNode from '../components/DataLayersTree/Nodes/AssetGroupNode';
import EquationMapNode from '../components/DataLayersTree/Nodes/EquationMapNode';
import PinsGroupNode from '../components/DataLayersTree/Nodes/PinsGroupNode';
import DataLayersTree from '../components/DataLayersTree';
import TopographyNode from '../components/DataLayersTree/Nodes/TopographyNode';
import AttributeNode from '../components/DataLayersTree/Nodes/AttributeNode';
import ThreeDimensionalMapNode from '../components/DataLayersTree/Nodes/ThreeDimensionalMapNode';
import GroupPlaceholderNode from '../components/DataLayersTree/Nodes/GroupPlaceholderNode';
import useTreeView from './useTreeView';
import type {
  ModeConfig,
  DatasetDataLayers,
  DataLayersTreeNode,
} from '../types/dataLayersTree';
import { isDatasetItemDisabled } from '../helpers/functions/datasets';
import SatelliteFilter from '../../filters/components/SatelliteFilter';
import DefaultAssetGroupFilter from '../../filters/components/DefaultAssetGroupFilter';
import ZonesMapFilter from '../../filters/components/ZonesMapFilter';
import ExpandFiltersButton from '../../filters/components/ExpandFiltersButton';
import FiltersPanel from '../../filters/components/FiltersPanel';
import { filterSatelliteImages } from '../../filters/helpers/functions/satelliteImage';
import {
  filterPinsGroups,
  getPinsGroupsTypeFilterOptions,
} from '../../filters/helpers/functions/pinsGroups';
import {
  filterVamaps,
  getVamapTypeFilterOptions,
} from '../../filters/helpers/functions/analysis';
import { DEFAULT_VAMAPS_FILTER } from '../../filters/helpers/constants/analysis';
import {
  DEFAULT_FIELD_WORKFLOW_SATELLITE_FILTER as DEFAULT_SAT_IMAGES_FILTER,
} from '../../filters/helpers/constants/satelliteImage';
import { SatelliteFilters } from '../../filters/types/satelliteImage';
import { DEFAULT_PINS_GROUPS_FILTER } from '../../filters/helpers/constants/pinsGroups';
import { VectorAnalysisFilters } from '../../filters/types/analysis';
import { PinsGroupsFilters } from '../../filters/types/pinsGroups';
import {
  AssetGroupsFilters,
  DefaultAssetGroupFilterType,
  DefaultAssetGroupFilters,
} from '../../filters/types/assetGroup';
import { filterAssetGroupItems } from '../../filters/helpers/functions/assetGroup';
import type { TransformedSatelliteImage } from '../../../satelliteImages/types/satelliteImage';
import type { TransformedVectorAnalysisMap } from '../../../../helpers/types/vectorAnalysisMap';
import type {
  TransformedAsAppliedDataset,
  TransformedDataset,
  TransformedSoilDataset,
  TransformedTopographyMap,
  TransformedYieldDataset,
} from '../../../../helpers/types/dataset';
import { DEFAULT_ASSET_GROUP_FILTER } from '../../filters/helpers/constants/assetGroup';
import { filterDatasets } from '../../filters/helpers/functions/dataset';
import type { TransformedThreeDimensionalMap } from '../../../../helpers/types/threeDimensionalMap';
import type { TransformedEquationMap } from '../../../../helpers/types/equationMap';
import type { PinsGroup } from '../../../pins/types';
import type { ArrayItem } from '../../../../helpers/types/shared';
import type { TransformedAsset } from '../../../../helpers/types';
import { updateParentSelection } from '../components/TreeView/CheckboxItem';
import RecommendedSatelliteImagesPicker from '../../../../components/RecommendedSatelliteImagesPicker';

const TREE_LEVEL_OFFSET = 27;

export default function useDataLayersTree({
  assets,
  mode = 'default',
  withAttributes = false,
  withRawProcessed = false,
  withGeoMapAttributes = false,
  grouping = false,
  satelliteImagesLoading = false,
  jdProfileIsHealth = false,
  jdProfileIsAuthorized = false,
  jdWorkPlanExportAvailable = false,
  isSynchronizedJohnDeereField = false,
  publishedDatasetsClickable = false,
  showItemMenu = false,
  showCleanCalibrateDataset = false,
  showEmptyGroups = false,
  showSatelliteRecommendations = false,
  collapsible = false,
  selectedAssetUuid,
  selectedAssetGroupType,
  farmUuid = '',
  fieldUuid = '',
  checked = {},
  children = [],
  hasGroupPlaceholderAction = true,
  withAssetGroupNode = true,
  onAssetNodeClick = () => {},
  onMenuItemClick = () => {},
  onAttributeNodeClick,
  onGroupPlaceholderActionClick = () => {},
  onCheckedChange = () => {},
  onCleanCalibrateDatasetClick = () => {},
}: {
  assets: {
    satelliteImages?: TransformedSatelliteImage[],
    vectorAnalysisMaps?: TransformedVectorAnalysisMap[],
    soilDatasets?: TransformedSoilDataset[],
    yieldDatasets?: TransformedYieldDataset[],
    asAppliedDatasets?: TransformedAsAppliedDataset[],
    topographyMaps?: TransformedTopographyMap[],
    threeDimensionalMaps?: TransformedThreeDimensionalMap[],
    equationMaps?: TransformedEquationMap[],
    pinsGroups?: PinsGroup[],
  },
  withAttributes?: boolean,
  withRawProcessed?: boolean,
  withGeoMapAttributes?: boolean,
  mode?: ModeConfig,
  grouping?: boolean,
  satelliteImagesLoading?: boolean,
  jdProfileIsHealth?: boolean,
  jdProfileIsAuthorized?: boolean,
  jdWorkPlanExportAvailable?: boolean,
  isSynchronizedJohnDeereField?: boolean,
  publishedDatasetsClickable?: boolean,
  showItemMenu?: boolean,
  showCleanCalibrateDataset?: boolean,
  showEmptyGroups?: boolean,
  showSatelliteRecommendations?: boolean,
  collapsible?: boolean,
  selectedAssetUuid?: string,
  selectedAssetGroupType?: AssetGroupType | null,
  farmUuid?: string,
  fieldUuid?: string,
  checked?: Record<string, number>,
  children?: ReactElement[],
  hasGroupPlaceholderAction?: boolean,
  withAssetGroupNode?: boolean,
  onAssetNodeClick?: <T extends TransformedAsset>(t: AssetGroupType, i: T) => void,
  onMenuItemClick?: <T extends TransformedAsset>(m: string, i: T) => void,
  onAttributeNodeClick?: (itemNode: DataLayersTreeNode | null) => void,
  onGroupPlaceholderActionClick?: (t: AssetGroupType) => void,
  onCheckedChange?: (u: Record<string, number>, item?: DataLayersTreeNode) => void,
  onCleanCalibrateDatasetClick?: (d: TransformedDataset) => void,
}) {
  const [expanded, setExpanded] = useState<Record<string, boolean>>({});
  const [filterExpanded, setFilterExpanded] = useState<Record<string, boolean>>({});
  const [selectedAssetPathExpanded, setSelectedAssetPathExpanded] = useState(false);
  const [selectedAssetScrolled, setSelectedAssetScrolled] = useState(false);
  const [activeAssetGroup, setActiveAssetGroup] = useState<string | null>();

  const [filtersValue, setFiltersValue] = useState<AssetGroupsFilters>({
    [AssetGroupType.satelliteImages]: DEFAULT_SAT_IMAGES_FILTER,
    [AssetGroupType.vectorAnalysisMaps]: DEFAULT_VAMAPS_FILTER,
    [AssetGroupType.pinsGroups]: DEFAULT_PINS_GROUPS_FILTER,
    [AssetGroupType.equationMaps]: DEFAULT_ASSET_GROUP_FILTER,
    [AssetGroupType.soilDatasets]: DEFAULT_ASSET_GROUP_FILTER,
    [AssetGroupType.yieldDatasets]: DEFAULT_ASSET_GROUP_FILTER,
    [AssetGroupType.asAppliedDatasets]: DEFAULT_ASSET_GROUP_FILTER,
    [AssetGroupType.topographyMaps]: DEFAULT_ASSET_GROUP_FILTER,
    [AssetGroupType.threeDimensionalMaps]: DEFAULT_ASSET_GROUP_FILTER,
  });

  const filteredVectorAnalysisMaps = useMemo(
    () => filterVamaps(filtersValue[AssetGroupType.vectorAnalysisMaps], assets.vectorAnalysisMaps),
    [assets.vectorAnalysisMaps, filtersValue],
  );
  const filteredSatelliteImages = useMemo(
    () => filterSatelliteImages(filtersValue[AssetGroupType.satelliteImages], assets.satelliteImages),
    [assets.satelliteImages, filtersValue],
  );
  const filteredPinsGroups = useMemo(
    () => filterPinsGroups(filtersValue[AssetGroupType.pinsGroups], assets.pinsGroups),
    [assets.pinsGroups, filtersValue],
  );
  const filteredSoilDatasets = useMemo(
    () => filterDatasets(filtersValue[AssetGroupType.soilDatasets], assets.soilDatasets),
    [assets.soilDatasets, filtersValue],
  );
  const filteredYieldDatasets = useMemo(
    () => filterDatasets(filtersValue[AssetGroupType.yieldDatasets], assets.yieldDatasets),
    [assets.yieldDatasets, filtersValue],
  );
  const filteredAsAppliedDatasets = useMemo(
    () => filterDatasets(filtersValue[AssetGroupType.asAppliedDatasets], assets.asAppliedDatasets),
    [assets.asAppliedDatasets, filtersValue],
  );
  const filteredEquationMaps = useMemo(
    () => filterAssetGroupItems(filtersValue[AssetGroupType.equationMaps], assets.equationMaps),
    [assets.equationMaps, filtersValue],
  );
  const filteredTopographyMaps = useMemo(
    () => filterAssetGroupItems(filtersValue[AssetGroupType.topographyMaps], assets.topographyMaps),
    [assets.topographyMaps, filtersValue],
  );
  const filteredThreeDimensionalMaps = useMemo(
    () => filterAssetGroupItems(filtersValue[AssetGroupType.threeDimensionalMaps], assets.threeDimensionalMaps),
    [assets.threeDimensionalMaps, filtersValue],
  );

  const handleAssetGroupNodeClick = (assetGroupType: AssetGroupType) => {
    const groupExpanded = !expanded[assetGroupType];

    if (groupExpanded) {
      setActiveAssetGroup(assetGroupType);
    } else if (!groupExpanded && assetGroupType === activeAssetGroup) {
      setActiveAssetGroup(null);
    }

    setExpanded({
      ...expanded,
      [assetGroupType]: groupExpanded,
    });
  };

  const handleAssetNodeClick = <T extends TransformedAsset>(
    assetGroupType: AssetGroupType,
    item: T,
    id: string,
    opts?: {
      skipExpand?: boolean,
    },
  ) => {
    setSelectedAssetScrolled(true);
    setSelectedAssetPathExpanded(true);

    onAssetNodeClick(assetGroupType, item);

    if (opts?.skipExpand) {
      return;
    }

    const itemExpanded = !expanded[id];

    setActiveAssetGroup(assetGroupType);
    setExpanded({
      ...expanded,
      [id]: itemExpanded,
    });
  };

  const handleViewTypeNodeClick = (
    id: string,
  ) => {
    const itemExpanded = !expanded[id];

    setExpanded({
      ...expanded,
      [id]: itemExpanded,
    });
  };

  const handleExpandMoreClick = (assetGroupType: AssetGroupType, id: string) => {
    setSelectedAssetScrolled(true);
    setSelectedAssetPathExpanded(true);

    const itemExpanded = !expanded[id];

    setActiveAssetGroup(assetGroupType);
    setExpanded({
      ...expanded,
      [id]: itemExpanded,
    });
  };

  const handleAssetGroupToggleClick = (assetGroupType: AssetGroupType, id: string) => {
    const itemExpanded = !expanded[id];

    setActiveAssetGroup(assetGroupType);
    setExpanded({
      ...expanded,
      [id]: itemExpanded,
    });
  };

  const handleCheckboxClick = (selectUpdate: Record<string, number>, item: DataLayersTreeNode) => {
    onCheckedChange(selectUpdate, item);
  };

  const handleMenuItemClick = <T extends TransformedAsset>(menuItem: string, item: T) => {
    onMenuItemClick(menuItem, item);
  };

  const isEquationMapSelectable = (item: TransformedEquationMap) => {
    return !isEquationMapInvalid(item);
  };

  const isDatasetSelectable = (item: TransformedDataset) => {
    return !isDatasetItemDisabled(item);
  };

  const treeNodeGetter = (id: string) => {
    return findNodeById(dataLayersTree, id);
  };

  const handleRecommendedImagesSelect = (uuids: string[]) => {
    const selectUpdate = uuids.reduce<Record<string, number>>((acc, uuid) => {
      return {
        ...acc,
        [generateAssetNodeId(uuid, AssetGroupType.satelliteImages)]: 2,
      };
    }, {} as Record<string, number>);

    if (uuids.length === assets.satelliteImages?.length) {
      selectUpdate[AssetGroupType.satelliteImages] = 2;
    } else if (uuids.length !== 0) {
      selectUpdate[AssetGroupType.satelliteImages] = 1;
    }

    onCheckedChange(selectUpdate);
  };

  const recommendedImagesRenderer = () => {
    return (
      <RecommendedSatelliteImagesPicker
        allImages={assets.satelliteImages}
        filteredImages={filteredSatelliteImages}
        onSelect={handleRecommendedImagesSelect}
      />
    );
  };

  const satelliteFiltersRenderer = () => {
    const type = AssetGroupType.satelliteImages;

    const clearSatelliteFilters = () => {
      setFiltersValue((prev) => ({ ...prev, [type]: DEFAULT_SAT_IMAGES_FILTER }));
    };

    return (
      <FiltersPanel
        type={type}
        value={filtersValue[type]}
        isExpanded={filterExpanded[AssetGroupType.satelliteImages] || !withAssetGroupNode}
        classes={{ root: withAssetGroupNode ? 'filters-panel-nested' : '' }}
        onFiltersClear={clearSatelliteFilters}
      >
        <SatelliteFilter
          value={filtersValue[type]}
          images={assets.satelliteImages}
          onFiltersClear={clearSatelliteFilters}
          onChange={(v: SatelliteFilters) => {
            setFiltersValue((prev) => ({ ...prev, [type]: v }));
          }}
        />
      </FiltersPanel>

    );
  };

  const zonesMapFilterRenderer = () => {
    const type = AssetGroupType.vectorAnalysisMaps;
    const options = getVamapTypeFilterOptions(
      assets.vectorAnalysisMaps,
      DEFAULT_VAMAPS_FILTER.typeFilterValue,
    );

    const clearVamapFilters = () => {
      setFiltersValue((prev) => ({ ...prev, [type]: DEFAULT_VAMAPS_FILTER }));
    };

    return (
      <FiltersPanel
        type={type}
        value={filtersValue[type]}
        isExpanded={filterExpanded[AssetGroupType.vectorAnalysisMaps] || !withAssetGroupNode}
        classes={{ root: withAssetGroupNode ? 'filters-panel-nested' : '' }}
        onFiltersClear={clearVamapFilters}
      >
        <ZonesMapFilter
          value={filtersValue[type]}
          typeOptions={options}
          onFiltersClear={clearVamapFilters}
          onChange={(v: VectorAnalysisFilters) => {
            setFiltersValue((prev) => ({ ...prev, [type]: v }));
          }}
        />
      </FiltersPanel>
    );
  };

  const pinsGroupsFilterRenderer = () => {
    const type = AssetGroupType.pinsGroups;
    const options = getPinsGroupsTypeFilterOptions(
      assets.pinsGroups,
      DEFAULT_PINS_GROUPS_FILTER.typeFilterValue,
    );

    const clearPinsGroupsFilters = () => {
      setFiltersValue((prev) => ({ ...prev, [type]: DEFAULT_PINS_GROUPS_FILTER }));
    };

    return (
      <FiltersPanel
        type={AssetGroupType.pinsGroups}
        value={filtersValue[type]}
        isExpanded={filterExpanded[AssetGroupType.pinsGroups] || !withAssetGroupNode}
        classes={{ root: withAssetGroupNode ? 'filters-panel-nested' : '' }}
        onFiltersClear={clearPinsGroupsFilters}
      >
        <ZonesMapFilter
          value={filtersValue[type]}
          typeOptions={options}
          onFiltersClear={clearPinsGroupsFilters}
          onChange={(v: PinsGroupsFilters) => {
            setFiltersValue((prev) => ({ ...prev, [type]: v }));
          }}
        />
      </FiltersPanel>
    );
  };

  const defaultAssetGroupFilterRenderer = (type: DefaultAssetGroupFilterType) => {
    const clearFilters = () => {
      setFiltersValue((prev) => ({ ...prev, [type]: DEFAULT_ASSET_GROUP_FILTER }));
    };

    return (
      <FiltersPanel
        value={filtersValue[type]}
        isExpanded={filterExpanded[type] || !withAssetGroupNode}
        classes={{ root: withAssetGroupNode ? 'filters-panel-nested' : '' }}
        onFiltersClear={clearFilters}
      >
        <DefaultAssetGroupFilter
          value={filtersValue[type]}
          onFiltersClear={clearFilters}
          onChange={(v: DefaultAssetGroupFilters) => {
            setFiltersValue((prev) => ({ ...prev, [type]: v }));
          }}
        />
      </FiltersPanel>
    );
  };

  const getDefaultAssetGroupFilterRenderer = (type: DefaultAssetGroupFilterType) => {
    return () => defaultAssetGroupFilterRenderer(type);
  };

  const assetGroupNodeRenderer = (
    assetGroupType: AssetGroupType,
    id: string,
    offset: number,
  ) => {
    const assetGroup = {
      ...assets,
      satelliteImages: filteredSatelliteImages,
      vectorAnalysisMaps: filteredVectorAnalysisMaps,
      pinsGroups: filteredPinsGroups,
    }[assetGroupType];

    const handleExpandFilterButton = (isExpanded: boolean) => {
      setFilterExpanded((prev) => ({ ...prev, [assetGroupType]: isExpanded }));
    };

    const isFiltersButtonVisible = !!assets[assetGroupType]?.length && expanded[assetGroupType];

    return (
      <AssetGroupNode<ArrayItem<typeof assetGroup>>
        id={id}
        assetGroupType={assetGroupType}
        mode={getModeValue(assetGroupType, mode)}
        assetGroup={assetGroup}
        checked={checked}
        expanded={expanded}
        offset={offset}
        treeNodeGetter={() => treeNodeGetter(id)}
        satelliteImagesLoading={satelliteImagesLoading}
        onCheckboxClick={handleCheckboxClick}
        onClick={() => handleAssetGroupNodeClick(assetGroupType)}
      >
        {
          isFiltersButtonVisible
            && (
              <ExpandFiltersButton
                isExpanded={filterExpanded[id]}
                onClick={handleExpandFilterButton}
              />
            )
        }
      </AssetGroupNode>
    );
  };

  const satelliteImageNodeRenderer = (image: TransformedSatelliteImage, id: string, offset: number) => {
    return (
      <SatelliteImageNode
        id={id}
        image={image}
        mode={getModeValue(AssetGroupType.satelliteImages, mode)}
        checked={checked}
        expanded={expanded}
        withAttributes={isAttributesDisplayed(withAttributes, AssetGroupType.satelliteImages, mode)}
        treeNodeGetter={() => treeNodeGetter(id)}
        jdProfileIsHealth={jdProfileIsHealth}
        jdProfileIsAuthorized={jdProfileIsAuthorized}
        isSynchronizedJohnDeereField={isSynchronizedJohnDeereField}
        showItemMenu={showItemMenu}
        selectedItemUuid={selectedAssetUuid}
        selectedItemGroupType={selectedAssetGroupType}
        offset={offset}
        onCheckboxClick={handleCheckboxClick}
        onClick={() => handleAssetNodeClick(AssetGroupType.satelliteImages, image, id)}
        onMenuItemClick={(menuItem) => handleMenuItemClick(menuItem, image)}
        onExpandMoreClick={() => handleExpandMoreClick(AssetGroupType.satelliteImages, id)}
      />
    );
  };

  const vectorAnalysisMapNodeRenderer = (vamap: TransformedVectorAnalysisMap, id: string, offset: number) => {
    return (
      <ZonesMapNode
        id={id}
        vamap={vamap}
        mode={getModeValue(AssetGroupType.vectorAnalysisMaps, mode)}
        checked={checked}
        treeNodeGetter={() => treeNodeGetter(id)}
        jdProfileIsHealth={jdProfileIsHealth}
        jdProfileIsAuthorized={jdProfileIsAuthorized}
        isSynchronizedJohnDeereField={isSynchronizedJohnDeereField}
        jdWorkPlanExportAvailable={jdWorkPlanExportAvailable}
        showItemMenu={showItemMenu}
        selectedItemUuid={selectedAssetUuid}
        selectedItemGroupType={selectedAssetGroupType}
        offset={offset}
        onCheckboxClick={handleCheckboxClick}
        onClick={() => handleAssetNodeClick(AssetGroupType.vectorAnalysisMaps, vamap, id)}
        onMenuItemClick={(menuItem) => handleMenuItemClick(menuItem, vamap)}
      />
    );
  };

  const datasetNodeRenderer: DatasetDataLayers<TransformedDataset>['renderer'] = (dataset, id, offset, opts) => {
    const assetGroupType = ASSET_TYPE_TO_ASSET_GROUP[dataset._type];
    const groupingEnabled = isDatasetsGroupingEnabled(grouping, assetGroupType, mode);
    const attributesDisplayed = isAttributesDisplayed(withAttributes || withRawProcessed, assetGroupType, mode);

    return (
      <DatasetNode
        id={id}
        dataset={dataset}
        mode={getModeValue(assetGroupType, mode)}
        checked={checked}
        expanded={expanded}
        treeNodeGetter={() => treeNodeGetter(id)}
        jdProfileIsHealth={jdProfileIsHealth}
        jdProfileIsAuthorized={jdProfileIsAuthorized}
        isSynchronizedJohnDeereField={isSynchronizedJohnDeereField}
        withAttributes={attributesDisplayed}
        grouping={groupingEnabled ? opts?.grouping : undefined}
        showItemMenu={showItemMenu}
        showCleanCalibrate={showCleanCalibrateDataset}
        assetGroupType={assetGroupType}
        selectedItemUuid={selectedAssetUuid}
        selectedItemGroupType={selectedAssetGroupType}
        publishedClickable={publishedDatasetsClickable}
        offset={offset}
        onCheckboxClick={handleCheckboxClick}
        onMenuItemClick={(menuItem) => handleMenuItemClick(menuItem, dataset)}
        onClick={() => handleAssetNodeClick(
          assetGroupType,
          dataset,
          id,
          {
            skipExpand: opts?.grouping?.memberType === 'parent' || attributesDisplayed,
          },
        )}
        onExpandMoreClick={() => handleExpandMoreClick(assetGroupType, id)}
        onGroupToggleClick={() => handleAssetGroupToggleClick(assetGroupType, id)}
        onCleanCalibrateClick={() => onCleanCalibrateDatasetClick(dataset)}
      />
    );
  };

  const topographyNodeRenderer = (dataset: TransformedTopographyMap, id: string, offset: number) => {
    const attributesDisplayed = isAttributesDisplayed(withAttributes, AssetGroupType.topographyMaps, mode);

    return (
      <TopographyNode
        id={id}
        dataset={dataset}
        mode={getModeValue(AssetGroupType.topographyMaps, mode)}
        checked={checked}
        expanded={expanded}
        treeNodeGetter={() => treeNodeGetter(id)}
        jdProfileIsHealth={jdProfileIsHealth}
        jdProfileIsAuthorized={jdProfileIsAuthorized}
        isSynchronizedJohnDeereField={isSynchronizedJohnDeereField}
        withAttributes={attributesDisplayed}
        showItemMenu={showItemMenu}
        selectedItemUuid={selectedAssetUuid}
        selectedItemGroupType={selectedAssetGroupType}
        offset={offset}
        onCheckboxClick={handleCheckboxClick}
        onMenuItemClick={(menuItem) => handleMenuItemClick(menuItem, dataset)}
        onClick={() => handleAssetNodeClick(
          AssetGroupType.topographyMaps,
          dataset,
          id,
          {
            skipExpand: attributesDisplayed,
          },
        )}
        onExpandMoreClick={() => handleExpandMoreClick(AssetGroupType.topographyMaps, id)}
      />
    );
  };

  const threeDimensionalMapNodeRenderer = (threeDMap: TransformedThreeDimensionalMap, id: string, offset: number) => {
    return (
      <ThreeDimensionalMapNode
        id={id}
        threeDMap={threeDMap}
        mode={getModeValue(AssetGroupType.threeDimensionalMaps, mode)}
        checked={checked}
        treeNodeGetter={() => treeNodeGetter(id)}
        showItemMenu={showItemMenu}
        selectedItemUuid={selectedAssetUuid}
        selectedItemGroupType={selectedAssetGroupType}
        offset={offset}
        onCheckboxClick={handleCheckboxClick}
        onClick={() => handleAssetNodeClick(AssetGroupType.threeDimensionalMaps, threeDMap, id)}
        onMenuItemClick={(menuItem) => handleMenuItemClick(menuItem, threeDMap)}
      />
    );
  };

  const equationMapNodeRenderer = (equationMap: TransformedEquationMap, id: string, offset: number) => {
    return (
      <EquationMapNode
        id={id}
        equationMap={equationMap}
        mode={getModeValue(AssetGroupType.equationMaps, mode)}
        withAttributes={isAttributesDisplayed(withGeoMapAttributes, AssetGroupType.equationMaps, mode)}
        checked={checked}
        expanded={expanded}
        treeNodeGetter={() => treeNodeGetter(id)}
        jdProfileIsHealth={jdProfileIsHealth}
        jdProfileIsAuthorized={jdProfileIsAuthorized}
        jdWorkPlanExportAvailable={jdWorkPlanExportAvailable}
        isSynchronizedJohnDeereField={isSynchronizedJohnDeereField}
        showItemMenu={showItemMenu}
        selectedItemUuid={selectedAssetUuid}
        selectedItemGroupType={selectedAssetGroupType}
        offset={offset}
        onCheckboxClick={handleCheckboxClick}
        onClick={() => handleAssetNodeClick(AssetGroupType.equationMaps, equationMap, id)}
        onMenuItemClick={(menuItem) => handleMenuItemClick(menuItem, equationMap)}
        onExpandMoreClick={() => handleExpandMoreClick(AssetGroupType.equationMaps, id)}
      />
    );
  };

  const pinsGroupNodeRenderer = (pinsGroup: PinsGroup, id: string, offset: number) => {
    return (
      <PinsGroupNode
        id={id}
        pinsGroup={pinsGroup}
        mode={getModeValue(AssetGroupType.pinsGroups, mode)}
        checked={checked}
        offset={offset}
        treeNodeGetter={() => treeNodeGetter(id)}
        selectedItemUuid={selectedAssetUuid}
        selectedItemGroupType={selectedAssetGroupType}
        onCheckboxClick={handleCheckboxClick}
        onClick={() => handleAssetNodeClick(AssetGroupType.pinsGroups, pinsGroup, id)}
      />
    );
  };

  const groupPlaceholderNodeRenderer = (assetGroupType: AssetGroupType) => {
    return (
      <GroupPlaceholderNode
        farmUuid={farmUuid}
        fieldUuid={fieldUuid}
        assetGroupType={assetGroupType}
        satelliteImagesLoading={satelliteImagesLoading}
        withAction={hasGroupPlaceholderAction}
        onActionClick={onGroupPlaceholderActionClick}
      />
    );
  };

  const attributeNodeRenderer = (
    assetType: AssetType,
    id: string,
    name: string,
    offset: number,
  ) => {
    const assetGroupType = ASSET_TYPE_TO_ASSET_GROUP[assetType];

    return (
      <AttributeNode
        id={id}
        name={name}
        isExpanded={expanded[id]}
        mode={getModeValue(assetGroupType, mode)}
        checked={checked}
        offset={offset}
        treeNodeGetter={() => treeNodeGetter(id)}
        onCheckboxClick={handleCheckboxClick}
        onAttributeClick={onAttributeNodeClick}
        onExpandMoreClick={() => handleViewTypeNodeClick(id)}
      />
    );
  };

  const rawProcessedAttributeNodeRenderer = (
    assetType: AssetType,
    id: string,
    name: string,
    offset: number,
  ) => {
    const assetGroupType = ASSET_TYPE_TO_ASSET_GROUP[assetType];
    const attributesDisplayed = isAttributesDisplayed(withAttributes, assetGroupType, mode);

    return (
      <AttributeNode
        id={id}
        name={name}
        isExpanded={expanded[id]}
        isParent={attributesDisplayed}
        mode={getModeValue(assetGroupType, mode)}
        checked={checked}
        offset={offset}
        treeNodeGetter={() => treeNodeGetter(id)}
        onCheckboxClick={handleCheckboxClick}
        onExpandMoreClick={() => handleViewTypeNodeClick(id)}
      />
    );
  };

  const dataLayersTree = generateDataLayersTree({
    satelliteImages: assets.satelliteImages
      ? {
        assets: assets.satelliteImages,
        filteredAssets: filteredSatelliteImages,
        satelliteImagesLoading,
        renderer: satelliteImageNodeRenderer,
        filterRenderer: assets.satelliteImages.length ? satelliteFiltersRenderer : null,
        recommendedImages: {
          enabled: showSatelliteRecommendations,
          renderer: recommendedImagesRenderer,
        },
        withAttributes: {
          enabled: isAttributesDisplayed(withAttributes, AssetGroupType.satelliteImages, mode),
          renderer: attributeNodeRenderer,
        },
      }
      : undefined,
    vectorAnalysisMaps: assets.vectorAnalysisMaps
      ? {
        assets: assets.vectorAnalysisMaps,
        filteredAssets: filteredVectorAnalysisMaps,
        renderer: vectorAnalysisMapNodeRenderer,
        filterRenderer: assets.vectorAnalysisMaps?.length ? zonesMapFilterRenderer : null,
      }
      : undefined,
    soilDatasets: assets.soilDatasets
      ? {
        assets: assets.soilDatasets,
        filteredAssets: filteredSoilDatasets,
        renderer: datasetNodeRenderer,
        filterRenderer: assets.soilDatasets?.length
          ? getDefaultAssetGroupFilterRenderer(AssetGroupType.soilDatasets)
          : null,
        withRawProcessed: {
          enabled: isAttributesDisplayed(withRawProcessed, AssetGroupType.soilDatasets, mode),
          renderer: rawProcessedAttributeNodeRenderer,
        },
        withAttributes: {
          enabled: isAttributesDisplayed(withAttributes, AssetGroupType.soilDatasets, mode),
          renderer: attributeNodeRenderer,
        },
        grouping: {
          enabled: isDatasetsGroupingEnabled(grouping, AssetGroupType.soilDatasets, mode),
        },
        isItemSelectable: isDatasetSelectable,
      }
      : undefined,
    yieldDatasets: assets.yieldDatasets
      ? {
        assets: assets.yieldDatasets,
        filteredAssets: filteredYieldDatasets,
        renderer: datasetNodeRenderer,
        filterRenderer: assets.yieldDatasets?.length
          ? getDefaultAssetGroupFilterRenderer(AssetGroupType.yieldDatasets)
          : null,
        withRawProcessed: {
          enabled: isAttributesDisplayed(withRawProcessed, AssetGroupType.yieldDatasets, mode),
          renderer: rawProcessedAttributeNodeRenderer,
        },
        withAttributes: {
          enabled: isAttributesDisplayed(withAttributes, AssetGroupType.yieldDatasets, mode),
          renderer: attributeNodeRenderer,
        },
        grouping: {
          enabled: isDatasetsGroupingEnabled(grouping, AssetGroupType.yieldDatasets, mode),
        },
        isItemSelectable: isDatasetSelectable,
      }
      : undefined,
    asAppliedDatasets: assets.asAppliedDatasets
      ? {
        assets: assets.asAppliedDatasets,
        filteredAssets: filteredAsAppliedDatasets,
        renderer: datasetNodeRenderer,
        filterRenderer: assets.asAppliedDatasets?.length
          ? getDefaultAssetGroupFilterRenderer(AssetGroupType.asAppliedDatasets)
          : null,
        withRawProcessed: {
          enabled: isAttributesDisplayed(withRawProcessed, AssetGroupType.asAppliedDatasets, mode),
          renderer: rawProcessedAttributeNodeRenderer,
        },
        withAttributes: {
          enabled: isAttributesDisplayed(withAttributes, AssetGroupType.asAppliedDatasets, mode),
          renderer: attributeNodeRenderer,
        },
        grouping: {
          enabled: isDatasetsGroupingEnabled(grouping, AssetGroupType.asAppliedDatasets, mode),
        },
        isItemSelectable: isDatasetSelectable,
      }
      : undefined,
    topographyMaps: assets.topographyMaps
      ? {
        assets: assets.topographyMaps,
        filteredAssets: filteredTopographyMaps,
        renderer: topographyNodeRenderer,
        filterRenderer: assets.topographyMaps?.length
          ? getDefaultAssetGroupFilterRenderer(AssetGroupType.topographyMaps)
          : null,
        withAttributes: {
          enabled: isAttributesDisplayed(withAttributes, AssetGroupType.topographyMaps, mode),
          renderer: attributeNodeRenderer,
        },
      }
      : undefined,
    threeDimensionalMaps: assets.threeDimensionalMaps
      ? {
        assets: assets.threeDimensionalMaps,
        filteredAssets: filteredThreeDimensionalMaps,
        renderer: threeDimensionalMapNodeRenderer,
        filterRenderer: assets.threeDimensionalMaps?.length
          ? getDefaultAssetGroupFilterRenderer(AssetGroupType.threeDimensionalMaps)
          : null,
      }
      : undefined,
    equationMaps: assets.equationMaps
      ? {
        assets: assets.equationMaps,
        filteredAssets: filteredEquationMaps,
        renderer: equationMapNodeRenderer,
        isItemSelectable: isEquationMapSelectable,
        filterRenderer: assets.equationMaps?.length
          ? getDefaultAssetGroupFilterRenderer(AssetGroupType.equationMaps)
          : null,
        withAttributes: {
          enabled: isAttributesDisplayed(withGeoMapAttributes, AssetGroupType.equationMaps, mode),
          renderer: attributeNodeRenderer,
        },
      }
      : undefined,
    pinsGroups: assets.pinsGroups
      ? {
        assets: assets.pinsGroups,
        filteredAssets: filteredPinsGroups,
        renderer: pinsGroupNodeRenderer,
        filterRenderer: assets.pinsGroups?.length ? pinsGroupsFilterRenderer : null,
      }
      : undefined,
    assetGroup: {
      renderer: assetGroupNodeRenderer,
      withAssetGroupNode,
    },
    groupPlaceholder: {
      enabled: showEmptyGroups,
      renderer: groupPlaceholderNodeRenderer,
    },
  });

  const {
    treeView,
    ref,
    getNodeIndexById,
  } = useTreeView({
    nodes: dataLayersTree,
    offset: TREE_LEVEL_OFFSET,
    expanded,
  });

  useEffect(() => {
    if (!selectedAssetUuid || !selectedAssetGroupType || !dataLayersTree || selectedAssetPathExpanded) {
      return;
    }

    const node = findNodeById(
      dataLayersTree,
      generateAssetNodeId(selectedAssetUuid, selectedAssetGroupType),
    );

    if (!node) {
      return;
    }

    let nodeParent = node.parent;
    let expandedUpdate = expanded;

    while (nodeParent && !expanded[nodeParent.id]) {
      expandedUpdate = {
        ...expandedUpdate,
        [nodeParent.id]: true,
      };

      nodeParent = nodeParent.parent;
    }

    setExpanded((prev) => {
      return {
        ...prev,
        ...expandedUpdate,
      };
    });

    setSelectedAssetPathExpanded(true);
  }, [
    dataLayersTree,
    selectedAssetUuid,
    selectedAssetGroupType,
    expanded,
    selectedAssetPathExpanded,
  ]);

  useEffect(() => {
    if (!selectedAssetUuid || !selectedAssetGroupType || !dataLayersTree || selectedAssetScrolled) {
      return;
    }

    const node = findNodeById(
      dataLayersTree,
      generateAssetNodeId(selectedAssetUuid, selectedAssetGroupType),
    );

    if (!node) {
      return;
    }

    // setTimeout to wait for expanded items to be rendered
    setTimeout(() => {
      const indexToScroll = getNodeIndexById(node.id);
      ref.current?.scrollToIndex(indexToScroll);
      setSelectedAssetScrolled(true);
      setSelectedAssetPathExpanded(true);
    });
  }, [
    dataLayersTree,
    selectedAssetPathExpanded,
    getNodeIndexById,
    ref,
    selectedAssetUuid,
    selectedAssetGroupType,
    selectedAssetScrolled,
  ]);

  useEffect(() => {
    if (!Object.entries(checked).length) {
      return;
    }

    let parentSelection = {};

    for (const node of traverseTree(dataLayersTree)) {
      if (checked[node.id]) {
        parentSelection = {
          ...parentSelection,
          ...updateParentSelection(node.parent, checked),
        };
      }
    }

    onCheckedChange({
      ...checked,
      ...parentSelection,
    });
    // Checked object is excluded from dependency array in as the onCheckedChanged function modifies the checked object.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    filteredVectorAnalysisMaps,
    filteredSatelliteImages,
    filteredPinsGroups,
    filteredEquationMaps,
    filteredSoilDatasets,
    filteredYieldDatasets,
    filteredAsAppliedDatasets,
    filteredTopographyMaps,
    filteredThreeDimensionalMaps,
  ]);

  return {
    dataLayersTreeComponent: (
      <DataLayersTree
        dataLayersTree={dataLayersTree}
        treeView={treeView}
        collapsible={collapsible}
      >
        {children}
      </DataLayersTree>
    ),
    dataLayersTree,
  };
}
