import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';

import Link from '../../../../../../../components/Link';

const ReadMode = ({
  value,
  withSkeleton,
}) => {
  if (!value) {
    return (
      withSkeleton && <Skeleton variant="text" />
    );
  }

  return (
    <>
      <Link to={value.link}>
        {value.title}
      </Link>
      {value.label}
    </>
  );
};

export default ReadMode;
