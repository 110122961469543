import React from 'react';
import { useTranslation } from 'react-i18next';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import clsx from 'clsx';

import Checkbox from '../../Checkbox';

import './index.scss';

const UseInterpolationCheckbox = ({
  checked,
  classes,
  onChange = () => {},
}: {
  checked: boolean,
  classes?: {
    root?: string;
  },
  onChange: (value: boolean) => void,
}) => {
  const { t } = useTranslation();

  return (
    <FormControlLabel
      classes={{
        root: clsx('use-interpolation-checkbox', classes?.root),
        label: 'use-interpolation-checkbox__label',
      }}
      control={(
        <Checkbox
          value={checked ? 2 : 0}
          onChange={(e) => onChange(e.target.checked)}
        />
            )}
      label={t('zones-ops.common.use-interpolated-data')}
    />
  );
};

export default UseInterpolationCheckbox;
