import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Button from '../../../../../components/Button';
import PopupHeader from '../../../../../components/Popups/PopupHeader';
import SelectOrganization from './Steps/01-SelectOrganization';
import { exportToJohnDeereAsFiles } from '../../jdExportSlice';

import './index.scss';

const ExportToJohnDeerePopup = ({
  vectorAnalysisMaps = [],
  equationMaps = [],
  ratesOnly = false,
  onCancel = () => {},
  onConfirm = () => {},
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [org, onOrganizationChange] = React.useState(0);

  const handleConfirm = () => {
    dispatch(exportToJohnDeereAsFiles({
      orgId: org,
      vectorAnalysisMaps,
      equationMaps,
      ratesOnly,
    }));
    onConfirm();
  };

  return (
    <Dialog open>
      <PopupHeader
        title={t('general.popups.export-to-john-deere.title')}
        onCancel={onCancel}
      />
      <DialogContent
        classes={{ root: 'export-to-john-deere-popup__body' }}
      >
        <SelectOrganization
          organizationId={org}
          onOrganizationChange={(orgId) => onOrganizationChange(orgId)}
        />
      </DialogContent>
      <DialogActions
        classes={{ root: 'export-to-john-deere-popup__actions' }}
      >
        <Button
          variant="outlined"
          onClick={onCancel}
        >
          {t('general.controls.cancel')}
        </Button>
        <Button
          disabled={!org}
          variant="contained"
          color="primary"
          onClick={handleConfirm}
        >
          {t('general.popups.done')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExportToJohnDeerePopup;
