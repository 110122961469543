import React from 'react';
import { useTranslation } from 'react-i18next';

import AuthenticationRedirector from '../routes/redirectors/Authentication';
import PricingRedirector from '../routes/redirectors/Pricing';
import { PAGES_ROOTS } from '../helpers/navigation';
import BaseTemplate from './BaseTemplate.jsx';
import useDidMount from '../hooks/useDidMount';
import { getDocumentTitle } from '../helpers';
import HomepagePanel from '../features/ui/homepage/containers/HomepagePanel';

const Homepage = () => {
  const { t } = useTranslation();

  useDidMount(() => {
    document.title = getDocumentTitle(t('general.navigation.homepage'));
  });

  return (
    <AuthenticationRedirector>
      <PricingRedirector>
        <BaseTemplate
          route={PAGES_ROOTS.homepage}
          headerProps={{
            text: t('general.navigation.homepage'),
          }}
        >
          <HomepagePanel key="panel" />
        </BaseTemplate>
      </PricingRedirector>
    </AuthenticationRedirector>
  );
};

export default Homepage;
