import { API, graphqlOperation } from '@aws-amplify/api';

import refreshVamapStatisticsMutation from './graphql/mutations/refreshVamapStatistics.gql';
import saveVamapMutation from './graphql/mutations/saveVamap.gql';

export const refreshVamapStatistics = async ({
  fieldUuid,
  uuid,
  zonesMapGeojson,
  areaUnit,
}) => {
  const { data } = await API.graphql(graphqlOperation(refreshVamapStatisticsMutation, {
    input: {
      fieldUuid,
      uuid,
      zonesMapGeojson: JSON.stringify(zonesMapGeojson),
      areaUnit,
    },
  }));

  return data?.refreshVectorAnalysisMapStatistics;
};

export const saveVamap = async ({
  fieldUuid,
  uuid,
  name,
  zonesMapGeojson,
  type,
  colors,
  areaUnit,
}) => {
  const vamapInput = {
    fieldUuid,
    uuid,
    name,
  };
  let geoMapsInput;

  if (zonesMapGeojson) {
    vamapInput.zonesMapGeojson = JSON.stringify(zonesMapGeojson);
  }

  if (type) {
    vamapInput.type = type;
  }

  if (colors) {
    geoMapsInput = {
      geoMaps: [
        {
          shortName: 'zones',
          fieldUuid,
          vectorAnalysisMapUuid: uuid,
          hexColorCodes: colors,
        },
        {
          shortName: 'thumbnail',
          fieldUuid,
          vectorAnalysisMapUuid: uuid,
          hexColorCodes: colors,
        },
      ],
    };
  }

  const { data } = await API.graphql(graphqlOperation(saveVamapMutation, {
    vamapInput,
    geoMapsInput,
    areaUnit,
    hasColors: !!colors,
    hasGeojson: !!zonesMapGeojson,
  }));

  return {
    zonesMapGeojson: data?.saveVectorAnalysisMap?.zonesMapGeojson,
    geoMaps: data?.setGeoMaps,
  };
};
