import isBefore from 'date-fns/isBefore';

import { APPLICATION_STATUSES } from '../constants/application';
import { getEndDate } from '../../../../user/helpers/functions/order';
import { STATUSES } from '../../containers/RestrictedAccessCard/config';

export const getApplicationStatus = (paidOrder, credits, hasGroupPermission) => {
  const paidOrderEndDate = getEndDate(paidOrder);

  if (!paidOrderEndDate || !hasGroupPermission) {
    return APPLICATION_STATUSES.BLOCKED;
  }

  if (!isBefore(new Date(), paidOrderEndDate)) {
    return APPLICATION_STATUSES.SUBSCRIPTION_AVAILABLE;
  }

  if (credits <= 0) {
    return APPLICATION_STATUSES.SUBSCRIPTION_AVAILABLE;
  }

  return APPLICATION_STATUSES.AVAILABLE;
};

export const getPlanSubscriptionWorkflowStatus = (paidOrderEndDate) => {
  let result = STATUSES.NO_CREDITS;

  if (!paidOrderEndDate || !isBefore(new Date(), paidOrderEndDate)) {
    result = STATUSES.NO_SUBSCRIPTION;
  }

  return result;
};

export const getApplicationSubscriptionStatus = (hasGroupPermission) => {
  let result = STATUSES.INITIALIZING;

  if (!hasGroupPermission) {
    result = STATUSES.NO_ACCESS;
  }

  return result;
};
