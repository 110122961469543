import { createSelector } from '@reduxjs/toolkit';

export const selectLastEvaluatedKey = ({ fieldsList }) => fieldsList.lastEvaluatedKey;

export const selectFarm = ({ fieldsList }) => fieldsList.farm;

export const selectFilter = ({ fieldsList }) => fieldsList.filter;

export const selectFields = ({ fieldsList }) => fieldsList.fields;

export const selectFieldsMap = createSelector(
  selectFields,
  (fields) => {
    return fields.reduce((acc, field) => {
      acc[field.uuid] = field;

      return acc;
    }, {});
  },
);

export const selectIsLoaded = ({ fieldsList }) => fieldsList.isLoaded;

export const selectField = createSelector(
  selectFields,
  (_, uuid) => uuid,
  (fields, fieldUuid) => {
    return fields.find(({ uuid }) => uuid === fieldUuid);
  },
);
