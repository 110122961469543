import { AssetGroupType } from '../../../../../helpers/constants/entities/asset';

export enum UploadType {
  allTypes = 'all-types',
  fieldBoundaries = 'field-boundaries',
  soilData = 'soil-data',
  yieldData = 'yield-data',
  asApplied = 'as-applied',
  machineryFormats = 'machinery-formats',
}

export const UPLOAD_TYPE_REMAPPING = {
  [UploadType.fieldBoundaries]: 'boundary',
  [UploadType.soilData]: 'soil',
  [UploadType.yieldData]: 'yield',
  [UploadType.asApplied]: 'applied',
  [UploadType.machineryFormats]: 'adapt',
};

export enum UploadStep {
  uploading = 'uploading',
  complete = 'complete',
  failed = 'failed',
}

export enum FileStatus {
  uploading = 'uploading',
  complete = 'complete',
  verified = 'verified',
  failed = 'failed',
}

export const UPLOAD_TYPE_TO_ASSET_GROUP: Partial<Record<UploadType, AssetGroupType>> = {
  [UploadType.soilData]: AssetGroupType.soilDatasets,
  [UploadType.yieldData]: AssetGroupType.yieldDatasets,
  [UploadType.asApplied]: AssetGroupType.asAppliedDatasets,
};

export const UPLOAD_TYPE_I18N_KEY: Partial<Record<UploadType, string>> = {
  [UploadType.fieldBoundaries]: 'general.labels.boundary',
  [UploadType.soilData]: 'general.labels.soil',
  [UploadType.yieldData]: 'general.labels.yield',
  [UploadType.asApplied]: 'general.labels.as-applied',
  [UploadType.machineryFormats]: 'general.labels.machinery-formats',
};
