import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

import RadioGroup from '../../../../../../components/RadioGroup';
import { setAssetType } from '../../../create3dSlice';
import {
  selectAsAppliedDatasets,
  selectSatelliteImages,
  selectSoilDatasets,
  selectTopographyMaps,
  selectYieldDatasets,
} from '../../../../../field/fieldSelectors';
import { AssetType } from '../../../../../../helpers/constants/entities/asset';
import { selectAssetType } from '../../../create3dSelectors';
import useDidMount from '../../../../../../hooks/useDidMount';
import { fetchAllAssets } from '../../../../../field/fieldSlice';

const ShapeDataTypePanel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const params = useParams();
  const satelliteImages = useSelector(selectSatelliteImages);
  const soilDatasets = useSelector(selectSoilDatasets);
  const yieldDatasets = useSelector(selectYieldDatasets);
  const asAppliedDatasets = useSelector(selectAsAppliedDatasets);
  const topographyMaps = useSelector(selectTopographyMaps);
  const assetType = useSelector(selectAssetType);

  const handleAssetTypeChange = (value) => {
    dispatch(setAssetType(value));
  };

  useDidMount(() => {
    const {
      farmUuid,
      fieldUuid,
    } = params;

    dispatch(fetchAllAssets({
      farmUuid,
      fieldUuid,
    }));
  });

  return (
    <RadioGroup
      options={[
        {
          value: AssetType.satelliteImage,
          disabled: true || satelliteImages.length === 0,
          title: t('general.shared.satellite-monitoring'),
        },
        {
          value: AssetType.soilDataset,
          disabled: soilDatasets.length === 0,
          title: t('general.shared.soil-data'),
        },
        {
          value: AssetType.yieldDataset,
          disabled: yieldDatasets.length === 0,
          title: t('general.shared.yield-data'),
        },
        {
          value: AssetType.asAppliedDataset,
          disabled: true || asAppliedDatasets.length === 0,
          title: t('general.shared.as-applied-data'),
        },
        {
          value: AssetType.topographyMap,
          disabled: topographyMaps.length === 0,
          title: t('general.shared.topography'),
        },
      ]}
      value={assetType}
      onChange={handleAssetTypeChange}
    />
  );
};

export default ShapeDataTypePanel;
