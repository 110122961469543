export const PREFIXES = {
  search: '_search',
  empty: '_empty',
  allValues: '_allValues',
};

export const transformFilterUpdates = (filter, labelsMap) => {
  const {
    labels,
    name,
  } = filter.reduce((acc, { prefix, value }) => {
    if (prefix === PREFIXES.search) {
      acc.name = value;
    } else if (value === PREFIXES.empty) {
      acc.labels.push({
        name: prefix,
        value: '',
      });
    } else if (value === PREFIXES.allValues) {
      const labelsToAdd = labelsMap[prefix].map((v) => ({
        name: prefix,
        value: v,
      }));
      acc.labels = [...acc.labels, ...labelsToAdd];
    } else {
      acc.labels.push({
        name: prefix,
        value,
      });
    }

    return acc;
  }, { labels: [], name: null });

  return {
    labels: labels.length ? labels : null,
    name,
  };
};

export const transformFilterValue = ({ name, labels }) => {
  const result = (labels || []).map(({ name: n, value }) => {
    return {
      prefix: n,
      value,
      title: `${n}: ${value}`,
    };
  });

  if (name) {
    result.push({
      prefix: PREFIXES.search,
      value: name,
      title: `search: ${name}`,
    });
  }

  return result;
};

export const getSelectedOptionByUuid = (option, value) => {
  return option.uuid === value.uuid;
};
