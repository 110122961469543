import NoCredits from './NoCredits';
import NoSubscription from './NoSubscription';
import NoAccess from './NoAccess';
import Initializing from './Initializing';

export const STATUSES = {
  NO_CREDITS: 'NO_CREDITS',
  NO_SUBSCRIPTION: 'NO_SUBSCRIPTION',
  NO_ACCESS: 'NO_ACCESS',
  INITIALIZING: 'INITIALIZING',
};

const pages = {
  [STATUSES.NO_CREDITS]: NoCredits,
  [STATUSES.NO_SUBSCRIPTION]: NoSubscription,
  [STATUSES.NO_ACCESS]: NoAccess,
  [STATUSES.INITIALIZING]: Initializing,
};

export default pages;
