import React from 'react';
import { withTranslation } from 'react-i18next';
import i18n from 'i18next';

import {
  DataClassificationType,
} from '../../../helpers/constants/entities/vectorAnalysisMap';
import TextField from '../../TextField';
import ComboBox from '../../ComboBox';
import Button from '../../Button';
import {
  MIN_POLYGON_AREA_MIN,
  MIN_POLYGON_AREA_MAX,
} from '../../../helpers/constants/app';
import UseInterpolationCheckbox from '../UseInterpolationCheckbox';
import {
  MAX_NUMBER_OF_ZONES,
  MIN_NUMBER_OF_ZONES,
} from '../../../features/createAnalysis/helpers/constants/vamap';
import { isNumber } from '../../../helpers/functions/utils/number';

import './index.scss';

const getDataClassificationTypeOptions = (t) => {
  return [
    {
      title: t('zones-ops.common.data-classification-types.natural-breaks'),
      value: DataClassificationType.naturalBreaks,
    },
    {
      title: t('zones-ops.common.data-classification-types.equal-interval'),
      value: DataClassificationType.equalInterval,
    },
    {
      title: t('zones-ops.common.data-classification-types.equal-count'),
      value: DataClassificationType.equalCount,
    },
    {
      title: t('zones-ops.common.data-classification-types.spatially-localized'),
      value: DataClassificationType.spatiallyLocalized,
    },
  ];
};

const getNumberOfZonesValidationMessage = (value) => {
  const isValidNumber = isNumber(value);

  if (isValidNumber && value < MIN_NUMBER_OF_ZONES) {
    return i18n.t('zones-ops.common.number-of-zones.validation.min', {
      min: MIN_NUMBER_OF_ZONES,
    });
  }

  if (isValidNumber && value > MAX_NUMBER_OF_ZONES) {
    return i18n.t('zones-ops.common.number-of-zones.validation.max', {
      max: MAX_NUMBER_OF_ZONES,
    });
  }

  return '';
};

const Panel = ({
  t,
  name,
  polygonMinArea,
  numberOfZones,
  dataClassificationType,
  runAnalysisDisabled,
  useInterpolatedData,
  onPropChange = () => {},
  onRunAnalysis = () => {},
}) => {
  const dataClassificationTypeOptions = getDataClassificationTypeOptions(t);
  const dataClassificationTypeOption = dataClassificationTypeOptions.find((option) => {
    return option.value === dataClassificationType;
  });

  return (
    <div className="ops-creation-panel">
      <TextField
        required
        value={name}
        className="ops-creation-panel__title"
        title={t('zones-ops.common.title')}
        onChange={(e) => onPropChange('name', e.target.value)}
      />
      <TextField
        required
        type="number"
        title={t('zones-ops.common.number-of-zones.title')}
        value={numberOfZones ?? ''}
        helperText={getNumberOfZonesValidationMessage(numberOfZones)}
        onChange={(e) => {
          const newValue = parseInt(e.target.value, 10);
          onPropChange('numberOfZones', isNumber(newValue) ? newValue : null);
        }}
        InputProps={{
          inputProps: {
            min: 0,
          },
        }}
        HelperTextProps={{
          classes: {
            root: 'ops-creation-panel__number-input-error',
          },
        }}
      />
      <ComboBox
        classes={{
          root: 'ops-creation-panel__select',
        }}
        disableClearable
        disableCloseOnSelect={false}
        title={t('zones-ops.common.data-classification-type')}
        options={dataClassificationTypeOptions}
        value={dataClassificationTypeOption || dataClassificationTypeOptions[0]}
        getOptionSelected={(option, value) => {
          return option.value === value.value;
        }}
        onChange={(_event, { value }) => onPropChange('dataClassificationType', value)}
      />
      <TextField
        title={t('zones-ops.common.min-polygon-area')}
        type="number"
        value={polygonMinArea}
        onChange={(e) => onPropChange('polygonMinArea', parseInt(e.target.value, 10))}
        InputProps={{
          inputProps: {
            min: MIN_POLYGON_AREA_MIN,
            max: MIN_POLYGON_AREA_MAX,
          },
        }}
      />
      <UseInterpolationCheckbox
        classes={{
          root: 'ops-creation-panel__interpolation-checkbox',
        }}
        checked={useInterpolatedData}
        onChange={(value) => onPropChange('useInterpolatedData', value)}
      />
      <Button
        disabled={runAnalysisDisabled}
        className="ops-creation-panel__run-btn"
        variant="contained"
        color="primary"
        onClick={onRunAnalysis}
      >
        {t('zones-ops.common.run-analysis')}
      </Button>
    </div>
  );
};

export default withTranslation()(Panel);
