import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../../app/store/helpers/functions';
import {
  selectCurrentEquation,
  selectIsCurrentEquationSelected,
  selectVerifyEquation,
} from '../../../../equations/equationsSelectors';
import EquationPreview from '../../components/EquationPreview';
import EquationMapSettings from '../../components/EquationMapSettings';
import { ProductUnit } from '../../../../../helpers/constants/units/productUnit';
import {
  selectEditMode,
  selectFullScreenEditor,
  selectGridSize,
  selectType,
  selectUseInterpolatedData,
} from '../../createBatchEquationBasedAnalysisSelectors';
import {
  toggleFullScreenEditor,
  setGridXSize,
  setGridYSize,
  setType,
  setUseInterpolatedData,
  setEditMode,
} from '../../createBatchEquationBasedAnalysisSlice';
import type {
  VectorAnalysisMapType,
} from '../../../../../helpers/constants/entities/vectorAnalysisMap';
import {
  resetVerifyEquation,
  updateCurrentEquation,
  verifyEquation,
} from '../../../../equations/equationsSlice';
import EquationPlaceholder from '../../components/EquationPlaceholder';
import ScrollContainer from '../../../../../components/ScrollContainer';
import FormulaEditor from '../../components/FormulaEditor';
import Button from '../../../../../components/Button';
import {
  filterEmptyVariables,
  isNewEquation,
} from '../../../../equations/helpers/functions/equations';
import VariablesEditor from '../../components/VariablesEditor';
import { getDebouncer } from '../../../../../helpers/functions/utils/debouncer';
import EquationTitle from '../../components/EquationTitle';
import { openPopup } from '../../../popups/popupsSlice';
import { POPUPS } from '../../../popups/helpers/constants/popups';
import { EMPTY_EQUATION } from '../../../../equations/helpers/constants/equations';

import './index.scss';

const TIMEOUT = 500; // ms
const verificationDebouncer = getDebouncer(TIMEOUT);

export default function EquationPanel() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const equation = useAppSelector(selectCurrentEquation);
  const {
    errorMessage: verifyEquationErrorMessage,
    inProgress: verifyEquationLoading,
  } = useAppSelector(selectVerifyEquation);
  const currentEquationSelected = useAppSelector(selectIsCurrentEquationSelected);
  const type = useAppSelector(selectType);
  const gridSize = useAppSelector(selectGridSize);
  const useInterpolatedData = useAppSelector(selectUseInterpolatedData);
  const fullScreenEditor = useAppSelector(selectFullScreenEditor);
  const editMode = useAppSelector(selectEditMode);

  const [equationUpdate, setEquationUpdate] = useState(equation);

  const saveDisabled = !equationUpdate.equationAsText
    || verifyEquationLoading
    || !!verifyEquationErrorMessage;

  useEffect(() => {
    // Enable editMode if a new equation is creating, otherwise disable it
    dispatch(setEditMode(isNewEquation({ uuid: equation.uuid })));

    // Reset equation update when another equation is selected
    setEquationUpdate({
      ...EMPTY_EQUATION,
      productUnit: EMPTY_EQUATION.productUnit || '',
    });
  }, [dispatch, equation.uuid]);

  const orderEquationVerification = (params: {
    equationAsText?: string,
    dataVariables?: string[],
    equationResultVariable?: string,
    useNumpy?: boolean,
  }) => {
    const defaultParams = {
      equationAsText: equationUpdate.equationAsText,
      dataVariables: equationUpdate.dataVariables,
      equationResultVariable: equationUpdate.equationResultVariable,
      useNumpy: equationUpdate.useNumpy,
    };

    verificationDebouncer(() => {
      dispatch(verifyEquation({
        ...defaultParams,
        ...params,
      }));
    });
  };

  const handleProductUnitChange = (productUnit: ProductUnit | '' | null) => {
    if (productUnit == null) {
      return;
    }

    dispatch(updateCurrentEquation({
      productUnit,
    }));
  };

  const handlePurposeChange = (purpose: VectorAnalysisMapType | null) => {
    if (purpose == null) {
      return;
    }

    dispatch(setType(purpose));
  };

  const handleGridXChange = (size: number) => {
    dispatch(setGridXSize(size));
  };

  const handleGridYChange = (size: number) => {
    dispatch(setGridYSize(size));
  };

  const handleUseInterpolatedDataChange = (value:boolean) => {
    dispatch(setUseInterpolatedData(value));
  };

  const handleAdjustEquationClick = () => {
    dispatch(setEditMode(true));
    setEquationUpdate(equation);
  };

  const handleUseNumpyChange = () => {
    setEquationUpdate({
      ...equationUpdate,
      useNumpy: !equationUpdate.useNumpy,
    });
    orderEquationVerification({
      useNumpy: !equationUpdate.useNumpy,
    });
  };

  const handleFullScreenModeChange = () => {
    dispatch(toggleFullScreenEditor());
  };

  const handleEquationChange = (equationAsText: string) => {
    setEquationUpdate({
      ...equationUpdate,
      equationAsText,
    });
    orderEquationVerification({
      equationAsText,
    });
  };

  const handleTestRunClick = () => {
    dispatch(openPopup({
      type: POPUPS.equationTestRun,
      equationAsText: equationUpdate.equationAsText,
      equationResultVariable: equationUpdate.equationResultVariable,
      dataVariables: filterEmptyVariables(equationUpdate.dataVariables),
      useNumpy: equationUpdate.useNumpy,
    }));
  };

  const handleDataVariablesUpdate = (variables: string[]) => {
    setEquationUpdate({
      ...equationUpdate,
      dataVariables: variables,
    });
    orderEquationVerification({
      dataVariables: variables,
    });
  };

  const handleOutputVariableChange = (value: string) => {
    setEquationUpdate({
      ...equationUpdate,
      equationResultVariable: value,
    });
    orderEquationVerification({
      equationResultVariable: value,
    });
  };

  const handleSaveEquationClick = () => {
    dispatch(openPopup({
      type: POPUPS.createEquation,
      equation: equationUpdate,
      onConfirm: (update: {
        uuid: string,
        title: string,
        description: string,
        sourceUrl: string,
      }) => {
        dispatch(setEditMode(false));
        dispatch(updateCurrentEquation({
          ...equationUpdate,
          ...update,
        }));
      },
    }));
  };

  const handleCancelClick = () => {
    dispatch(setEditMode(false));
    dispatch(resetVerifyEquation());
  };

  const handleSaveClick = () => {
    dispatch(setEditMode(false));
    dispatch(updateCurrentEquation(equationUpdate));
  };

  return (
    <div className="equation-panel">
      {
        currentEquationSelected
          ? (
            <>
              <EquationTitle
                uuid={equation.uuid || ''}
                equationTitle={equation.title}
                editMode={editMode}
              />
              <ScrollContainer classes={{ root: 'equation-panel__scroll-container' }}>
                <EquationMapSettings
                  productUnit={equation.productUnit}
                  purpose={type}
                  gridX={gridSize.x}
                  gridY={gridSize.y}
                  useInterpolatedData={useInterpolatedData}
                  onProductUnitChange={handleProductUnitChange}
                  onPurposeChange={handlePurposeChange}
                  onGridXChange={handleGridXChange}
                  onGridYChange={handleGridYChange}
                  onUseInterpolatedDataChange={handleUseInterpolatedDataChange}
                />
                {
                  editMode
                    ? (
                      <>
                        <FormulaEditor
                          uuid={equationUpdate.uuid || ''}
                          equationAsText={equationUpdate.equationAsText}
                          useNumpy={equationUpdate.useNumpy}
                          fullScreenMode={fullScreenEditor}
                          errorMessage={verifyEquationErrorMessage}
                          errorsLoading={verifyEquationLoading}
                          onUseNumpyChange={handleUseNumpyChange}
                          onFullScreenModeChange={handleFullScreenModeChange}
                          onEquationChange={handleEquationChange}
                          onTestRunClick={handleTestRunClick}
                        />
                        <VariablesEditor
                          dataVariables={equationUpdate.dataVariables}
                          resultVariable={equationUpdate.equationResultVariable}
                          onDataVariablesUpdate={handleDataVariablesUpdate}
                          onOutputVariableChange={handleOutputVariableChange}
                        />
                      </>
                    )
                    : (
                      <EquationPreview
                        equationAsText={equation.equationAsText}
                        dataVariables={equation.dataVariables}
                        equationResultVariable={equation.equationResultVariable}
                        onAdjustEquationClick={handleAdjustEquationClick}
                      />
                    )
                }
              </ScrollContainer>
              {
                editMode
                  && (
                    <div className="equation-panel__actions">
                      <Button
                        className="equation-panel__action_left"
                        variant="outlined"
                        color="primary"
                        disabled={saveDisabled}
                        onClick={handleSaveEquationClick}
                      >
                        {t('general.popups.create-equation.title')}
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleCancelClick}
                      >
                        {t('general.controls.cancel')}
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        disabled={saveDisabled}
                        onClick={handleSaveClick}
                      >
                        {t('general.controls.save')}
                      </Button>
                    </div>
                  )
              }
            </>
          )
          : <EquationPlaceholder />
      }
    </div>
  );
}
