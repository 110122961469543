export const PERIODS_MAP = {
  TODAY: 'today',
  LAST_7_DAYS: 'last-7-days',
  CURRENT_MONTH: 'current-month',
  THREE_MONTHS: '3-months',
  FOR_PERIOD: 'for-period',
};

export const PERIODS_TO_I18N_KEY_MAP = {
  [PERIODS_MAP.TODAY]: 'credits-usage.periods.today',
  [PERIODS_MAP.LAST_7_DAYS]: 'credits-usage.periods.last-7-days',
  [PERIODS_MAP.CURRENT_MONTH]: 'credits-usage.periods.current-month',
  [PERIODS_MAP.THREE_MONTHS]: 'credits-usage.periods.3-months',
  [PERIODS_MAP.FOR_PERIOD]: 'credits-usage.periods.for-period',
};
