import React from 'react';
import { connect } from 'react-redux';

import ScrollContainer from '../../../../../../components/ScrollContainer';
import SelectList from '../../../../../../components/SelectList';
import ZonesMapItem from '../../../../dataLayersView/components/Items/ZonesMapItem';
import { setZonesMapUuid } from '../../../create3dSlice';
import { selectZonesMapUuid } from '../../../create3dSelectors';
import { selectVectorAnalysisMaps } from '../../../../../field/fieldSelectors';

import './index.scss';

const ZonesMapPanel = ({
  items,
  selectedUuids,
  onItemClick,
}) => {
  return (
    <ScrollContainer classes={{ root: 'zones-map-panel-create-3d' }}>
      <SelectList
        multiValue={false}
        items={items}
        selectedUuids={selectedUuids}
        itemRenderer={(item) => {
          return (
            <ZonesMapItem zonesMap={item} />
          );
        }}
        onClick={onItemClick}
      />
    </ScrollContainer>
  );
};

const mapStateToProps = (state) => {
  const vectorAnalysisMaps = selectVectorAnalysisMaps(state);
  const zonesMap = selectZonesMapUuid(state);

  return {
    items: vectorAnalysisMaps,
    selectedUuids: [zonesMap],
  };
};

const mapDispatchToProps = (dispatch) => ({
  onItemClick: ({ uuid }) => {
    dispatch(setZonesMapUuid(uuid));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ZonesMapPanel);
