import { CustomError } from '../errorHandling';

const ENV_VARIABLE_PREFIX = 'REACT_APP_';

export const readEnvVariable = (variable: string): string => {
  const fullVariable = ENV_VARIABLE_PREFIX + variable;
  const result = process.env[fullVariable];

  if (result == null) {
    throw new CustomError(`[Environment] Variable ${fullVariable} is not defined.`);
  }

  return result;
};
