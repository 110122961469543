import React, {
  Fragment, Suspense, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { useSelector, useDispatch } from 'react-redux';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import AddIcon from '@material-ui/icons/Add';

import Button from '../../../../../../../components/Button';
import Loading from '../../../../../../../components/Loading';
import Tabs from '../../../../../../../components/Tabs';
import Stepper from '../../../../../../../components/Stepper';
import Panel from '../../../Panel/DataLayers';
import DataLayersPreviews from '../../../DataLayersPreviews';
import BackdropLoading from '../../../../../../../components/Loading/BackdropLoading';
import { goToPrevStep } from '../../../../../zonesOps/zonesOpsSlice';
import {
  addDataLayer,
  changeActiveDataLayer,
  deleteDataLayer,
  navigateToSettings,
} from '../../../../createMultiLayerAnalysisSlice';
import { SATELLITE_INDEXES, formatAcquisitionDate } from '../../../../../../../helpers/satellite';
import Message from '../../../../../../../components/Messages/Message';
import {
  selectCreateAnalysisAssets,
  selectIsAllAssetsLoaded,
} from '../../../../../../field/fieldSelectors';
import {
  selectActiveDataLayer,
  selectActiveDataLayerIndex,
  selectActiveImageUuid,
  selectDataLayers,
  selectIsValidSetup,
} from '../../../../createMultiLayerAnalysisSelectors';
import { fetchAllAssets } from '../../../../../../field/fieldSlice';
import { selectSelectedField } from '../../../../../zonesOps/zonesOpsSelectors';
import useDidMount from '../../../../../../../hooks/useDidMount';
import { AssetType } from '../../../../../../../helpers/constants/entities/asset';

import './index.scss';

const Map = React.lazy(() => import('../../../Map'));

const getDatasetLayerPostfix = (layer) => {
  return layer.attribute && layer.datasetUuid ? 1 : null;
};

const getDataLayerTabs = (dataLayers, t) => {
  return dataLayers.map((layer) => {
    const { type } = layer;
    let label;

    if (type === AssetType.satelliteImage) {
      label = {
        primary: t('general.shared.satellite-monitoring'),
        secondary: layer.index,
        primaryPostfix: layer.satelliteImageUuids && layer.satelliteImageUuids.length
          ? layer.satelliteImageUuids.length
          : null,
      };
    } else if (type === AssetType.soilDataset) {
      label = {
        primary: t('general.shared.soil-data'),
        secondary: layer.attribute,
        primaryPostfix: getDatasetLayerPostfix(layer),
      };
    } else if (type === AssetType.yieldDataset) {
      label = {
        primary: t('general.shared.yield-data'),
        secondary: layer.attribute,
        primaryPostfix: getDatasetLayerPostfix(layer),
      };
    } else if (type === AssetType.asAppliedDataset) {
      label = {
        primary: t('general.shared.as-applied-data'),
        secondary: layer.attribute,
        primaryPostfix: getDatasetLayerPostfix(layer),
      };
    } else if (type === AssetType.topographyMap) {
      label = {
        primary: t('general.shared.topography'),
        secondary: layer.attribute,
        primaryPostfix: getDatasetLayerPostfix(layer),
      };
    }

    return {
      label,
    };
  });
};

const getDataLayersOptions = ({
  satelliteImages,
  soilDatasets,
  yieldDatasets,
  asAppliedDatasets,
  topographyMaps,
}) => {
  const result = [];

  if (satelliteImages.length > 0) {
    result.push({
      type: AssetType.satelliteImage,
      label: i18n.t('general.shared.satellite-monitoring'),
    });
  }

  if (soilDatasets.length > 0) {
    result.push({
      type: AssetType.soilDataset,
      label: i18n.t('general.shared.soil-data'),
    });
  }

  if (yieldDatasets.length > 0) {
    result.push({
      type: AssetType.yieldDataset,
      label: i18n.t('general.shared.yield-data'),
    });
  }

  if (asAppliedDatasets.length > 0) {
    result.push({
      type: AssetType.asAppliedDataset,
      label: i18n.t('general.shared.as-applied-data'),
    });
  }

  if (topographyMaps.length > 0) {
    result.push({
      type: AssetType.topographyMap,
      label: i18n.t('general.shared.topography'),
    });
  }

  return result;
};

const createDataLayer = (layerType) => {
  const layer = {
    type: layerType,
    weight: 1,
  };

  if (layerType === AssetType.satelliteImage) {
    layer.index = SATELLITE_INDEXES[0].value;
    layer.satelliteImageUuids = [];
  } else if (
    layerType === AssetType.soilDataset
    || layerType === AssetType.yieldDataset
    || layerType === AssetType.asAppliedDataset
    || layerType === AssetType.topographyMap
  ) {
    layer.attribute = '';
    layer.datasetUuid = '';
  }

  return layer;
};

const StepperDataLayers = ({
  steps,
  stepIndex,
  step,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    farmUuid,
    fieldUuid,
  } = useSelector(selectSelectedField);
  const activeDataLayerIndex = useSelector(selectActiveDataLayerIndex);
  const activeDataLayer = useSelector(selectActiveDataLayer);
  const activeSatelliteImageUuid = useSelector(selectActiveImageUuid);
  const dataLayers = useSelector(selectDataLayers);
  const isValidSetup = useSelector(selectIsValidSetup);
  const isFieldLoaded = useSelector(selectIsAllAssetsLoaded);
  const assets = useSelector(selectCreateAnalysisAssets);
  const dataLayersOptions = getDataLayersOptions(assets);

  const addDataLayerButtonRef = useRef(null);
  const [addNewDataLayerOpen, setAddNewDataLayerOpen] = useState(false);

  const requestDataLayers = () => {
    let requiredSatImages;
    const satelliteImagesLayer = dataLayers.find((layer) => {
      return layer.type === AssetType.satelliteImage;
    });

    if (satelliteImagesLayer) {
      requiredSatImages = satelliteImagesLayer.satelliteImageUuids;
    }

    dispatch(fetchAllAssets({
      farmUuid,
      fieldUuid,
      requiredSatImages,
    }));
  };

  const activeAssetTitle = useMemo(() => {
    let title = '';

    if (activeDataLayer?.type === AssetType.satelliteImage) {
      const satelliteImage = assets.satelliteImages.find(({ uuid }) => {
        return uuid === activeSatelliteImageUuid;
      });
      title = satelliteImage?.satelliteImage
        ? formatAcquisitionDate(satelliteImage.satelliteImage.acquisitionDate)
        : '';
    } else if (activeDataLayer?.type === AssetType.soilDataset) {
      const dataset = assets.soilDatasets.find(({ uuid }) => {
        return uuid === activeDataLayer.datasetUuid;
      });
      title = dataset?.name;
    } else if (activeDataLayer?.type === AssetType.yieldDataset) {
      const dataset = assets.yieldDatasets.find(({ uuid }) => {
        return uuid === activeDataLayer.datasetUuid;
      });
      title = dataset?.name;
    } else if (activeDataLayer?.type === AssetType.asAppliedDataset) {
      const dataset = assets.asAppliedDatasets.find(({ uuid }) => {
        return uuid === activeDataLayer.datasetUuid;
      });
      title = dataset?.name;
    } else if (activeDataLayer?.type === AssetType.topographyMap) {
      const topographyMap = assets.topographyMaps.find(({ uuid }) => {
        return uuid === activeDataLayer.datasetUuid;
      });
      title = topographyMap?.name;
    }

    return title;
  }, [
    assets,
    activeDataLayer,
    activeSatelliteImageUuid,
  ]);

  const onClickNext = () => {
    dispatch(navigateToSettings());
  };

  const onClickBack = () => {
    dispatch(goToPrevStep());
  };

  const onActiveDataLayerIndexChange = (index) => {
    dispatch(changeActiveDataLayer(index));
  };

  const onDataLayerTabClose = (value) => {
    dispatch(deleteDataLayer(value));
  };

  const onAddDataLayer = (layer) => {
    dispatch(addDataLayer(layer));
  };

  useDidMount(requestDataLayers);

  let content;

  if (isFieldLoaded) {
    if (dataLayersOptions.length === 0) {
      content = (
        <Message key="panel" messageTextLocation="zones-ops.common.no-assets-message" />
      );
    } else {
      content = [
        <div key="tabs" className="data-layers-tabs">
          {
            dataLayers.length !== 0
              && (
                <Tabs
                  hasClose
                  tabs={getDataLayerTabs(dataLayers, t)}
                  value={activeDataLayerIndex}
                  classes={{
                    root: 'data-layers-tabs__tabs',
                  }}
                  onTabChange={(_event, value) => onActiveDataLayerIndexChange(value)}
                  onTabClose={(_event, value) => onDataLayerTabClose(value)}
                />
              )
          }
          <Button
            ref={addDataLayerButtonRef}
            startIcon={<AddIcon />}
            classes={{
              root: 'data-layers-tabs__add-tab-btn',
            }}
            onClick={() => setAddNewDataLayerOpen(true)}
          >
            {t('zones-ops.multi-layer.steps.3.add-data-layer')}
          </Button>
          <Menu
            anchorEl={addDataLayerButtonRef.current}
            open={addNewDataLayerOpen}
            onClose={() => setAddNewDataLayerOpen(false)}
          >
            {
              dataLayersOptions.map((option) => {
                return (
                  <MenuItem
                    key={option.type}
                    classes={{
                      root: 'data-layers-tabs__menu-item',
                    }}
                    onClick={() => {
                      const dataLayer = createDataLayer(option.type);

                      onAddDataLayer(dataLayer);
                      setAddNewDataLayerOpen(false);
                    }}
                  >
                    {option.label}
                  </MenuItem>
                );
              })
            }
          </Menu>
        </div>,
        <Panel key="panel" step={step} />,
        <Suspense key="map" fallback={<Loading />}>
          <Map step={step} />
        </Suspense>,
        <Fragment key="map-title">{activeAssetTitle}</Fragment>,
        <DataLayersPreviews key="previews" />,
      ];
    }
  } else {
    content = (
      <BackdropLoading key="loader" />
    );
  }

  return (
    <Stepper
      activeStep={stepIndex}
      steps={steps}
      nextDisabled={!isValidSetup}
      onClickBack={onClickBack}
      onClickNext={onClickNext}
    >
      { content }
    </Stepper>
  );
};

export default StepperDataLayers;
