import React, { Fragment } from 'react';
import TableCell from '@material-ui/core/TableCell';

import Link from '../../../../../../components/Link';
import { getCellClasses } from '../../../helpers/functions/cell';
import { isEnterKeyPressed } from '../../../../../../helpers/functions/utils/navigation';

import './index.scss';

const ClickOperationCell = ({
  row = {},
  headCell = {},
}) => {
  const {
    link,
    text,
    type,
  } = row[headCell.id] || {};
  const isRowAndText = row[headCell.id] && text;
  const onOpenPopup = headCell.onOpenPopup || (() => {});

  return (
    <TableCell
      align={headCell.horizontalAlign || 'left'}
      className={getCellClasses('click-operation-cell', headCell, row)}
    >
      {
        isRowAndText
          && (
            <Fragment>
              {
              type === 'link' && link && <Link to={link}>{text}</Link>
            }
              {
              type === 'navLink' && link && <a className="link" href={link} target="_blank">{text}</a>
            }
              {
              type === 'popup'
                && (
                  <span
                    role="button"
                    tabIndex={0}
                    className="click-operation-cell__item-popup"
                    onClick={() => onOpenPopup(row[headCell.id].popup)}
                    onKeyDown={(event) => {
                      if (isEnterKeyPressed(event)) {
                        onOpenPopup(row[headCell.id].popup);
                      }
                    }}
                  >
                    {text}
                  </span>
                )
            }
              {
              type === 'text' && <span>{text}</span>
            }
            </Fragment>
          )
      }
    </TableCell>
  );
};

export default ClickOperationCell;
