import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Legend from '../../../../../components/Legend';
import AssetLegendContent from '../../../../../components/Legend/AssetLegendContent';
import {
  selectAttributesLoading,
  selectField,
} from '../../../../field/fieldSelectors';
import { sendFeatureUsage } from '../../../../../helpers/analytics';
import { PAGES_ROOTS } from '../../../../../helpers/navigation';
import { selectApiKey, selectAreaUnit } from '../../../../user/userSelectors';
import {
  selectIsLegendOpen,
  selectLegendUuid,
} from '../../compareLayersSelectors';
import { resetLegend } from '../../compareLayersSlice';
import { fetchVamapAttributesJson } from '../../../../field/fieldSlice';
import { isVectorAnalysis } from '../../../../../helpers/functions/entities/assets';
import { getSelectedItem } from '../../../../../helpers/components/legend';

import './index.scss';

const CompareLayersLegend = () => {
  const dispatch = useDispatch();

  const field = useSelector(selectField);
  const isOpen = useSelector(selectIsLegendOpen);
  const loading = useSelector(selectAttributesLoading);
  const item = getSelectedItem(useSelector(selectLegendUuid), field);
  const areaUnit = useSelector(selectAreaUnit);
  const apiKey = useSelector(selectApiKey);

  const type = (item || {})._type;

  const requestAttributes = useCallback((data) => {
    if (isVectorAnalysis(data)) {
      dispatch(fetchVamapAttributesJson({ uuid: data.uuid }));
    }
  }, [dispatch]);

  const onLegendClose = () => {
    dispatch(resetLegend());
  };

  useEffect(() => {
    if (!item) {
      return;
    }

    requestAttributes(item);
  }, [item, requestAttributes]);

  useEffect(() => {
    if (!type) {
      return;
    }

    sendFeatureUsage('legend', 'view', {
      type,
      screen: PAGES_ROOTS.compareLayers,
    });
  }, [type]);

  if (!isOpen) {
    return null;
  }

  return (
    <Legend
      className="compare-layers-legend"
      loading={loading}
      title={item.name}
      onClose={onLegendClose}
      content={(
        <AssetLegendContent
          item={item}
          field={field}
          areaUnit={areaUnit}
          apiKey={apiKey}
        />
      )}
    />
  );
};

export default CompareLayersLegend;
