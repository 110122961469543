import React, { Fragment, MouseEvent } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Typography from '@material-ui/core/Typography';
import AddIcon from '@material-ui/icons/Add';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import ButtonMenu from '../../../../components/ButtonMenu';
import type { ButtonMenuItem } from '../../../../components/ButtonMenu/types';
import type { ListPanelItem } from './types';

import './index.scss';

const ListPanel = ({
  title,
  addButtonLabel,
  emptyAddedItemsLabel,
  items = [],
  activeItemsValues,
  menuItems,
  required = false,
  onItemClick,
  onAddClick,
  onMenuItemClick = () => {},
}: {
  title: string,
  addButtonLabel?: string,
  emptyAddedItemsLabel?: string;
  items: ListPanelItem[],
  activeItemsValues: Set<string>,
  menuItems?: ButtonMenuItem[],
  required?: boolean,
  onItemClick: (e: MouseEvent, i: ListPanelItem) => void,
  onAddClick?: () => void,
  onMenuItemClick?: (m: ButtonMenuItem, i: ListPanelItem, ind: number) => void,
}) => {
  const getActionContent = (item: ListPanelItem, index: number) => {
    let actionContent;

    if (menuItems) {
      actionContent = (
        <ListItemSecondaryAction>
          <ButtonMenu
            isIconButton
            edge="end"
            size="small"
            items={menuItems}
            classes={{
              iconButton: {
                root: 'list-panel__item-menu',
              },
              buttonMenuItem: {
                buttonLabel: 'list-panel__item-menu-item',
              },
            }}
            onItemClick={(e) => onMenuItemClick(e, item, index)}
          >
            <MoreVertIcon fontSize="small" />
          </ButtonMenu>
        </ListItemSecondaryAction>
      );
    } else {
      actionContent = <Fragment />;
    }

    return actionContent;
  };

  return (
    <div className="list-panel">
      <Typography className="list-panel__title">
        {title}
        {required && <span className="required-asterisk">*</span>}
      </Typography>
      <List className="list-panel__list">
        {
          addButtonLabel && onAddClick
            && (
              <ListItem
                className="list-panel__add-button"
                button
                onClick={onAddClick}
              >
                <ListItemIcon className="list-panel__add-button-icon">
                  <AddIcon />
                </ListItemIcon>
                <ListItemText
                  primary={addButtonLabel}
                  classes={{
                    primary: 'list-panel__add-button-text',
                  }}
                />
              </ListItem>
            )
        }
        {
          items.length === 0 && emptyAddedItemsLabel
            ? (
              <ListItem className="list-panel__empty-item">
                {emptyAddedItemsLabel}
              </ListItem>
            )
            : items.map((item, index) => (
              <ListItem
                key={index}
                className="list-panel__item"
                button
                selected={activeItemsValues.has(item.value)}
                onClick={(e) => onItemClick(e, item)}
              >
                <span className="list-panel__item-text">
                  {item.title}
                </span>
                {getActionContent(item, index)}
              </ListItem>
            ))
        }
      </List>
    </div>
  );
};

export default ListPanel;
