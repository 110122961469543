import FieldProfiler from '../pages/FieldProfiler.jsx';
import ZonesOps from '../pages/ZonesOps.jsx';
import ZonesMaps from '../pages/ZonesMaps.jsx';
import ZonesMap from '../pages/ZonesMap.jsx';
import Field from '../pages/Field.jsx';
import CreateField from '../pages/CreateField.jsx';
import UploadData from '../pages/UploadData.jsx';
import UploadDataJohnDeere from '../pages/UploadDataJohnDeere.jsx';
import CompareLayers from '../pages/CompareLayers.jsx';
import UserIntegrations from '../pages/UserIntegrations';
import UserSettings from '../pages/UserSettings.jsx';
import CreditsUsage from '../pages/CreditsUsage';
import UserOrganizations from '../pages/UserOrganizations.jsx';
import UserFarms from '../pages/UserFarms.jsx';
import ExportData from '../pages/ExportData.jsx';
import PlanSubscription from '../pages/PlanSubscription';
import ExportDataJohnDeere from '../pages/ExportDataJohnDeere.jsx';
import Create3d from '../pages/Create3d.jsx';
import NewUser from '../pages/NewUser.jsx';
import RestrictedAccess from '../pages/RestrictedAccess.jsx';
import SmartSampling from '../pages/SmartSampling.jsx';
import Homepage from '../pages/Homepage.jsx';
import OperationsWorkflow from '../pages/OperationsWorkflow';
import Authentication from '../pages/Authentication.jsx';
import BatchAnalytics from '../pages/BatchAnalytics';
import { getRootLink, PAGES_ROOTS } from '../helpers/navigation';

const config = [
  {
    path: '/',
    component: Homepage,
    exact: true,
  },
  {
    path: '/auth/:view',
    component: Authentication,
    exact: true,
  },
  {
    path: `${getRootLink(PAGES_ROOTS.field)}/:farmUuid/:fieldUuid/:assetGroup?/:itemUuid?`,
    component: Field,
  },
  {
    path: getRootLink(PAGES_ROOTS.fields),
    component: FieldProfiler,
  },
  {
    path: `${getRootLink(PAGES_ROOTS.compareLayers)}/:farmUuid/:fieldUuid`,
    component: CompareLayers,
  },
  {
    path: `${getRootLink(PAGES_ROOTS.create3d)}/:farmUuid/:fieldUuid`,
    component: Create3d,
  },
  {
    path: getRootLink(PAGES_ROOTS.createField),
    component: CreateField,
  },
  {
    path: `${getRootLink(PAGES_ROOTS.zonesOps)}/:workflow/:farmUuid?/:fieldUuid?`,
    component: ZonesOps,
  },
  {
    path: getRootLink(PAGES_ROOTS.batchAnalytics),
    component: BatchAnalytics,
  },
  {
    path: getRootLink(PAGES_ROOTS.zonesMaps),
    component: ZonesMaps,
  },
  {
    path: `${getRootLink(PAGES_ROOTS.zonesMap)}/:farmUuid/:fieldUuid/:uuid`,
    component: ZonesMap,
  },
  {
    path: getRootLink(PAGES_ROOTS.uploadDataJohnDeere),
    component: UploadDataJohnDeere,
  },
  {
    path: getRootLink(PAGES_ROOTS.upload),
    component: UploadData,
  },
  {
    path: getRootLink(PAGES_ROOTS.userIntegrations),
    component: UserIntegrations,
  },
  {
    path: getRootLink(PAGES_ROOTS.userSettings),
    component: UserSettings,
  },
  {
    path: getRootLink(PAGES_ROOTS.userCreditsUsage),
    component: CreditsUsage,
  },
  {
    path: getRootLink(PAGES_ROOTS.userOrganizations),
    component: UserOrganizations,
  },
  {
    path: getRootLink(PAGES_ROOTS.userFarms),
    component: UserFarms,
  },
  {
    path: getRootLink(PAGES_ROOTS.exportDataJohnDeere),
    component: ExportDataJohnDeere,
  },
  {
    path: getRootLink(PAGES_ROOTS.exportData),
    component: ExportData,
  },
  {
    path: getRootLink(PAGES_ROOTS.operations),
    component: OperationsWorkflow,
  },
  {
    path: `${getRootLink(PAGES_ROOTS.planSubscription)}/:success?`,
    component: PlanSubscription,
  },
  {
    path: getRootLink(PAGES_ROOTS.newUser),
    component: NewUser,
  },
  {
    path: getRootLink(PAGES_ROOTS.restrictedAccess),
    component: RestrictedAccess,
  },
  {
    path: getRootLink(PAGES_ROOTS.smartSampling),
    component: SmartSampling,
  },
  {
    component: FieldProfiler,
  },
];

export default config;
