import { AreaUnit } from '../../../../user/helpers/constants/user';

export enum PricingTableType {
  hectares = 'hectares',
  acres = 'acres',
}

export const AREA_UNIT_TO_PRICING_TABLE_TYPE: Partial<Record<AreaUnit, PricingTableType>> = {
  [AreaUnit.hectares]: PricingTableType.hectares,
  [AreaUnit.acres]: PricingTableType.acres,
};
