const noop = () => {};

class AnalyticsProcessor {
  static get dispatcher() {
    return window.gtag || noop;
  }
}

export const sendPageView = (path, title) => {
  AnalyticsProcessor.dispatcher('event', 'page_view', {
    page_title: title,
    page_path: path,
  });
};

export const sendTiming = (time, category) => {
  AnalyticsProcessor.dispatcher('event', 'timing_complete', {
    name: 'usage-time',
    value: time,
    event_category: category,
  });
};

export const sendFeatureUsage = (feature, action, details = {}) => {
  AnalyticsProcessor.dispatcher('event', 'feature_usage', {
    feature,
    action,
    ...details,
  });
};
