import { createSelector } from '@reduxjs/toolkit';

import type { RootState } from '../../app/store/helpers/types';
import { EquationCategory } from './helpers/constants/equations';

export const selectFilterSearch = ({ equations }: RootState) => equations.filter.search;

export const selectEquationsLoading = ({ equations }: RootState) => equations.equations.loading;

export const selectFilterCategory = ({ equations }: RootState) => equations.filter.category;

export const selectCustomUserEquations = ({ equations }: RootState) => {
  return equations.equations.customUser;
};

export const selectCustomOrganizationEquations = ({ equations }: RootState) => {
  return equations.equations.customOrganization;
};

export const selectMasterEquations = ({ equations }: RootState) => {
  return equations.equations.master;
};

export const selectCurrentCategoryEquations = createSelector(
  selectCustomUserEquations,
  selectCustomOrganizationEquations,
  selectMasterEquations,
  selectFilterCategory,
  (
    customUserEquations,
    customOrganizationEquations,
    masterEquations,
    category,
  ) => {
    let result;

    if (category === EquationCategory.customUser) {
      result = customUserEquations;
    } else if (category === EquationCategory.customOrganization) {
      result = customOrganizationEquations;
    } else {
      result = masterEquations;
    }

    return result;
  },
);

export const selectCurrentEquation = ({ equations }: RootState) => {
  return equations.currentEquation;
};

export const selectIsCurrentEquationSelected = ({ equations }: RootState) => {
  return equations.currentEquation.uuid != null;
};

export const selectVerifyEquation = ({ equations }: RootState) => {
  return equations.verifyEquation;
};

export const selectIsCurrentEquationValid = createSelector(
  selectCurrentEquation,
  selectIsCurrentEquationSelected,
  selectVerifyEquation,
  (currentEquation, currentEquationSelected, verifyEquation) => {
    return currentEquationSelected
      && !!currentEquation.equationAsText
      && !verifyEquation.errorMessage
      && !verifyEquation.inProgress;
  },
);
