import { isNumberValueValid } from '../../../../helpers/functions/utils/number';
import { TransformedVectorAnalysisMap } from '../../../../helpers/types/vectorAnalysisMap';
import {
  MAX_NUMBER_OF_ZONES,
  MIN_NUMBER_OF_ZONES,
} from '../constants/vamap';

export const isVectorAnalysisMapGenerated = (generatedVectorAnalysisMap:TransformedVectorAnalysisMap | null) => {
  return !!generatedVectorAnalysisMap?.zonesMapGeojson
    && !!generatedVectorAnalysisMap?.geoMaps;
};

export const isNumberOfZonesValueValid = (value: number | null) => {
  return isNumberValueValid(value, MIN_NUMBER_OF_ZONES, MAX_NUMBER_OF_ZONES);
};
