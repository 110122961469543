import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { EXPORT_TYPES } from '../../../../features/exportData/helpers/constants/export-types';
import { ISOXML_STATUSES } from '../../../../helpers/analysis';
import RadioGroup from '../../../RadioGroup';
import ExportData from '..';

const getIsoXmlItemDescription = (t, isoXmlStatus) => {
  return isoXmlStatus === ISOXML_STATUSES.VALID
    ? t('general.popups.export-zones-map.items.isoxml.descriptions.valid')
    : t('general.popups.export-equation-map.items.isoxml.descriptions.invalid');
};

const ExportEquationMap = ({ isoXmlStatus, ...props }) => {
  const { t } = useTranslation();
  const options = [
    {
      value: EXPORT_TYPES.multipolygons,
      title: t('general.popups.export-zones-map.items.shapefile-multipolygons.title'),
      description: t('general.popups.export-zones-map.items.shapefile-multipolygons.description'),
    },
    {
      value: EXPORT_TYPES.polygons,
      title: t('general.popups.export-zones-map.items.shapefile-polygons.title'),
      description: t('general.popups.export-zones-map.items.shapefile-polygons.description'),
    },
    {
      value: EXPORT_TYPES.isoxml,
      disabled: isoXmlStatus !== ISOXML_STATUSES.VALID,
      title: t('general.popups.export-zones-map.items.isoxml.title'),
      description: getIsoXmlItemDescription(t, isoXmlStatus),
    },
  ];
  const [type, setType] = useState(options[0].value);

  return (
    <ExportData
      {...props}
      title={t('general.popups.export-equation-map.title')}
      content={(
        <RadioGroup
          options={options}
          value={type}
          onChange={(newType) => setType(newType)}
        />
      )}
      onConfirm={() => props.onConfirm(type)}
    />
  );
};

export default ExportEquationMap;
