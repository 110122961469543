import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

import { DEFAULT_VIEW } from '../../helpers/constants/authentication';
import { getCode } from '../../helpers/functions/navigation';
import BackdropLoading from '../../../../../components/Loading/BackdropLoading';
import useDidMount from '../../../../../hooks/useDidMount';
import views from './config';

const AuthenticationPanel = () => {
  const { view = DEFAULT_VIEW } = useParams();
  const [loading, setLoading] = useState(false);
  const Component = views[view];

  useDidMount(() => {
    const code = getCode(window.location.search);

    // Display loader while getting redirected by auth provider
    setLoading(!!code);
  });

  return (
    <>
      { loading && <BackdropLoading /> }
      <Component onLoading={setLoading} />
    </>
  );
};

export default AuthenticationPanel;
