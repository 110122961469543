import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Legend from '../../../../../components/Legend';
import { selectApiKey, selectAreaUnit } from '../../../../user/userSelectors';
import { sendFeatureUsage } from '../../../../../helpers/analytics';
import { PAGES_ROOTS } from '../../../../../helpers/navigation';
import { resetLegendAsset, setLegendChartScale } from '../../downloadFilesSlice';
import { selectLegend } from '../../downloadFilesSelectors';
import {
  selectAllVectorAnalysisMaps,
  selectAsset,
  selectFarm,
  selectField,
} from '../../../../exportData/exportDataSelectors';
import { isField } from '../../../../../helpers/functions/entities/assets';
import FieldLegendContent from '../../../../../components/Legend/FieldLegendContent';
import AssetLegendContent from '../../../../../components/Legend/AssetLegendContent';

const DownloadFilesLegend = () => {
  const dispatch = useDispatch();

  const {
    asset: {
      farmUuid,
      fieldUuid,
      uuid,
      type,
    },
    chartScale,
    loading,
  } = useSelector(selectLegend);
  const apiKey = useSelector(selectApiKey);
  const areaUnit = useSelector(selectAreaUnit);
  const farm = useSelector((state) => selectFarm(state, farmUuid));
  const field = useSelector((state) => selectField(state, fieldUuid));
  const vectorAnalysisMaps = useSelector(selectAllVectorAnalysisMaps);
  const legendItem = useSelector((state) => selectAsset(state, uuid, type));
  const legendField = {
    ...field,
    vectorAnalysisMaps,
  };

  const handleLegendClose = () => {
    dispatch(resetLegendAsset());
  };

  const handleChartScaleChange = (scale) => {
    dispatch(setLegendChartScale(scale));
  };

  useEffect(() => {
    if (!type) {
      return;
    }

    sendFeatureUsage('legend', 'view', {
      type,
      screen: PAGES_ROOTS.exportData,
    });
  }, [type]);

  if (!legendItem) {
    return null;
  }

  let content = null;

  if (isField(legendItem)) {
    content = (
      <FieldLegendContent
        field={legendItem}
        farm={farm}
        satelliteImages={field.satelliteImages}
        apiKey={apiKey}
        areaUnit={areaUnit}
        chartScale={chartScale}
        onChartScaleChange={handleChartScaleChange}
      />
    );
  } else {
    content = (
      <AssetLegendContent
        item={legendItem}
        field={legendField}
        areaUnit={areaUnit}
        apiKey={apiKey}
      />
    );
  }

  return (
    <Legend
      loading={loading}
      title={legendItem.name}
      content={content}
      onClose={handleLegendClose}
    />
  );
};

export default DownloadFilesLegend;
