import React from 'react';
import { withTranslation, Trans } from 'react-i18next';

import TextFieldPopup from '../TextFieldPopup';

const RenameEntity = ({
  t,
  i18nKey = '',
  entityName = '',
  onCancel = () => {},
  onConfirm = () => {},
}) => {
  return (
    <TextFieldPopup
      title={t(`general.popups.rename-entity.title.${i18nKey}`)}
      textValue={entityName}
      description={(
        <Trans i18nKey={`general.popups.rename-entity.description.${i18nKey}`}>
          Text <span className="text-field-popup__content-text_highlighted">{{ entityName }}</span>
        </Trans>
      )}
      placeholder={t(`general.popups.rename-entity.placeholder.${i18nKey}`)}
      confirmText={t('general.controls.rename')}
      onCancel={onCancel}
      onConfirm={onConfirm}
    />
  );
};

export default withTranslation()(RenameEntity);
