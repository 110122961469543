import i18n from 'i18next';

import { ButtonMenuItem } from '../../../../../components/ButtonMenu/types';
import {
  isAsAppliedDataset,
  isEquationMap,
  isSatelliteImage,
  isSoilDataset,
  isTopographyMap,
  isVectorAnalysis,
  isYieldDataset,
} from '../../../../../helpers/functions/entities/assets';
import {
  getValueOptionsArray,
  getSortedValueOptionsArray,
} from '../../../../../helpers/functions/components/uiList';
import {
  getDatasetAttributeNameHandler,
  getDatasetViewProps,
  getDatasetAttributeFullName,
  addAttributesAndSortDatasets,
} from '../../../../../helpers/functions/entities/dataset';
import {
  addAttributesAndSortTopographyMaps,
  getTopographyMapAttributeName,
  getTopographyMapAttributeNameHandler,
  getTopographyMapViewProps,
} from '../../../../../helpers/functions/entities/topographyMap';
import {
  convertToGenerateMapIndex,
  formatAcquisitionDate,
  SATELLITE_INDEXES,
} from '../../../../../helpers/satellite';
import {
  VariableItem,
  VariableAttributes,
  AssignableAsset,
} from '../../types/variable';
import { VariableMenuAction } from '../constants/variables';
import { AssetGroupType } from '../../../../../helpers/constants/entities/asset';
import { generateAssetNodeId } from '../../../dataLayersView/helpers/functions/dataLayersTree';
import type { TransformedSatelliteImage } from '../../../../satelliteImages/types/satelliteImage';
import type {
  TransformedAsAppliedDataset,
  TransformedSoilDataset,
  TransformedTopographyMap,
  TransformedYieldDataset,
} from '../../../../../helpers/types/dataset';
import type {
  SatelliteImagesVariable,
  SoilDatasetVariable,
  YieldDatasetVariable,
  AsAppliedDatasetVariable,
  TopographyMapVariable,
  InputDataVariable,
  VectorAnalysisMapVariable,
  EquationMapVariable,
} from '../../../../../helpers/types/equationMap/dataVariableInput';
import { TransformedVectorAnalysisMap } from '../../../../../helpers/types/vectorAnalysisMap';
import { TransformedEquationMap } from '../../../../../helpers/types/equationMap';

export const isSatelliteImagesVariable = (
  dataVariable: Partial<InputDataVariable>,
): dataVariable is SatelliteImagesVariable => {
  return 'satelliteImageUuids' in dataVariable;
};

export const isSoilDatasetVariable = (
  dataVariable: Partial<InputDataVariable>,
): dataVariable is SoilDatasetVariable => {
  return 'soilDatasetUuid' in dataVariable;
};

export const isYieldDatasetVariable = (
  dataVariable: Partial<InputDataVariable>,
): dataVariable is YieldDatasetVariable => {
  return 'yieldDatasetUuid' in dataVariable;
};

export const isAsAppliedDatasetVariable = (
  dataVariable: Partial<InputDataVariable>,
): dataVariable is AsAppliedDatasetVariable => {
  return 'asAppliedDatasetUuid' in dataVariable;
};

export const isTopographyMapVariable = (
  dataVariable: Partial<InputDataVariable>,
): dataVariable is TopographyMapVariable => {
  return 'topographyMapUuid' in dataVariable;
};

export const isVectorAnalysisMapVariable = (
  dataVariable: Partial<InputDataVariable>,
): dataVariable is VectorAnalysisMapVariable => {
  return 'vectorAnalysisMapUuid' in dataVariable;
};

export const isEquationMapVariable = (
  dataVariable: Partial<InputDataVariable>,
): dataVariable is EquationMapVariable => {
  return 'equationMapUuid' in dataVariable;
};

export const getVariablesActions = (): ButtonMenuItem[] => [
  {
    id: VariableMenuAction.changeVariable,
    label: i18n.t('general.controls.change'),
  },
  {
    id: VariableMenuAction.deleteVariable,
    label: i18n.t('general.controls.delete'),
  },
];

export const getSatelliteImagesVariableName = (
  satelliteImages?: TransformedSatelliteImage[],
  selectedSatelliteImageUuids?: string[] | null,
) => {
  const images = (satelliteImages ?? []).filter((image) => {
    return selectedSatelliteImageUuids?.includes(image.uuid);
  }, []) ?? [];

  return images
    .map((image) => {
      return formatAcquisitionDate(image.satelliteImage.acquisitionDate);
    })
    .join(', ');
};

export const getDataVariableItems = ({
  dataVariables,
  soilDatasets,
  yieldDatasets,
  asAppliedDatasets,
  topographyMaps,
  satelliteImages,
  vectorAnalysisMaps,
  equationMaps,
}: {
  dataVariables: InputDataVariable[];
  soilDatasets: TransformedSoilDataset[];
  yieldDatasets: TransformedYieldDataset[];
  asAppliedDatasets: TransformedAsAppliedDataset[];
  topographyMaps: TransformedTopographyMap[];
  satelliteImages: TransformedSatelliteImage[];
  vectorAnalysisMaps: TransformedVectorAnalysisMap[];
  equationMaps: TransformedEquationMap[];
}): VariableItem[] => {
  return dataVariables.map((dataVariable) => {
    let label;
    let name;
    let selectedAttribute;

    if (isSoilDatasetVariable(dataVariable)) {
      const soilDataset = soilDatasets.find(
        (dataset) => dataset.uuid === dataVariable.soilDatasetUuid,
      );

      selectedAttribute = getDatasetAttributeFullName(dataVariable.soilAttribute, soilDataset?.fullAttributes);
      name = soilDataset?.name;
    } else if (isYieldDatasetVariable(dataVariable)) {
      const yieldDataset = yieldDatasets.find(
        (dataset) => dataset.uuid === dataVariable.yieldDatasetUuid,
      );

      selectedAttribute = getDatasetAttributeFullName(dataVariable.yieldAttribute, yieldDataset?.fullAttributes);
      name = yieldDataset?.name;
    } else if (isAsAppliedDatasetVariable(dataVariable)) {
      const asAppliedDataset = asAppliedDatasets.find(
        (dataset) => dataset.uuid === dataVariable.asAppliedDatasetUuid,
      );

      selectedAttribute = getDatasetAttributeFullName(
        dataVariable.asAppliedAttribute,
        asAppliedDataset?.fullAttributes,
      );
      name = asAppliedDataset?.name;
    } else if (isTopographyMapVariable(dataVariable)) {
      const topographyMap = topographyMaps.find(
        (dataset) => dataset.uuid === dataVariable.topographyMapUuid,
      );

      selectedAttribute = getTopographyMapAttributeName(
        dataVariable.topographyAttribute,
        topographyMap?.fullAttributes,
      );
      name = topographyMap?.name;
    } else if (isSatelliteImagesVariable(dataVariable)) {
      label = i18n.t(
        'zones-ops.equation-based.steps.4.panel.variables-list.satelliteImagery',
      );
      selectedAttribute = dataVariable.index;
      name = getSatelliteImagesVariableName(satelliteImages, dataVariable.satelliteImageUuids);
    } else if (isVectorAnalysisMapVariable(dataVariable)) {
      const vectorAnalysisMap = vectorAnalysisMaps.find(
        (dataset) => dataset.uuid === dataVariable.vectorAnalysisMapUuid,
      );

      name = vectorAnalysisMap?.name;
    } else if (isEquationMapVariable(dataVariable)) {
      const equationMap = equationMaps.find(
        (dataset) => dataset.uuid === dataVariable.equationMapUuid,
      );

      name = equationMap?.name;
    }

    return {
      name: dataVariable.variable || '',
      dataset: {
        name,
        label,
        selectedAttribute,
      },
    };
  });
};

export const prepareVariableAttributes = ({
  dataVariable,
  soilDatasets,
  yieldDatasets,
  asAppliedDatasets,
  topographyMaps,
}: {
  dataVariable: InputDataVariable;
  soilDatasets: TransformedSoilDataset[];
  yieldDatasets: TransformedYieldDataset[];
  asAppliedDatasets: TransformedAsAppliedDataset[];
  topographyMaps: TransformedTopographyMap[];
}): VariableAttributes => {
  let attributes;
  let selectedAttribute;

  if (isSoilDatasetVariable(dataVariable)) {
    const dataset = getSelectedAsset(soilDatasets, dataVariable.soilDatasetUuid);
    const [selectedData] = dataset ? addAttributesAndSortDatasets([dataset]) : [];

    attributes = getSortedValueOptionsArray(
      selectedData.attributes,
      getDatasetAttributeNameHandler(selectedData.fullAttributes),
    );
    selectedAttribute = dataVariable.soilAttribute;
  } else if (isYieldDatasetVariable(dataVariable)) {
    const dataset = getSelectedAsset(yieldDatasets, dataVariable.yieldDatasetUuid);
    const [selectedData] = dataset ? addAttributesAndSortDatasets([dataset]) : [];

    attributes = getSortedValueOptionsArray(
      selectedData.attributes,
      getDatasetAttributeNameHandler(selectedData.fullAttributes),
    );
    selectedAttribute = dataVariable.yieldAttribute;
  } else if (isAsAppliedDatasetVariable(dataVariable)) {
    const dataset = getSelectedAsset(asAppliedDatasets, dataVariable.asAppliedDatasetUuid);
    const [selectedData] = dataset ? addAttributesAndSortDatasets([dataset]) : [];

    attributes = getSortedValueOptionsArray(
      selectedData.attributes,
      getDatasetAttributeNameHandler(selectedData.fullAttributes),
    );
    selectedAttribute = dataVariable.asAppliedAttribute;
  } else if (isTopographyMapVariable(dataVariable)) {
    const topographyMap = getSelectedAsset(topographyMaps, dataVariable.topographyMapUuid);
    const [selectedData] = topographyMap ? addAttributesAndSortTopographyMaps([topographyMap]) : [];

    attributes = getValueOptionsArray(
      selectedData.attributes,
      getTopographyMapAttributeNameHandler(selectedData.fullAttributes),
    );
    selectedAttribute = dataVariable.topographyAttribute;
  } else if (isSatelliteImagesVariable(dataVariable) && dataVariable.satelliteImageUuids.length) {
    attributes = SATELLITE_INDEXES;
    selectedAttribute = dataVariable.index;
  }

  return {
    datasetAttributes: attributes ?? [],
    selectedAttribute: selectedAttribute ?? '',
  };
};

export const updateDataVariableDataset = (
  asset: AssignableAsset,
  dataVariable: InputDataVariable,
): InputDataVariable | null => {
  let result: InputDataVariable | null = null;

  if (isSoilDataset(asset)) {
    const dataVariableAttribute = isSoilDatasetVariable(dataVariable)
      ? dataVariable.soilAttribute
      : null;

    result = {
      soilDatasetUuid: asset.uuid,
      soilAttribute: getDatasetViewProps(asset, dataVariableAttribute).attribute,
    };
  } else if (isYieldDataset(asset)) {
    const dataVariableAttribute = isYieldDatasetVariable(dataVariable)
      ? dataVariable.yieldAttribute
      : null;

    result = {
      yieldDatasetUuid: asset.uuid,
      yieldAttribute: getDatasetViewProps(asset, dataVariableAttribute).attribute,
    };
  } else if (isAsAppliedDataset(asset)) {
    const dataVariableAttribute = isAsAppliedDatasetVariable(dataVariable)
      ? dataVariable.asAppliedAttribute
      : null;

    result = {
      asAppliedDatasetUuid: asset.uuid,
      asAppliedAttribute: getDatasetViewProps(asset, dataVariableAttribute).attribute,
    };
  } else if (isTopographyMap(asset)) {
    const dataVariableAttribute = isTopographyMapVariable(dataVariable)
      ? dataVariable.topographyAttribute
      : null;

    result = {
      topographyMapUuid: asset.uuid,
      topographyAttribute: getTopographyMapViewProps(asset, dataVariableAttribute, true).attribute,
    };
  } else if (isSatelliteImage(asset)) {
    result = isSatelliteImagesVariable(dataVariable)
      ? {
        satelliteImageUuids: dataVariable.satelliteImageUuids,
        index: dataVariable.index,
      }
      : {
        satelliteImageUuids: [],
        index: '',
      };
  } else if (isVectorAnalysis(asset)) {
    result = {
      vectorAnalysisMapUuid: asset.uuid,
    };
  } else if (isEquationMap(asset)) {
    result = {
      equationMapUuid: asset.uuid,
    };
  }

  if (result) {
    result.variable = dataVariable.variable;
  }

  return result;
};

export const updateDataVariableAttribute = (
  attribute: string,
  dataVariable: InputDataVariable,
  datasetAttributes: { title: string, value: string }[],
): InputDataVariable => {
  const updatedDataVariable: InputDataVariable = { ...dataVariable };

  if (isSoilDatasetVariable(updatedDataVariable)) {
    updatedDataVariable.soilAttribute = attribute;
  } else if (isYieldDatasetVariable(updatedDataVariable)) {
    updatedDataVariable.yieldAttribute = attribute;
  } else if (isAsAppliedDatasetVariable(updatedDataVariable)) {
    updatedDataVariable.asAppliedAttribute = attribute;
  } else if (isTopographyMapVariable(updatedDataVariable)) {
    updatedDataVariable.topographyAttribute = attribute;
  } else if (isSatelliteImagesVariable(updatedDataVariable)) {
    const index = convertToGenerateMapIndex(attribute);

    if (getAttributeOptionValue(datasetAttributes, index)) {
      updatedDataVariable.index = index;
    }
  }

  return updatedDataVariable;
};

export const isVariableDataSelected = (dataVariable: Partial<InputDataVariable>): boolean => {
  return (
    (
      isSoilDatasetVariable(dataVariable)
        && !!dataVariable.soilDatasetUuid
        && !!dataVariable.soilAttribute
    )
    || (
      isYieldDatasetVariable(dataVariable)
        && !!dataVariable.yieldDatasetUuid
        && !!dataVariable.yieldAttribute
    )
    || (
      isAsAppliedDatasetVariable(dataVariable)
        && !!dataVariable.asAppliedDatasetUuid
        && !!dataVariable.asAppliedAttribute
    )
    || (
      isTopographyMapVariable(dataVariable)
        && !!dataVariable.topographyMapUuid
        && !!dataVariable.topographyAttribute
    )
    || (
      isSatelliteImagesVariable(dataVariable)
        && !!dataVariable.satelliteImageUuids.length
        && !!dataVariable.index
    )
    || (
      isVectorAnalysisMapVariable(dataVariable)
        && !!dataVariable.vectorAnalysisMapUuid
    )
    || (
      isEquationMapVariable(dataVariable)
        && !!dataVariable.equationMapUuid
    )
  );
};

export const isVariableDataChanged = (
  activeDataVariable: InputDataVariable,
  currentDataVariable: InputDataVariable,
): boolean => {
  const sortedActiveDataVariable = JSON.stringify(activeDataVariable, Object.keys(activeDataVariable).sort());
  const sortedCurrentDataVariable = JSON.stringify(currentDataVariable, Object.keys(currentDataVariable).sort());

  return sortedActiveDataVariable !== sortedCurrentDataVariable;
};

export const getSelectedAsset = <T extends AssignableAsset>(datasets: T[], dataVariableUuid?: string) => {
  return datasets.find((dataset) => dataset.uuid === dataVariableUuid);
};

export const getAssetGroupCheckedLayers = ({
  checkedUuids,
  groupType,
}:
{
  checkedUuids?: string[] | null,
  groupType: AssetGroupType,
}): Record<string, number> => {
  return (checkedUuids ?? []).reduce((acc, imageUuid) => {
    return { ...acc, [generateAssetNodeId(imageUuid, groupType)]: 2 };
  }, {});
};

export const getDataVariableCheckedLayers = (dataVariable: InputDataVariable): Record<string, number> => {
  return isSatelliteImagesVariable(dataVariable)
    ? getAssetGroupCheckedLayers({
      checkedUuids: dataVariable.satelliteImageUuids,
      groupType: AssetGroupType.satelliteImages,
    })
    : {};
};

export const getAttributeOptionValue = (
  attributeOptions: ({ title: string, value: string } | string)[] = [],
  attribute = '',
) => {
  const selectedOption = attributeOptions.find((option) => {
    const optionValue = typeof option === 'string' ? option : option.value;
    return optionValue.toUpperCase() === attribute.toUpperCase();
  });

  if (!selectedOption) {
    return '';
  }

  return typeof selectedOption === 'string' ? selectedOption : selectedOption.value;
};
