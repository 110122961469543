import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import AccountBoxSharpIcon from '@material-ui/icons/AccountBoxSharp';
import EmailSharpIcon from '@material-ui/icons/EmailSharp';
import LockSharpIcon from '@material-ui/icons/LockSharp';
import PhoneAndroidSharpIcon from '@material-ui/icons/PhoneAndroidSharp';

import TwoSidePanel from '../../../../components/TwoSidePanel';
import Button from '../../../../../../../components/Button';
import TextField from '../../../../../../../components/TextField';
import Link from '../../../../../../../components/Link';
import FederatedLogInPanel from '../common/FederatedLogIn';
import {
  getMultilineHelperText,
  hasErrors,
  prepareAttribute,
  prepareEmailAttribute,
} from '../../../../helpers/functions/authentication';
import { getPasswordHelperTexts } from '../../../../helpers/functions/password';
import { getAuthLink } from '../../../../../../../helpers/navigation';
import { View } from '../../../../helpers/constants/authentication';
import { HelperTextType } from '../../../../helpers/constants/helperTexts';
import { SIGN_UP_ERRORS } from '../../../../helpers/constants/errors';
import { errorNotify } from '../../../../../../notifications/helpers/functions/notify';
import { CustomError } from '../../../../../../../helpers/functions/utils/errorHandling';

import '../common/index.scss';

const SignUpPanel = ({
  onLoading,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    password: '',
    email: '',
    phone: '',
  });
  const [formHelperTexts, setFormHelperTexts] = useState({
    firstName: [],
    lastName: [],
    password: [],
    email: [],
    phone: [],
  });

  const handlePasswordInputButtonClick = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleFieldChange = (field, value) => {
    let fieldHelperTexts;

    if (field === 'password') {
      fieldHelperTexts = getPasswordHelperTexts(value);
    } else {
      fieldHelperTexts = [];
    }

    setFormState((prevFormState) => {
      return {
        ...prevFormState,
        [field]: value,
      };
    });
    setFormHelperTexts((prevFormHelperTexts) => {
      return {
        ...prevFormHelperTexts,
        [field]: fieldHelperTexts,
      };
    });
  };

  const onSignUpClick = () => {
    const preparedEmail = prepareEmailAttribute(formState.email);

    onLoading(true);
    setFormHelperTexts({
      firstName: [],
      lastName: [],
      password: [],
      email: [],
      phone: [],
    });

    Auth.signUp({
      username: preparedEmail,
      password: formState.password,
      attributes: {
        email: preparedEmail,
        given_name: prepareAttribute(formState.firstName),
        family_name: prepareAttribute(formState.lastName),
        phone_number: prepareAttribute(formState.phone),
      },
    })
      .then(() => {
        history.push({
          pathname: getAuthLink(View.signUpSent),
          state: {
            email: preparedEmail,
          },
        });
      })
      .catch((e) => {
        const error = SIGN_UP_ERRORS.find((err) => {
          return err.regexp.test(e.message);
        });

        if (error) {
          setFormHelperTexts((prevFormHelperTexts) => {
            return {
              ...prevFormHelperTexts,
              [error.field]: [
                {
                  label: error.helperText,
                  type: HelperTextType.error,
                },
              ],
            };
          });
        } else {
          errorNotify({
            error: new CustomError('[UI Sign Up] Unable to sign up.', {
              cause: e,
            }),
            message: e.message,
            dispatch,
          });
        }
      })
      .finally(() => {
        onLoading(false);
      });
  };

  const signUpButtonDisabled = () => {
    const hasRequiredFields = formState.firstName.trim()
      && formState.lastName.trim()
      && formState.password.trim()
      && formState.email.trim();

    return !hasRequiredFields || hasErrors(formHelperTexts);
  };

  const getFieldErrorState = (field) => {
    return formHelperTexts[field].some(({ type }) => {
      return type === HelperTextType.error;
    });
  };

  return (
    <div className="authentication-panel">
      <Typography
        classes={{
          root: 'authentication-panel__header',
        }}
        variant="h1"
      >
        Get started today
      </Typography>
      <TwoSidePanel
        left={<FederatedLogInPanel key="left-panel" />}
        right={(
          <div key="right-panel">
            <Typography
              classes={{
                root: 'authentication-panel__tip',
              }}
            >
              Sign up with a new account
            </Typography>
            <TextField
              value={formState.firstName}
              error={getFieldErrorState('firstName')}
              helperText={getMultilineHelperText(formHelperTexts.firstName)}
              placeholder="First name"
              InputProps={{
                classes: {
                  error: 'textfield__input_error',
                },
                startAdornment: (
                  <AccountBoxSharpIcon
                    fontSize="small"
                    className="authentication-panel__input-icon authentication-panel__input-icon_start"
                  />
                ),
              }}
              FormHelperTextProps={{
                classes: {
                  root: 'authentication-panel__input-helper-text',
                },
              }}
              onChange={(e) => handleFieldChange('firstName', e.target.value)}
            />
            <TextField
              value={formState.lastName}
              error={getFieldErrorState('lastName')}
              helperText={getMultilineHelperText(formHelperTexts.lastName)}
              placeholder="Last name"
              InputProps={{
                classes: {
                  error: 'textfield__input_error',
                },
                startAdornment: (
                  <AccountBoxSharpIcon
                    fontSize="small"
                    className="authentication-panel__input-icon authentication-panel__input-icon_start"
                  />
                ),
              }}
              FormHelperTextProps={{
                classes: {
                  root: 'authentication-panel__input-helper-text',
                },
              }}
              onChange={(e) => handleFieldChange('lastName', e.target.value)}
            />
            <TextField
              value={formState.email}
              error={getFieldErrorState('email')}
              helperText={getMultilineHelperText(formHelperTexts.email)}
              placeholder="Email"
              InputProps={{
                classes: {
                  error: 'textfield__input_error',
                },
                startAdornment: (
                  <EmailSharpIcon
                    fontSize="small"
                    className="authentication-panel__input-icon authentication-panel__input-icon_start"
                  />
                ),
              }}
              FormHelperTextProps={{
                classes: {
                  root: 'authentication-panel__input-helper-text',
                },
              }}
              onChange={(e) => handleFieldChange('email', e.target.value)}
            />
            <TextField
              value={formState.password}
              error={getFieldErrorState('password')}
              helperText={getMultilineHelperText(formHelperTexts.password)}
              placeholder="Password 8+ characters"
              type={passwordVisible ? 'text' : 'password'}
              InputProps={{
                classes: {
                  adornedEnd: 'textfield__input_adorned-end',
                  error: 'textfield__input_error',
                },
                startAdornment: (
                  <LockSharpIcon
                    fontSize="small"
                    className="authentication-panel__input-icon authentication-panel__input-icon_start"
                  />
                ),
                endAdornment: (
                  <IconButton
                    className="authentication-panel__input-icon-button"
                    size="small"
                    disableRipple
                    onClick={handlePasswordInputButtonClick}
                  >
                    {
                        passwordVisible
                          ? (
                            <VisibilityOffIcon
                              fontSize="small"
                              className="authentication-panel__input-icon"
                            />
                          )
                          : (
                            <VisibilityIcon
                              fontSize="small"
                              className="authentication-panel__input-icon"
                            />
                          )
                      }
                  </IconButton>
                ),
              }}
              FormHelperTextProps={{
                classes: {
                  root: 'authentication-panel__input-helper-text',
                },
              }}
              onChange={(e) => handleFieldChange('password', e.target.value)}
            />
            <TextField
              value={formState.phone}
              error={getFieldErrorState('phone')}
              helperText={getMultilineHelperText(formHelperTexts.phone)}
              placeholder="Phone number (optional)"
              InputProps={{
                classes: {
                  error: 'textfield__input_error',
                },
                startAdornment: (
                  <PhoneAndroidSharpIcon
                    fontSize="small"
                    className="authentication-panel__input-icon authentication-panel__input-icon_start"
                  />
                ),
              }}
              FormHelperTextProps={{
                classes: {
                  root: 'authentication-panel__input-helper-text',
                },
              }}
              onChange={(e) => handleFieldChange('phone', e.target.value)}
            />
            <Button
              className="authentication-panel__button"
              variant="contained"
              color="primary"
              disabled={signUpButtonDisabled()}
              onClick={onSignUpClick}
            >
              Get started now
            </Button>
            <div className="authentication-panel__account-option">
              <Typography
                className='authentication-panel__tip'
              >
                Already have account?
              </Typography>
              <Link
                className="authentication-panel__link"
                to={getAuthLink(View.logIn)}
              >
                Log in
              </Link>
            </div>
          </div>
          )}
      />
    </div>
  );
};

export default SignUpPanel;
