import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  goToSettings as goToCrossLayerSettings,
} from '../crossLayer/crossLayerSlice';
import {
  goBackFromSettings as goBackFromMultiLayerSettings,
  goToSettings as goToMultiLayerSettings,
} from '../createMultiLayerAnalysis/createMultiLayerAnalysisSlice';
import {
  goBackFromDataLayers as goBackFromSingleLayerDataLayers,
  goToSettings as goToSingleLayerSettings,
} from '../createSingleLayerAnalysis/createSingleLayerAnalysisSlice';
import {
  goToSettings as goToFieldPotentialSettings,
} from '../createFieldPotentialZones/createFieldPotentialZonesSlice';
import {
  goToSettings as goToStabilitySettings,
} from '../createStabilityZones/createStabilityZonesSlice';
import {
  goToResults as goToEquationResults,
} from '../createEquationBasedAnalysis/createEquationBasedAnalysisSlice';
import { ZonesOpsWorkflow } from './helpers/constants/workflows';
import { setWorkflow as setCloneWorkflow } from '../cloneZonesMap/cloneZonesMapSlice';

interface ZonesOpsState {
  stepIndex: number;
  workflow: ZonesOpsWorkflow,
  selectedField: {
    farmUuid: string;
    fieldUuid: string;
  };
}

const initialState: ZonesOpsState = {
  stepIndex: 0,
  workflow: ZonesOpsWorkflow.createSingleLayerAnalysis,
  selectedField: {
    farmUuid: '',
    fieldUuid: '',
  },
};

const zonesOpsSlice = createSlice({
  name: 'zonesOps',
  initialState,
  reducers: {
    preselectWorkflow(state, action: PayloadAction<{
      workflow: ZonesOpsWorkflow,
      fieldUuid: string,
      farmUuid: string,
    }>) {
      const {
        workflow,
        fieldUuid,
        farmUuid,
      } = action.payload;

      state.workflow = workflow || state.workflow;

      if (fieldUuid && farmUuid) {
        state.selectedField = {
          fieldUuid,
          farmUuid,
        };
      }
    },
    setSelectedField(state, action: PayloadAction<{
      uuid: string,
      farmUuid: string,
    }>) {
      state.selectedField = {
        fieldUuid: action.payload.uuid,
        farmUuid: action.payload.farmUuid,
      };
    },
    changeWorkflow(state, action: PayloadAction<ZonesOpsWorkflow>) {
      state.workflow = action.payload;
    },
    goToNextStep(state) {
      state.stepIndex += 1;
    },
    goToPrevStep(state) {
      state.stepIndex -= 1;
    },
    reset() {
      return initialState;
    },
    resetToSelectedField(state) {
      return {
        ...initialState,
        selectedField: state.selectedField,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setCloneWorkflow, (state) => {
        state.stepIndex += 1;
      })
      .addCase(goToEquationResults, (state) => {
        state.stepIndex += 1;
      })
      .addMatcher(
        ({ type }) => {
          return type === goBackFromMultiLayerSettings.type
          || type === goBackFromSingleLayerDataLayers.type;
        },
        (state) => {
          if (state.stepIndex - 1 === 0) {
            state.stepIndex = 0;
          } else {
            state.stepIndex -= 1;
          }
        },
      )
      .addMatcher(
        ({ type }) => {
          return type === goToCrossLayerSettings.type
          || type === goToStabilitySettings.type
          || type === goToFieldPotentialSettings.type
          || type === goToSingleLayerSettings.type
          || type === goToMultiLayerSettings.type;
        },
        (state) => {
          state.stepIndex += 1;
        },
      );
  },
});

export const {
  preselectWorkflow,
  setSelectedField,
  changeWorkflow,
  goToNextStep,
  goToPrevStep,
  reset,
  resetToSelectedField,
} = zonesOpsSlice.actions;

export default zonesOpsSlice.reducer;
