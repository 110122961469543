import React from 'react';
import { Link } from 'react-router-dom';

import AuthLogo from '../../../../../../public/assets/auth-logo.svg';

import './index.scss';

const AuthenticationHeader = () => {
  return (
    <div className="authentication-header">
      <Link to="/">
        <AuthLogo />
      </Link>
    </div>
  );
};

export default AuthenticationHeader;
