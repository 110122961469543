import React from 'react';
import { useSelector } from 'react-redux';

import { selectStep } from '../../create3dSelectors';
import config from './config';

const StepperCreate3d = () => {
  const step = useSelector(selectStep);
  const Component = config[step];

  return (<Component />);
};

export default StepperCreate3d;
