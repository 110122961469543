import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Stepper from '../../../../../../../components/Stepper';
import BackdropLoading from '../../../../../../../components/Loading/BackdropLoading';
import Panel from '../../../Panel/CreateFormulaAdjustment';
import { goToPrevStep } from '../../../../../zonesOps/zonesOpsSlice';
import {
  selectDataVariables,
  selectChanged,
} from '../../../../createEquationBasedAnalysisSelectors';
import {
  selectCurrentEquation,
  selectVerifyEquation,
} from '../../../../../../equations/equationsSelectors';
import { selectIsAllAssetsLoaded } from '../../../../../../field/fieldSelectors';
import useDidMount from '../../../../../../../hooks/useDidMount';
import { selectSelectedField } from '../../../../../zonesOps/zonesOpsSelectors';
import { fetchAllAssets } from '../../../../../../field/fieldSlice';
import { isVariableDataSelected } from '../../../../helpers/functions/variables';
import { goToResults } from '../../../../createEquationBasedAnalysisSlice';

const StepperCreateFormulaAdjustment = ({
  steps,
  stepIndex,
  step,
}) => {
  const dispatch = useDispatch();
  const {
    equationAsText: equationFormula,
    equationResultVariable: resultVariable,
  } = useSelector(selectCurrentEquation);
  const {
    errorMessage: verificationErrorMessage,
    inProgress: verificationInProgress,
  } = useSelector(selectVerifyEquation);
  const isLoaded = useSelector(selectIsAllAssetsLoaded);
  const dataVariables = useSelector(selectDataVariables);
  const changed = useSelector(selectChanged);
  const {
    farmUuid,
    fieldUuid,
  } = useSelector(selectSelectedField);

  const isVariablesDataSelected = dataVariables.every((dataVariable) => {
    return isVariableDataSelected(dataVariable);
  });

  const nextDisabled = !equationFormula
    || !isLoaded
    || !resultVariable
    || verificationInProgress
    || !!verificationErrorMessage
    || !isVariablesDataSelected;

  useDidMount(() => {
    dispatch(fetchAllAssets({
      farmUuid,
      fieldUuid,
    }));
  });

  const onClickBack = () => {
    dispatch(goToPrevStep());
  };

  const onClickNext = () => {
    dispatch(goToResults({
      changed,
    }));
  };

  return (
    <Stepper
      activeStep={stepIndex}
      steps={steps}
      nextDisabled={nextDisabled}
      onClickNext={onClickNext}
      onClickBack={onClickBack}
    >
      {isLoaded ? <Panel key="panel" step={step} /> : <BackdropLoading key="loader" />}
    </Stepper>
  );
};

export default StepperCreateFormulaAdjustment;
