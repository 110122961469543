import React from 'react';
import Typography from '@material-ui/core/Typography';

import './index.scss';

const TwoSidePanel = ({
  left,
  right,
}) => {
  return (
    <div className="two-side-panel">
      <div className="two-side-panel__panel">
        <Typography
          variant="h5"
          className="two-side-panel__panel-title"
        >
          Custom methods
        </Typography>
        {left}
      </div>
      <div className="two-side-panel__divider">or</div>
      <div className="two-side-panel__panel">
        <Typography
          variant="h5"
          className="two-side-panel__panel-title"
        >
          GeoPard account
        </Typography>
        {right}
      </div>
    </div>
  );
};

export default TwoSidePanel;
