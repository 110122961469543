import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';
import CircularProgress from '@material-ui/core/CircularProgress';

import { useAppSelector } from '../../../../../app/store/helpers/functions';
import { selectEquationMapsEntitiesList, selectFieldsEntitiesList } from '../../../../assets/assetsSelectors';
import { selectSelectedFieldsUuids } from '../../../batchAnalytics/batchAnalyticsSelectors';
import { selectCurrentEquation } from '../../../../equations/equationsSelectors';
import { LoadStatus } from '../../../../../helpers/constants/utils/loadStatus';
import { calculateWeightedAvg, calculateWeightedSum } from '../../helpers/functions/statistics';
import { prettifyNumber } from '../../../../../helpers/markup';
import { PRODUCT_UNIT_TO_I18N_LABEL } from '../../../../../helpers/constants/units/productUnit';
import { convertProductUnitToAbsoluteUnit } from '../../../../../helpers/functions/units/absoluteUnit';
import { ABSOLUTE_UNIT_TO_I18N_LABEL } from '../../../../../helpers/constants/units/absoluteUnit';
import OperationsIcon from '../../../../../components/Icons/operations';
import Tooltip from '../../../../../components/Tooltip';
import OpsLogTooltip from '../../components/OpsLogTooltip';
import { selectAreaUnit } from '../../../../user/userSelectors';
import { getI18nAreaUnit } from '../../../../../helpers';
import { selectEquationMapsDataByStatuses } from '../../createBatchEquationBasedAnalysisSelectors';

import './index.scss';

const DASH = '—';

export default function PreviewSummary() {
  const { t } = useTranslation();

  const selectedFieldsUuids = useAppSelector(selectSelectedFieldsUuids);
  const successEquationMapsData = useAppSelector((state) => {
    return selectEquationMapsDataByStatuses(state, [LoadStatus.success]);
  });
  const errorEquationMapsData = useAppSelector((state) => {
    return selectEquationMapsDataByStatuses(state, [LoadStatus.error]);
  });
  const inProgressEquationMapsData = useAppSelector((state) => {
    return selectEquationMapsDataByStatuses(state, [LoadStatus.loading]);
  });
  const successEquationMapsUuids = successEquationMapsData
    .map(({ uuid }) => uuid)
    .filter((uuid): uuid is string => !!uuid);
  const successEquationMapsEntities = useAppSelector((state) => {
    return selectEquationMapsEntitiesList(state, successEquationMapsUuids);
  });
  const { productUnit } = useAppSelector(selectCurrentEquation);
  const fieldsEntities = useAppSelector((state) => selectFieldsEntitiesList(state, selectedFieldsUuids));
  const areaUnit = useAppSelector(selectAreaUnit);

  const areaUnitLabel = t(getI18nAreaUnit(areaUnit));

  const fieldsAreaSum = useMemo(() => {
    return fieldsEntities.reduce((acc, field) => {
      return acc + (field.area || 0);
    }, 0);
  }, [fieldsEntities]);

  const weightedSum = useMemo(() => {
    return calculateWeightedSum(successEquationMapsEntities);
  }, [successEquationMapsEntities]);

  const weightedAvg = useMemo(() => {
    return calculateWeightedAvg(successEquationMapsEntities);
  }, [successEquationMapsEntities]);

  const productUnitLabel = useMemo(() => {
    if (!productUnit) {
      return '';
    }

    return t(PRODUCT_UNIT_TO_I18N_LABEL[productUnit]);
  }, [productUnit, t]);

  const absoluteUnitLabel = useMemo(() => {
    if (!productUnit) {
      return '';
    }

    const absoluteUnit = convertProductUnitToAbsoluteUnit(productUnit);

    return t(ABSOLUTE_UNIT_TO_I18N_LABEL[absoluteUnit]);
  }, [productUnit, t]);

  const generationInProgress = inProgressEquationMapsData.length !== 0;

  return (
    <div className="preview-summary">
      <div className="preview-summary__section">
        <Typography className="preview-summary__section-text">
          {t('batch-equation-based.steps.preview.summary.total-area')}:
        </Typography>
        <Typography className="preview-summary__section-text preview-summary__section-text_success">
          {prettifyNumber(fieldsAreaSum, 3)} {areaUnitLabel}
        </Typography>
      </div>
      <div className="preview-summary__section">
        <Typography className="preview-summary__section-text">
          {t('general.controls.map-legend.statistics.avg')}:
        </Typography>
        {
          successEquationMapsData.length !== 0
            ? (
              <Typography className="preview-summary__section-text preview-summary__section-text_success">
                {
                  weightedAvg != null
                    ? `${prettifyNumber(weightedAvg)} ${productUnitLabel}`
                    : t('batch-equation-based.steps.preview.summary.not-applicable')
                }
              </Typography>
            )
            : (generationInProgress && <CircularProgress size={20} />) || DASH
        }
      </div>
      <div className="preview-summary__section">
        <Typography className="preview-summary__section-text">
          {t('general.controls.map-legend.statistics.sum')}:
        </Typography>
        {
          successEquationMapsData.length !== 0
            ? (
              <Typography className="preview-summary__section-text preview-summary__section-text_success">
                {
                  weightedSum != null
                    ? `${prettifyNumber(weightedSum)} ${absoluteUnitLabel}`
                    : t('batch-equation-based.steps.preview.summary.not-applicable')
                }
              </Typography>
            )
            : (generationInProgress && <CircularProgress size={20} />) || DASH
        }
      </div>
      <div className="preview-summary__section">
        <Tooltip
          classes={{
            tooltip: 'preview-summary__tooltip',
            label: 'preview-summary__tooltip-label',
          }}
          tooltip={<OpsLogTooltip />}
          interactive
        >
          <OperationsIcon className="preview-summary__section-icon" />
        </Tooltip>
        <Typography className="preview-summary__section-text preview-summary__section-text_with-separator">
          <span>{t('batch-equation-based.steps.preview.summary.equation-maps')}: </span>
          <span className="preview-summary__section-text_neutral">
            {selectedFieldsUuids.length}
          </span>
        </Typography>
        <Typography className="preview-summary__section-text preview-summary__section-text_with-separator">
          {t('operations.statuses.in-progress')}: {inProgressEquationMapsData.length}
        </Typography>
        <Typography className="preview-summary__section-text preview-summary__section-text_error preview-summary__section-text_with-separator">
          {t('operations.statuses.error')}: {errorEquationMapsData.length}
        </Typography>
        <Typography className="preview-summary__section-text preview-summary__section-text_success">
          {t('operations.statuses.success')}: {successEquationMapsData.length}
        </Typography>
      </div>
    </div>
  );
}
