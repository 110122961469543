import React from 'react';

import { useAppDispatch, useAppSelector } from '../../../../../app/store/helpers/functions';
import Select from '../../../../../components/Select';
import FieldsListFilter from '../../../../fieldsList/containers/FieldsListFilter';
import FieldsListTable from '../FieldsListTable';
import { selectWorkflow } from '../../batchAnalyticsSelectors';
import { BatchOperationWorkflow } from '../../helpers/constants/workflows';
import { changeWorkflow } from '../../batchAnalyticsSlice';
import { getWorkflowOptions } from '../../helpers/functions/workflows';
import { sendFeatureUsage } from '../../../../../helpers/analytics';
import useResetWorkflow from '../../hooks/resetWorkflow';

import './index.scss';

export default function SelectFieldsWorkflowPanel() {
  const dispatch = useAppDispatch();
  const workflow = useAppSelector(selectWorkflow);
  const workflowOptions = getWorkflowOptions();
  const { resetWorkflow } = useResetWorkflow();

  const handleWorkflowChange = (value: BatchOperationWorkflow | null) => {
    if (!value) {
      return;
    }

    resetWorkflow();
    dispatch(changeWorkflow(value));
  };

  const handleWorkflowSelectOpen = () => {
    sendFeatureUsage('batch-analytics', 'select-workflow');
  };

  return (
    <div className="select-fields-workflow-panel">
      <Select
        id="analysis-type"
        value={workflow}
        options={workflowOptions}
        classes={{
          root: 'select-fields-workflow-panel__analysis-select',
        }}
        onChange={handleWorkflowChange}
        onOpen={handleWorkflowSelectOpen}
      />
      <FieldsListFilter />
      <FieldsListTable />
    </div>
  );
}
