import { API, graphqlOperation } from '@aws-amplify/api';

import getCreditsUsageDataQuery from './graphql/queries/getUsageData.gql';

export const getCreditsUsageData = async (filter) => {
  return API.graphql(graphqlOperation(getCreditsUsageDataQuery, {
    filter,
  }))
    .then(({ data }) => {
      const {
        balance: {
          creditsAmount,
        },
        actionsData: {
          actions,
          totalSum,
          totalCount,
        },
      } = data.getUserData;

      return {
        creditsAmount,
        actions,
        totalCount,
        totalSum,
      };
    });
};
