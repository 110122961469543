import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from '../../../../app/store/helpers/functions';
import { saveNewVectorAnalysisMap } from '../../../createAnalysis/createAnalysisSlice';
import { selectColors, selectFeature } from '../drawManuallyWorkflowSelectors';
import { prepareFeatures } from '../../zonesMap/helpers/functions/geojson';
import { fetchFieldVamapPins } from '../../../field/fieldSlice';
import { selectSelectedField } from '../../zonesOps/zonesOpsSelectors';
import { openPopup } from '../../popups/popupsSlice';
import { isTimeoutError } from '../../zonesMap/helpers/functions/api';
import { errorNotify, warningNotify } from '../../../notifications/helpers/functions/notify';
import { CustomError } from '../../../../helpers/functions/utils/errorHandling';

export default function useSaveNewVamap() {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const feature = useAppSelector(selectFeature);
  const colors = useAppSelector(selectColors);
  const { fieldUuid, farmUuid } = useAppSelector(selectSelectedField);

  return async (name: string) => {
    const zonesMapGeojson = {
      ...feature,
      features: prepareFeatures(feature.features),
    };

    try {
      const vectorAnalysisMapUuid = await dispatch(saveNewVectorAnalysisMap({
        name,
        zonesMapGeojson,
        hexColorCodes: colors,
      })).unwrap();

      dispatch(fetchFieldVamapPins({
        farmUuid,
        fieldUuid,
        uuid: vectorAnalysisMapUuid,
      }));

      dispatch(openPopup({
        type: 'save-zones-map',
        name,
        vamapUuid: vectorAnalysisMapUuid,
      }));
    } catch (error) {
      if (isTimeoutError(error)) {
        warningNotify({
          key: fieldUuid,
          message: t('zones-map.notifications.save-zones-map-timeout'),
        });
      } else {
        errorNotify({
          error: new CustomError('[Create Analysis] Unable to save new vector analysis map', {
            cause: error,
          }),
          dispatch,
        });
      }
    }
  };
}
