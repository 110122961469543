import React, { ChangeEvent, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Auth } from '@aws-amplify/auth';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import EmailSharpIcon from '@material-ui/icons/EmailSharp';

import Button from '../../../../../../../../components/Button';
import GoogleLogoIcon from '../../../../../components/Icons/google-logo.svg';
import JDLogoIcon from '../../../../../components/Icons/jd-logo.svg';
import { errorNotify } from '../../../../../../../notifications/helpers/functions/notify';
import { CustomError } from '../../../../../../../../helpers/functions/utils/errorHandling';
import TextField from '../../../../../../../../components/TextField';
import { Provider } from '../../../../../helpers/constants/authentication';
import { matchEmailToProvider } from '../../../../../helpers/functions/authentication';
import { HELPER_TEXTS } from '../../../../../helpers/constants/helperTexts';

import '../index.scss';
import './index.scss';

export default function FederatedLogInPanel() {
  const dispatch = useDispatch();
  const [emailState, setEmailState] = useState({
    value: '',
    valid: true,
    errorMessage: '',
  });

  const handleEmailChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEmailState({
      ...emailState,
      value: e.target.value,
    });
  };

  const handleEmailProceed = () => {
    const provider = matchEmailToProvider(emailState.value);

    if (provider) {
      setEmailState({
        ...emailState,
        valid: true,
        errorMessage: '',
      });

      Auth.federatedSignIn({ customProvider: provider })
        .catch((e) => {
          const message = `Unable to log in with ${provider}`;

          errorNotify({
            error: new CustomError(message, {
              cause: e,
            }),
            message,
            dispatch,
          });
        });
    } else {
      setEmailState({
        ...emailState,
        valid: false,
        errorMessage: HELPER_TEXTS.invalidEmail,
      });
    }
  };

  const onSocialLoginClick = (provider: Provider) => {
    Auth.federatedSignIn({ customProvider: provider })
      .catch((e) => {
        const message = `Unable to log in with ${provider}`;

        errorNotify({
          error: new CustomError(message, {
            cause: e,
          }),
          message,
          dispatch,
        });
      });
  };

  return (
    <div className="federated-log-in-panel">
      <div className="federated-log-in-panel__row">
        <Typography
          classes={{
            root: 'federated-log-in-panel__tip',
          }}
        >
          Continue with your MyJohnDeere account
        </Typography>
        <div>
          <Button
            classes={{
              root: 'federated-log-in-panel__button_jd',
            }}
            disableRipple
            variant="outlined"
            onClick={() => onSocialLoginClick(Provider.johnDeere)}
          >
            <JDLogoIcon />
          </Button>
        </div>
      </div>
      <div className="federated-log-in-panel__row">
        <Typography
          classes={{
            root: 'federated-log-in-panel__tip',
          }}
        >
          Continue with Microsoft Active Directory
        </Typography>
        <div className="federated-log-in-panel__button-input">
          <TextField
            value={emailState.value}
            placeholder="Enter email"
            error={!emailState.valid}
            helperText={emailState.errorMessage}
            InputProps={{
              classes: {
                error: 'textfield__input_error',
              },
              startAdornment: <EmailSharpIcon
                fontSize="small"
                className="authentication-panel__input-icon authentication-panel__input-icon_start"
                              />,
            }}
            onChange={handleEmailChange}
          />
          <Button
            variant="contained"
            color="primary"
            endIcon={<ArrowForwardIcon />}
            onClick={handleEmailProceed}
          >
            Next
          </Button>
        </div>
      </div>
      <div className="federated-log-in-panel__row">
        <Typography
          classes={{
            root: 'federated-log-in-panel__tip',
          }}
        >
          Continue with Google account
        </Typography>
        <Button
          className="federated-log-in-panel__button_google"
          variant="outlined"
          startIcon={<GoogleLogoIcon />}
          onClick={() => onSocialLoginClick(Provider.google)}
        >
          Continue with Google
        </Button>
        <Typography
          classes={{
            root: 'federated-log-in-panel__legal',
          }}
        >
          We won&apos;t post to any of your accounts without asking first
        </Typography>
      </div>
    </div>
  );
}
