import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import DeleteSharpIcon from '@material-ui/icons/DeleteSharp';

import AddLabelsIcon from '../../components/Icons/addLabels';
import RemoveLabelsIcon from '../../components/Icons/removeLabels';
import ExportIcon from '../../../../../components/Icons/export';
import TablePanel from '../../../../../components/TablePanel';
import Button from '../../../../../components/Button';
import { toggleEditMode } from '../../fieldProfilerSlice';
import {
  addFieldsLabels,
  deleteFieldsLabels,
  deleteFields,
} from '../../../../fieldsList/fieldsListSlice';
import { openPopup } from '../../../popups/popupsSlice';
import { selectFieldsMap } from '../../../../fieldsList/fieldsListSelectors';
import { selectSelectedFields } from '../../fieldProfilerSelectors';
import { exportGeoPardFieldsToJohnDeereAsFiles } from '../../../jdExport/jdExportSlice';
import { selectProfileIsAuthorizedAndReady } from '../../../../jdProfile/jdProfileSelectors';
import { isJohnDeereExportWorkflowEnabled } from '../../../../../helpers/functions/utils/appConfig';
import { POPUPS } from '../../../popups/helpers/constants/popups';

import './index.scss';

const FieldProfilerTablePanel = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const fieldsMap = useSelector(selectFieldsMap);
  const selectedFieldsUuids = useSelector(selectSelectedFields);
  const selectedFields = selectedFieldsUuids.map((uuid) => {
    return fieldsMap[uuid];
  });
  const jdProfileReady = useSelector(selectProfileIsAuthorizedAndReady);
  const isExportToJdEnabled = isJohnDeereExportWorkflowEnabled() && jdProfileReady;

  const onStopEditingClick = () => {
    dispatch(toggleEditMode());
  };

  const onDeleteFieldsClick = () => {
    dispatch(openPopup({
      type: 'delete-fields',
      fieldNames: selectedFields.map((field) => {
        return field.name;
      }),
      onConfirm: () => {
        const transformedFields = selectedFields.map(({ uuid, farmUuid }) => {
          return { uuid, farmUuid };
        });
        dispatch(deleteFields(transformedFields));
      },
    }));
  };

  const onAddLabelsClick = () => {
    dispatch(openPopup({
      type: 'add-labels',
      onConfirm: (labels) => {
        const preparedLabels = labels.map(({ prefix, value }) => ({
          name: prefix,
          value,
        }));
        const fieldLabels = selectedFields.map(({ uuid, farmUuid }) => ({
          uuid,
          farmUuid,
          labels: preparedLabels,
        }));

        dispatch(addFieldsLabels(fieldLabels));
      },
      onLinkClick: () => {
        dispatch(openPopup({
          type: 'create-label',
        }));
      },
    }));
  };

  const onRemoveLabelsClick = () => {
    const selectedLabels = selectedFields.map(({ labels }) => labels);
    dispatch(openPopup({
      type: 'remove-labels',
      labels: selectedLabels.flat(),
      onConfirm: (labels) => {
        const fieldLabels = selectedFields.map(({ uuid, farmUuid }) => ({
          uuid,
          farmUuid,
          labels,
        }));
        dispatch(deleteFieldsLabels(fieldLabels));
      },
    }));
  };

  const onExportToJD = () => {
    dispatch(openPopup({
      type: POPUPS.exportFieldsToJD,
      fieldNames: selectedFields.map((field) => field.name),
      onConfirm: () => {
        const fieldUuids = selectedFields.map(({ uuid }) => uuid);
        dispatch(exportGeoPardFieldsToJohnDeereAsFiles(fieldUuids));
      },
    }));
  };

  return (
    <TablePanel
      elementsLeft={[
        <Button
          key="add-labels"
          color="primary"
          classes={{ root: 'fields-edit-panel__button' }}
          startIcon={<AddLabelsIcon />}
          onClick={onAddLabelsClick}
          disabled={selectedFields.length === 0}
        >
          {t('field-profiler.fields-list.table-panel.add-labels')}
        </Button>,
        <Button
          key="remove-labels"
          color="primary"
          classes={{ root: 'fields-edit-panel__button' }}
          startIcon={<RemoveLabelsIcon />}
          onClick={onRemoveLabelsClick}
          disabled={selectedFields.length === 0}
        >
          {t('field-profiler.fields-list.table-panel.remove-labels')}
        </Button>,
        <Button
          key="export-to-JD"
          color="primary"
          classes={{ root: 'fields-edit-panel__button' }}
          startIcon={<ExportIcon />}
          onClick={onExportToJD}
          disabled={selectedFields.length === 0 || !isExportToJdEnabled}
        >
          {t('field-profiler.fields-list.table-panel.export-to-JD')}
        </Button>,
        <Button
          key="delete-fields"
          color="primary"
          classes={{ root: 'fields-edit-panel__button' }}
          startIcon={<DeleteSharpIcon />}
          onClick={onDeleteFieldsClick}
          disabled={selectedFields.length === 0}
        >
          {t('field-profiler.fields-list.table-panel.delete-fields')}
        </Button>,
      ]}
      elementsRight={[
        <Button
          key="stop-editing"
          variant="contained"
          color="primary"
          onClick={onStopEditingClick}
        >
          {t('field-profiler.fields-list.table-panel.finish-editing')}
        </Button>,
      ]}
    />
  );
};

export default FieldProfilerTablePanel;
