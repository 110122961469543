import React from 'react';
import clsx from 'clsx';
import Backdrop, { BackdropProps } from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import './index.scss';

const BackdropLoading = ({
  classes,
  inlined,
  size,
}: {
  classes?: BackdropProps['classes'],
  inlined?: boolean,
  size?: number | string,
}) => {
  return (
    <Backdrop
      open
      classes={classes}
      className={clsx('backdrop-loading', {
        'backdrop-loading_inlined': inlined,
      })}
    >
      <CircularProgress data-test="backdrop-loading" size={size}/>
    </Backdrop>
  );
};

export default BackdropLoading;
