import { RootState } from '../../app/store/helpers/types';

export const selectSatelliteImages = ({ subscription }: RootState) => subscription.satelliteImages;

export const selectVectorAnalysisMaps = ({ subscription }: RootState) => subscription.vectorAnalysisMaps;

export const selectSoilDatasets = ({ subscription }: RootState) => subscription.soilDatasets;

export const selectYieldDatasets = ({ subscription }: RootState) => subscription.yieldDatasets;

export const selectAsAppliedDatasets = ({ subscription }: RootState) => subscription.asAppliedDatasets;

export const selectTopographyMaps = ({ subscription }: RootState) => subscription.topographyMaps;
