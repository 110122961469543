import parse from 'date-fns/parse';
import addMonths from 'date-fns/addMonths';

import { ORDER_STATUSES } from '../constants/order';
import { PaymentMethod } from '../constants/paymentMethods';

export const getEndDate = (order) => {
  let result = null;

  if (order?.packageInfo?.endDate) {
    result = parse(order.packageInfo.endDate, 'yyyy-MM-dd', new Date());
  } else if (order?.packageInfo?.startDate) {
    result = addMonths(
      parse(order.packageInfo.startDate, 'yyyy-MM-dd', new Date()),
      order.billingPeriodInMonths,
    );
  } else if (order?.orderDate) {
    result = addMonths(
      parse(order.orderDate, 'yyyy-MM-dd', new Date()),
      order.billingPeriodInMonths,
    );
  }

  return result;
};

export const getLastPaid = (orders = []) => {
  return orders.find((order) => {
    return order.status === ORDER_STATUSES.INVOICE_PAID;
  });
};

export const isEditable = (orderStatus) => {
  return orderStatus === 'DRAFT' || orderStatus === 'PACKAGE_ORDERED';
};

export const hasStripePayment = (orders) => {
  return orders.some((order) => order.paymentMethod === PaymentMethod.stripe);
};
