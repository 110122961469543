export const isArraysEqual = <T>(
  arr1: T[],
  arr2: T[],
  comparator: (a: T, b: T) => boolean,
): boolean => {
  if (arr1.length !== arr2.length) {
    return false;
  }

  const sortedArr1 = [...arr1].sort();
  const sortedArr2 = [...arr2].sort();

  return sortedArr1.every((item1, index) => comparator(item1, sortedArr2[index]));
};
