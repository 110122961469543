import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import AuthenticationRedirector from '../routes/redirectors/Authentication';
import PricingRedirector from '../routes/redirectors/Pricing';
import BackdropLoading from '../components/Loading/BackdropLoading';
import Loading from '../components/Loading';
import ToolsPanel from '../features/ui/createField/containers/ToolsPanel';
import {
  getRootLink,
  PAGES_ROOTS,
} from '../helpers/navigation';
import { getDocumentTitle } from '../helpers';
import BaseTemplate from './BaseTemplate.jsx';
import { useGetFarmsQuery } from '../features/farms/farmsAPI';
import useDidMount from '../hooks/useDidMount';
import { reset } from '../features/ui/createField/createFieldSlice';

const Map = React.lazy(() => import('../features/ui/createField/containers/Map'));

const CreateField = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    isFetching: isFarmsFetching,
  } = useGetFarmsQuery();

  useDidMount(() => {
    document.title = getDocumentTitle(t('field-profiler.tools-panel.create-field'));

    return () => {
      dispatch(reset());
    };
  });

  return (
    <AuthenticationRedirector>
      <PricingRedirector>
        <BaseTemplate
          route={PAGES_ROOTS.createField}
          headerProps={{
            text: t('create-field.title'),
            backUrl: getRootLink(PAGES_ROOTS.fields),
          }}
        >
          <ToolsPanel key="tools-panel" />
          <Suspense
            key="map"
            fallback={<Loading />}
          >
            <Map />
          </Suspense>
          { isFarmsFetching && <BackdropLoading key="loading" />}
        </BaseTemplate>
      </PricingRedirector>
    </AuthenticationRedirector>
  );
};

export default CreateField;
