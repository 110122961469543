import React, { Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import BackdropLoading from '../../../../../../../components/Loading/BackdropLoading';
import Loading from '../../../../../../../components/Loading';
import Stepper from '../../../../../../../components/Stepper';
import ToolsPanel from '../../../../../../../components/OpsPanel/Create3D';
import {
  setStep,
  save,
  updateSettings,
} from '../../../../create3dSlice';
import {
  selectLoading,
  selectSettings,
} from '../../../../create3dSelectors';
import Panel from '../../../Panel/Preview';
import { getSteps } from '../../../../helpers/functions/steps';

const View = React.lazy(() => import('../../../3dView'));

const Preview = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const loading = useSelector(selectLoading);
  const { name } = useSelector(selectSettings);
  const steps = getSteps();

  const onClickBack = () => {
    dispatch(setStep('zonesMap'));
  };

  const onClickNext = () => {
    dispatch(save());
  };

  const onPropChange = (prop, value) => {
    dispatch(updateSettings({
      prop,
      value,
    }));
  };

  return (
    <Stepper
      showInactive
      classes={{
        root: 'preview-stepper',
      }}
      nextLabel={t('create-3d.stepper.save-and-finish')}
      activeStep={3}
      steps={steps}
      nextDisabled={loading || !name}
      onClickBack={onClickBack}
      onClickNext={onClickNext}
    >
      <ToolsPanel
        key="tools-panel"
        name={name}
        onPropChange={onPropChange}
      />
      <Panel key="panel" />
      <Suspense
        key="map"
        fallback={<Loading />}
      >
        <View />
      </Suspense>
      {loading && <BackdropLoading key="loader"/>}
    </Stepper>
  );
};

export default Preview;
