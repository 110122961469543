import React, { useState } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import Typography from '@material-ui/core/Typography';

import Button from '../../../../../../../components/Button';
import { getAuthLink } from '../../../../../../../helpers/navigation';
import { View } from '../../../../helpers/constants/authentication';
import { getContactsLink } from '../../../../../../../helpers/functions/utils/appConfig';
import { prepareEmailAttribute } from '../../../../helpers/functions/authentication';

import '../common/index.scss';
import './index.scss';

const ForgotPasswordSentPanel = () => {
  const { state: locationState } = useLocation();
  const [resendClicked, setResendClicked] = useState();

  const onActionClick = () => {
    const preparedEmail = prepareEmailAttribute(locationState.email);

    setResendClicked(true);
    Auth.forgotPassword(preparedEmail);
  };

  return (
    <div className="authentication-panel recover-password-sent-panel">
      <Typography
        classes={{
          root: 'authentication-panel__header recover-password-sent-panel__header',
        }}
        variant="h1"
      >
        Password Recovery
      </Typography>
      <Typography
        classes={{
          root: 'authentication-panel__tip',
        }}
      >
        We&apos;ve sent instructions to {locationState.email}.
        If you didn&apos;t get the email, ask to resend it.
      </Typography>
      <Typography
        classes={{
          root: 'authentication-panel__tip authentication-panel__tip_extra',
        }}
      >
        Haven&apos;t received our email? It might be in your spam/junk folder.
      </Typography>
      <Button
        className="recover-password-sent-panel__button"
        variant="contained"
        color="primary"
        component={Link}
        to={getAuthLink(View.logIn)}
      >
        Back to Log in
      </Button>
      {
         resendClicked
         && (
           <Typography
             classes={{
               root: 'authentication-panel__tip authentication-panel__tip_extra',
             }}
           >
             If you still haven&apos;t received the email, please <a
               className="sign-up-sent-panel__link link"
               target="_blank"
               href={getContactsLink()}
                                                                  >
               contact us
             </a>.
           </Typography>
         )
       }
      <Button
        className="authentication-panel__link sign-up-sent-panel__action-link link"
        onClick={onActionClick}
      >
        Resend the recovery instructions again
      </Button>
    </div>
  );
};

export default ForgotPasswordSentPanel;
