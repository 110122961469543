import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import BackdropLoading from '../../../components/Loading/BackdropLoading';
import { selectApplicationStatus } from '../../../features/ui/applicationShell/applicationShellSelectors';
import { APPLICATION_STATUSES } from '../../../features/ui/applicationShell/helpers/constants/application';
import { selectIsLoaded } from '../../../features/user/userSelectors';
import { getNewUserKey } from '../../../helpers/functions/utils/appConfig';
import {
  getRootLink,
  PAGES_ROOTS,
} from '../../../helpers/navigation';

const PricingRedirector = ({
  isLoaded,
  isNew,
  isRestricted,
  children,
}) => {
  if (!isLoaded) {
    return (
      <BackdropLoading />
    );
  }

  if (isNew) {
    return (
      <Redirect to={getRootLink(PAGES_ROOTS.planSubscription)} />
    );
  }

  if (isRestricted) {
    return (
      <Redirect to={getRootLink(PAGES_ROOTS.restrictedAccess)} />
    );
  }

  return children;
};

const mapStateToProps = (state, ownProps) => {
  const applicationStatus = selectApplicationStatus(state);
  let isNew = false;

  if (applicationStatus === APPLICATION_STATUSES.BLOCKED) {
    isNew = true;
  }

  if (localStorage.getItem(getNewUserKey())) {
    localStorage.removeItem(getNewUserKey());
    isNew = true;
  }

  return {
    ...ownProps,
    isLoaded: selectIsLoaded(state),
    isNew,
    isRestricted: applicationStatus < APPLICATION_STATUSES.AVAILABLE,
  };
};

export default connect(mapStateToProps)(PricingRedirector);
