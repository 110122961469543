import sub from 'date-fns/sub';
import startOfMonth from 'date-fns/startOfMonth';
import format from 'date-fns/format';

import { ACTIONS_PAGE_SIZE } from '../constants/action';
import { PERIODS_MAP } from '../constants/periods';

const transformFilterDate = (date) => {
  return format(date, 'yyyy-MM-dd');
};

export const prepareFilter = ({
  period,
  operation,
  dateFrom,
  dateTill,
  pageNumber,
  pageSize = ACTIONS_PAGE_SIZE,
  organizations,
  users,
}) => {
  let dates;

  if (period === PERIODS_MAP.FOR_PERIOD) {
    dates = {
      dateFrom: transformFilterDate(dateFrom),
      dateTo: transformFilterDate(dateTill),
    };
  } else if (period === PERIODS_MAP.TODAY) {
    dates = {
      dateFrom: transformFilterDate(new Date()),
      dateTo: transformFilterDate(new Date()),
    };
  } else if (period === PERIODS_MAP.LAST_7_DAYS) {
    dates = {
      dateFrom: transformFilterDate(sub(new Date(), { days: 7 })),
      dateTo: transformFilterDate(new Date()),
    };
  } else if (period === PERIODS_MAP.CURRENT_MONTH) {
    dates = {
      dateFrom: transformFilterDate(startOfMonth(new Date())),
      dateTo: transformFilterDate(new Date()),
    };
  } else if (period === PERIODS_MAP.THREE_MONTHS) {
    dates = {
      dateFrom: transformFilterDate(sub(new Date(), { months: 3 })),
      dateTo: transformFilterDate(new Date()),
    };
  }

  return {
    ...dates,
    ...(operation
      ? {
        operation: operation.map((op) => {
          return op.value;
        })
          .reduce((acc, curr) => {
            acc.push(...curr);

            return acc;
          }, []),
      }
      : null),
    pageNumber,
    pageSize,
    ...(organizations.length > 0 ? { organizationUuids: organizations.map(({ uuid }) => uuid) } : null),
    ...(users.length > 0 ? { userUuids: users.map(({ uuid }) => uuid) } : null),
  };
};
