import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { errorNotify } from '../../notifications/helpers/functions/notify';
import { CustomError } from '../../../helpers/functions/utils/errorHandling';
import {
  selectEmail,
  selectOrganizations,
  selectUuid,
} from '../../user/userSelectors';
import { getLoadMoreFilter, getUpdatedFilter } from './helpers/functions/redux';
import { transformAction } from './helpers/functions/action';
import { getCreditsUsageData } from './userCreditsUsageAPI';
import { prepareFilter } from './helpers/functions/filter';
import { selectActionsData, selectFilter } from './userCreditsUsageSelectors';
import { ACTIONS_PAGE_SIZE, MAX_ACTIONS_PAGE_SIZE } from './helpers/constants/action';

const initialState = {
  filter: {
    period: 'today',
    operation: null,
    dateFrom: null,
    dateTill: null,
    pageNumber: 0,
    organizations: [],
    users: [],
  },
  actionsData: {
    actions: [],
    lastRequestedIndex: -1,
    totalSum: 0,
    totalCurrency: 0,
    totalCount: 0,
  },
  isLoaded: true,
  balance: {
    creditsAmount: null,
  },
};

const requestCreditsUsageData = (filter) => (_dispatch, getState) => {
  const state = getState();
  const currentUser = {
    uuid: selectUuid(state),
    email: selectEmail(state),
  };
  const organizations = selectOrganizations(state);

  return getCreditsUsageData(prepareFilter(filter))
    .then(({
      creditsAmount,
      actions,
      totalCount,
      totalSum,
    }) => {
      return {
        actions: transformAction(actions, organizations, currentUser),
        creditsAmount,
        totalSum,
        totalCount,
      };
    });
};

export const updateFilterCreditsUsage = createAsyncThunk(
  'userCreditsUsage/updateFilterCreditsUsage',
  async (filter, { dispatch }) => {
    let result = null;

    try {
      result = await dispatch(requestCreditsUsageData(getUpdatedFilter(filter)));
    } catch (error) {
      errorNotify({
        error: new CustomError('Unable to request credits usage data for updated filter.', {
          cause: error,
        }),
        dispatch,
      });
    }

    return result;
  },
);

export const loadMoreActionsCreditsUsage = createAsyncThunk(
  'userCreditsUsage/loadMoreActionsCreditsUsage',
  async (_, { dispatch, getState }) => {
    const filter = selectFilter(getState());
    let result = null;

    try {
      const {
        actions,
        totalCount,
      } = await dispatch(requestCreditsUsageData(filter));

      result = {
        actions,
        totalCount,
      };
    } catch (error) {
      errorNotify({
        error: new CustomError('Unable to load mode actions', {
          cause: error,
        }),
        dispatch,
      });
    }

    return result;
  },
);

export const fetchCreditsUsage = createAsyncThunk(
  'userCreditsUsage/fetchCreditsUsage',
  async (_, { dispatch, getState }) => {
    const filter = selectFilter(getState());
    let result = null;

    try {
      result = await dispatch(requestCreditsUsageData(filter));
    } catch (error) {
      errorNotify({
        error: new CustomError('Unable to fetch credits usage.', {
          cause: error,
        }),
        dispatch,
      });
    }

    return result;
  },
);

export const updateCreditsUsage = createAsyncThunk(
  'userCreditsUsage/updateCreditsUsage',
  async (_, { dispatch, getState }) => {
    const state = getState();
    const filter = selectFilter(state);
    const { lastRequestedIndex } = selectActionsData(state);
    const batchesAmount = Math.ceil(lastRequestedIndex / MAX_ACTIONS_PAGE_SIZE);
    const requests = [];

    for (let i = 0; i < batchesAmount; i++) {
      requests.push(
        dispatch(requestCreditsUsageData({
          ...filter,
          pageNumber: i,
          pageSize: MAX_ACTIONS_PAGE_SIZE,
        })),
      );
    }

    let result = null;

    if (requests.length === 0) {
      return result;
    }

    try {
      result = await Promise.all(requests)
        .then((responses) => {
          const allActions = responses.reduce((acc, { actions }) => {
            return [...acc, ...actions];
          }, []);

          return {
            actions: allActions,
            totalCurrency: responses[0].totalCurrency,
            creditsAmount: responses[0].creditsAmount,
            totalSum: responses[0].totalSum,
            totalCount: responses[0].totalCount,
          };
        });
    } catch (error) {
      errorNotify({
        error: new CustomError('Unable to update credits usage.', {
          cause: error,
        }),
        dispatch,
      });
    }

    return result;
  },
);

export const userCreditsUsageSlice = createSlice({
  name: 'userCreditsUsage',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateFilterCreditsUsage.pending, (state, action) => {
        state.filter = getUpdatedFilter(action.meta.arg);
        state.isLoaded = false;
        state.actionsData.lastRequestedIndex = -1;
      })
      .addCase(loadMoreActionsCreditsUsage.pending, (state) => {
        state.filter = getLoadMoreFilter(state.filter);
        state.actionsData.lastRequestedIndex = state.actionsData.actions.length + ACTIONS_PAGE_SIZE;
        state.isLoaded = false;
      })
      .addCase(loadMoreActionsCreditsUsage.fulfilled, (state, action) => {
        state.isLoaded = true;

        if (action.payload) {
          state.actionsData.totalCount = action.payload.totalCount;
          state.actionsData.actions.push(...action.payload.actions);
        }
      })
      .addCase(fetchCreditsUsage.pending, (state) => {
        state.isLoaded = false;
      })
      .addMatcher(
        ({ type }) => {
          return type === updateFilterCreditsUsage.fulfilled.type
            || type === fetchCreditsUsage.fulfilled.type
            || type === updateCreditsUsage.fulfilled.type;
        },
        (state, action) => {
          state.isLoaded = true;

          if (action.payload) {
            state.actionsData.actions = action.payload.actions;
            state.actionsData.totalSum = action.payload.totalSum;
            state.actionsData.totalCurrency = action.payload.totalCurrency;
            state.actionsData.totalCount = action.payload.totalCount;
            state.balance.creditsAmount = action.payload.creditsAmount;
          }
        },
      )
      .addMatcher(
        ({ type }) => {
          return type === updateFilterCreditsUsage.fulfilled.type
            || type === fetchCreditsUsage.fulfilled.type;
        },
        (state, action) => {
          state.actionsData.lastRequestedIndex = action.payload.actions.length;
        },
      );
  },
});

export const {
  reset,
} = userCreditsUsageSlice.actions;

export default userCreditsUsageSlice.reducer;
