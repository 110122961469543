import { createSelector } from '@reduxjs/toolkit';

import { getAuthenticationRequiredGroups } from '../../../helpers/functions/utils/appConfig';
import {
  selectCognitoGroups,
  selectCreditsAmount,
  selectLastPaidOrder,
} from '../../user/userSelectors';
import { getApplicationStatus } from './helpers/functions/application';

export const selectHasRequiredGroups = createSelector(
  selectCognitoGroups,
  (groups) => {
    return getAuthenticationRequiredGroups().every((group) => {
      return groups.includes(group);
    });
  },
);

export const selectApplicationStatus = createSelector(
  selectLastPaidOrder,
  selectCreditsAmount,
  selectHasRequiredGroups,
  (lastPaidOrder, credits, hasGroupPermission) => {
    return getApplicationStatus(lastPaidOrder, credits, hasGroupPermission);
  },
);
