import i18n from 'i18next';

import { getFieldsTableRows } from '../../../fieldProfiler/helpers/functions/tableRows';
import { FieldPipelineStatus } from '../constants/fieldPipelineStatus';
import type { TransformedField } from '../../../../field/types/field';
import type { TransformedFarm } from '../../../../farms/types/farm';
import type { FieldTableRow } from '../../../fieldProfiler/types/tableRow';

const mapFieldsPipelineStatusesToLabels = (
  fieldsStatuses: Record<string, FieldPipelineStatus>,
  tableRows: FieldTableRow[],
) => {
  return tableRows.map((row) => {
    if (row.rowType === 'default') {
      const status = fieldsStatuses[row.id] || null;

      return {
        ...row,
        status: status ? i18n.t(`smart-sampling.${status}`) : '',
      };
    }

    return row;
  });
};

export const getSmartSamplingTableRows = ({
  fieldsStatuses,
  fields,
  farms,
  apiKey,
}: {
  fieldsStatuses: Record<string, FieldPipelineStatus>,
  fields: TransformedField[],
  farms: TransformedFarm[],
  apiKey: string,
}) => {
  const fieldsRows = getFieldsTableRows({
    fields,
    farms,
    apiKey,
  });

  return mapFieldsPipelineStatusesToLabels(fieldsStatuses, fieldsRows);
};
