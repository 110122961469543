import { useEffect, useRef } from 'react';

import { getSteps } from '../../helpers/functions/steps';
import {
  reset as resetCloneZonesMap,
} from '../../cloneZonesMapSlice';
import {
  reset as resetSingleLayer,
} from '../../../createSingleLayerAnalysis/createSingleLayerAnalysisSlice';
import {
  reset as resetFieldPotential,
} from '../../../createFieldPotentialZones/createFieldPotentialZonesSlice';
import {
  reset as resetStability,
} from '../../../createStabilityZones/createStabilityZonesSlice';
import {
  reset as resetCrossLayer,
} from '../../../crossLayer/crossLayerSlice';
import {
  reset as resetMultiLayer,
} from '../../../createMultiLayerAnalysis/createMultiLayerAnalysisSlice';
import {
  reset as resetDrawManuallyWorkflow,
} from '../../../drawManuallyWorkflow/drawManuallyWorkflowSlice';
import { ZonesOpsWorkflow } from '../../../zonesOps/helpers/constants/workflows';
import { selectWorkflow } from '../../cloneZonesMapSelectors';
import { useAppDispatch, useAppSelector } from '../../../../../app/store/helpers/functions';
import useStepComponent from '../../../zonesOps/hooks/useStepComponent';
import { selectAssetType } from '../../../createSingleLayerAnalysis/createSingleLayerAnalysisSelectors';

export default function StepperCloneZonesMap() {
  const dispatch = useAppDispatch();

  const workflow = useAppSelector(selectWorkflow);
  const singleLayerAssetType = useAppSelector(selectAssetType);
  const workflowRef = useRef(workflow);

  useEffect(() => {
    workflowRef.current = workflow;
  }, [workflow]);

  const stepComponent = useStepComponent({
    getStepsFn: getSteps(workflow, singleLayerAssetType),
    resetFn: () => {
      dispatch(resetCloneZonesMap());

      if (workflowRef.current === ZonesOpsWorkflow.createSingleLayerAnalysis) {
        dispatch(resetSingleLayer());
      } else if (workflowRef.current === ZonesOpsWorkflow.createFieldPotentialZones) {
        dispatch(resetFieldPotential());
      } else if (workflowRef.current === ZonesOpsWorkflow.createStabilityZones) {
        dispatch(resetStability());
      } else if (workflowRef.current === ZonesOpsWorkflow.crossLayer) {
        dispatch(resetCrossLayer());
      } else if (workflowRef.current === ZonesOpsWorkflow.createMultiLayerAnalysis) {
        dispatch(resetMultiLayer());
      } else if (workflowRef.current === ZonesOpsWorkflow.drawManually) {
        dispatch(resetDrawManuallyWorkflow());
      }
    },
  });

  return stepComponent;
}
