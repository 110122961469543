import React from 'react';

import { useCreateFarmMutation } from '../../farmsAPI';
import CreateEntity from '../../../../components/Popups/CreateEntity';
import { CreateFarmResult } from '../../types/api';

const CreateFarmPopup = ({
  farmName = '',
  onFarmCreated,
  onCancel,
  ...popup
}: {
  farmName?: string,
  onFarmCreated?: (v: CreateFarmResult) => void;
  onCancel: () => void,
}) => {
  const [createFarm, { isLoading }] = useCreateFarmMutation();

  async function handleCreateClick(value: string) {
    const response = await createFarm({ name: value });

    if ('data' in response) {
      onFarmCreated?.(response.data);
    }

    onCancel();
  }

  return (
    <CreateEntity
      {...popup}
      textValue={farmName}
      i18nKey="create-farm"
      processing={isLoading}
      onCancel={onCancel}
      onConfirm={handleCreateClick}
    />
  );
};

export default CreateFarmPopup;
