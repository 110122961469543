import React, { Fragment, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';

import Button from '../../../../../components/Button';
import ExportIcon from '../../../../../components/Icons/export';
import ToolsPanel from '../../../../../components/ToolsPanel';
import ZonesMapsListFilter from '../../../../../components/ZonesMapsListFilter';
import {
  transformFilterUpdates,
  transformFilterValue,
} from '../../../../../helpers/components/autocomplete';
import { remapNameToTitle } from '../../../../../helpers/markup';
import { fetchLabels } from '../../../../labels/labelsSlice';
import {
  setLabelsExpanded,
  switchSelectionMode,
  updateFilters,
} from '../../zonesMapsSlice';
import { selectLabelsMap } from '../../../../labels/labelsSelectors';
import {
  selectFilter,
  selectLabelsExpanded,
  selectSelectionMode,
} from '../../zonesMapsSelectors';
import useAllFields from '../../../../allFields/hooks/useAllFields';
import { FieldFragment } from '../../../../allFields/helpers/constants/fragments';
import { LoadStatus } from '../../../../../helpers/constants/utils/loadStatus';
import ButtonMenu from '../../../../../components/ButtonMenu';
import { getWorkflowOptions } from '../../../zonesOps/helpers/functions/workflowOptions';
import { ZonesOpsWorkflow } from '../../../zonesOps/helpers/constants/workflows';
import { sendFeatureUsage } from '../../../../../helpers/analytics';

const Panel = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    farm: farmValue,
    field: fieldValue,
    type,
    purpose,
    labels,
    withRates: withRatesValue,
  } = useSelector(selectFilter);
  const labelsMap = useSelector(selectLabelsMap);
  const selectionMode = useSelector(selectSelectionMode);
  const typeValue = type || '';
  const purposeValue = purpose || '';
  const labelsValue = transformFilterValue({ labels });
  const { allFields, status: allFieldsStatus } = useAllFields(FieldFragment.filterField);
  const labelsExpanded = useSelector(selectLabelsExpanded);

  const workflowOptions = useMemo(() => {
    return getWorkflowOptions({
      options: {
        omit: [
          ZonesOpsWorkflow.equationBased,
          ZonesOpsWorkflow.threeDMap,
        ],
      },
    });
  }, []);

  const handleCreateMapItemClick = () => {
    sendFeatureUsage('zones-maps', 'create-zones-map');
  };

  const handleFilterUpdate = (update) => {
    dispatch(updateFilters(update));
  };

  const handleFarmChange = (value) => {
    const update = {
      farm: value,
      type: null,
      purpose: null,
      withRates: false,
    };

    if (value && fieldValue) {
      const hasSelectedField = allFields.some(({ farmUuid }) => {
        return farmUuid === fieldValue.farmUuid;
      });

      if (!hasSelectedField) {
        update.field = null;
      }
    }

    dispatch(updateFilters(update));
  };

  const handleFieldChange = (_event, value) => {
    let update = {
      field: value,
    };

    if (!value) {
      update = {
        ...update,
        type: null,
        purpose: null,
        withRates: false,
      };
    }

    dispatch(updateFilters(update));
  };

  const handleLabelsChange = (filter) => {
    const { labels: newLabels } = transformFilterUpdates(filter, labelsMap);

    dispatch(updateFilters({ labels: newLabels }));
  };

  const handleLabelsFilterGetOptions = () => {
    dispatch(fetchLabels());
  };

  const handleExportButtonClick = () => {
    dispatch(switchSelectionMode());
  };

  const handleLabelsSwitchClick = (isChecked) => {
    dispatch(setLabelsExpanded(isChecked));
  };

  return (
    <ToolsPanel
      classes={{
        root: 'zones-maps-tools-panel',
      }}
      actionsLeft={(
        <Fragment>
          <ButtonMenu
            startIcon={<AddIcon />}
            items={workflowOptions}
            onItemClick={handleCreateMapItemClick}
            horizontalAnchor='left'
          >
            {t('field.tools-panel.create-map-items.create-zones-map')}
          </ButtonMenu>
          <Button
            startIcon={<ExportIcon />}
            pressed={selectionMode}
            classes={{
              root: 'action__btn',
              label: 'action__label',
              startIcon: 'action__icon',
            }}
            onClick={handleExportButtonClick}
          >
            {t('export.export')}
          </Button>
        </Fragment>
      )}
      filtersLeft={(
        <ZonesMapsListFilter
          farm={farmValue}
          fields={remapNameToTitle(allFields)}
          fieldsLoading={allFieldsStatus === LoadStatus.loading}
          field={fieldValue}
          type={typeValue}
          purpose={purposeValue}
          labelsMap={labelsMap}
          labels={labelsValue}
          withRates={withRatesValue}
          withLabelsSwitch
          labelsExpanded={labelsExpanded}
          onFarmChange={handleFarmChange}
          onFieldChange={handleFieldChange}
          onFilterUpdate={handleFilterUpdate}
          onLabelsFilterGetOptions={handleLabelsFilterGetOptions}
          onLabelsChange={handleLabelsChange}
          onLabelsSwitchClick={handleLabelsSwitchClick}
        />
      )}
    />
  );
};

export default Panel;
