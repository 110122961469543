import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { getFieldsOptions } from '../../helpers/functions/filters';
import ComboBox, { Option } from '../../../../../components/ComboBox';
import { Field } from '../../../../asyncOperations/types/api';
import { TransformedFarm } from '../../../../farms/types/farm';

import './index.scss';

export default function FarmFieldFilters({
  farmUuid,
  fieldUuid,
  farms,
  fields,
  loading,
  onFarmChange,
  onFieldChange,
}: {
  farmUuid?: string;
  fieldUuid?: string;
  farms: TransformedFarm[];
  fields: Field[];
  loading?: boolean;
  onFarmChange: (farm?: TransformedFarm, field?: Field) => void;
  onFieldChange: (field?: Field) => void;
}) {
  const { t } = useTranslation();

  const farmOptions = useMemo(
    () => farms.map((item) => ({ title: item.name ?? '', value: item })),
    [farms],
  );

  const farm = useMemo(
    () => farmOptions.find(({ value }) => value.uuid === farmUuid) ?? null,
    [farmOptions, farmUuid],
  );

  const fieldsOptions = useMemo(
    () => getFieldsOptions(fields, farm?.value?.uuid),
    [fields, farm],
  );

  const field = useMemo(
    () => fieldsOptions.find(({ value }) => value.uuid === fieldUuid) ?? null,
    [fieldsOptions, fieldUuid],
  );

  const handleFarmChange = (_event: React.ChangeEvent<{}>, selectedFarm: Option<TransformedFarm> | null) => {
    onFarmChange(selectedFarm?.value, field?.value);
  };

  const handleFieldChange = (_event: React.ChangeEvent<{}>, selectedField: Option<Field> | null) => {
    onFieldChange(selectedField?.value);
  };

  return (
    <div className="farm-field-filters">
      <ComboBox
        title={t('general.shared.farm')}
        placeholder={t('general.controls.select')}
        classes={{
          root: 'farm-field-filters__item',
          tooltipClass: 'farm-field-filters__item-tooltip',
        }}
        value={farm}
        options={farmOptions}
        loading={loading}
        infoTooltip={t('operations.filters.farms-fields-info-tooltip')}
        disableCloseOnSelect={false}
        getOptionSelected={(option, value) => option.value.uuid === value.value.uuid}
        onChange={handleFarmChange}
      />
      <ComboBox
        title={t('general.shared.field')}
        placeholder={t('general.controls.select')}
        classes={{
          root: 'farm-field-filters__item',
        }}
        value={field}
        options={fieldsOptions}
        loading={loading}
        disableCloseOnSelect={false}
        onChange={handleFieldChange}
        getOptionSelected={(option, value) => option.value.uuid === value.value.uuid}
      />
    </div>
  );
}
