import React, { Fragment } from 'react';
import {
  connect,
  useDispatch,
} from 'react-redux';
import {
  useHistory,
  useLocation,
} from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';

import { toggleEditMode } from '../../fieldProfilerSlice';
import Button from '../../../../../components/Button';
import ToolsPanel from '../../../../../components/ToolsPanel';
import Tabs from '../../../../../components/Tabs';
import FieldsListFilter from '../../../../fieldsList/containers/FieldsListFilter';
import {
  getViewType,
  isMapView,
} from '../../helpers/functions/view';
import { VIEWS } from '../../helpers/constants/view';
import {
  PAGES_ROOTS,
  getRootLink,
  updateSearchParam,
} from '../../../../../helpers/navigation';
import { openPopup } from '../../../popups/popupsSlice';
import { selectReachedAreaLimit } from '../../../../user/userSelectors';
import { selectEditMode } from '../../fieldProfilerSelectors';
import { POPUPS } from '../../../popups/helpers/constants/popups';

import './index.scss';

const getTabs = (t) => {
  return [
    {
      label: {
        primary: t('field-profiler.tools-panel.map-view'),
      },
      value: VIEWS.map,
    },
    {
      label: {
        primary: t('field-profiler.tools-panel.table-view'),
      },
      value: VIEWS.table,
    },
  ];
};

const Panel = ({
  editMode,
  onEditFieldsClick,
  onCreateFarmClick,
  createFieldDisabled,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const viewType = getViewType(location.search);
  const isMap = isMapView(viewType);

  const handleTabChange = (_event, newValue) => {
    updateSearchParam(location, history, [{ name: 'view', value: newValue }]);
  };

  const onCreateFieldClick = () => {
    dispatch(openPopup({
      type: POPUPS.createFieldPopup,
    }));
  };

  return (
    <ToolsPanel
      data-test="tools-panel"
      classes={{
        root: 'field-profiler-tools-panel',
        filtersRight: 'field-profiler-tools-panel__filters-right',
      }}
      actionsLeft={(
        <Fragment>
          <Button
            to={getRootLink(PAGES_ROOTS.createField)}
            startIcon={<AddIcon />}
            classes={{
              root: 'action__btn',
              label: 'action__label',
              startIcon: 'action__icon',
            }}
            disabled={createFieldDisabled}
            onClick={onCreateFieldClick}
          >
            {t('field-profiler.tools-panel.create-field')}
          </Button>
          <Button
            startIcon={<AddIcon />}
            classes={{
              root: 'action__btn',
              label: 'action__label',
              startIcon: 'action__icon',
            }}
            onClick={onCreateFarmClick}
          >
            {t('field-profiler.tools-panel.create-farm')}
          </Button>
          {
            !isMap
            && (
              <Button
                startIcon={<EditIcon />}
                pressed={editMode}
                classes={{
                  root: 'action__btn',
                  label: 'action__label',
                  startIcon: 'action__icon',
                }}
                onClick={onEditFieldsClick}
              >
                {t('field-profiler.tools-panel.edit-fields')}
              </Button>
            )
          }
        </Fragment>
      )}
      filtersLeft={(
        isMap
          ? <></>
          : (
            <FieldsListFilter
              withLabelsExpandSwitcher={!isMap}
              withCreateFarm
            />
          )
      )}
      filtersRight={(
        <Tabs
          value={viewType}
          tabs={getTabs(t)}
          onTabChange={handleTabChange}
        />
      )}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    editMode: selectEditMode(state),
    createFieldDisabled: selectReachedAreaLimit(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onEditFieldsClick: () => {
    dispatch(toggleEditMode());
  },
  onCreateFarmClick: () => {
    dispatch(openPopup({
      type: 'create-farm',
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Panel);
