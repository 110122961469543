import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

import { PricingTableType } from '../../helpers/constants';
import AntSwitch from '../../../../../components/AntSwitch';
import Button from '../../../../../components/Button';

import './index.scss';

export default function PricingTableToggle({
  pricingTableType,
  onPricingTableTypeChange,
}: {
  pricingTableType: PricingTableType;
  onPricingTableTypeChange: (type: PricingTableType) => void;
}) {
  const { t } = useTranslation();

  const handleValueChange = (checked: boolean) => {
    const selectedPricingTableType = checked
      ? PricingTableType.hectares
      : PricingTableType.acres;
    onPricingTableTypeChange(selectedPricingTableType);
  };

  return (
    <div className="pricing-table-toggle">
      <Button
        classes={{
          root: clsx('pricing-table-toggle__button', {
            'pricing-table-toggle__button-selected': pricingTableType === PricingTableType.acres,
          }),
        }}
        TouchRippleProps={{ classes: { root: 'pricing-table-toggle__button-ripple' } }}
        onClick={() => onPricingTableTypeChange(PricingTableType.acres)}
      >
        {t('general.area-unit.acres-unit')}
      </Button>
      <AntSwitch
        classes={{
          label: 'pricing-table-toggle__control-label',
          switch: {
            root: 'pricing-table-toggle__control',
            base: 'pricing-table-toggle__control-base',
            track: 'pricing-table-toggle__control-track',
            checked: 'pricing-table-toggle__control-checked',
          },
        }}
        isChecked={pricingTableType === PricingTableType.hectares}
        onValueChange={handleValueChange}
      />
      <Button
        classes={{
          root: clsx('pricing-table-toggle__button', {
            'pricing-table-toggle__button-selected': pricingTableType === PricingTableType.hectares,
          }),
        }}
        TouchRippleProps={{ classes: { root: 'pricing-table-toggle__button-ripple' } }}
        onClick={() => onPricingTableTypeChange(PricingTableType.hectares)}
      >
        {t('general.area-unit.hectares-unit')}
      </Button>
    </div>
  );
}
