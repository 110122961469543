export enum MenuAction {
  goToMap = 'go-to-map',
  assignRates = 'assign-rates',
  exportMap = 'export-map',
  downloadSourceData = 'download-source-data',
  downloadOriginalData = 'download-original-data',
  exportToJDAsFiles = 'export-to-jd-as-files',
  exportToJDAsMapsLayers = 'export-to-jd-as-maps-layers',
  exportToJDAsWorkPlan = 'export-to-jd-as-work-plan',
  cloneZonesMap = 'clone-zones-map',
  renameZonesMap = 'rename-zones-map',
  deleteZonesMap = 'delete-zones-map',
  downloadEquationMap = 'download-equation-map',
  renameEquationMap = 'rename-equation-map',
  deleteEquationMap = 'delete-equation-map',
  renameDataset = 'rename-dataset',
  deleteDataset = 'delete-dataset',
  orderPlanetImage = 'order-planet-image',
  orderProcessingPlanetImage = 'order-processing-planet-image',
  rename3DMap = 'rename-3d-map',
  delete3DMap = 'delete-3d-map',
}

// Left offset of the TreeViewItem according to the layout
export const TREE_VIEW_ITEM_OFFSET = 5;
export const CHILD_TREE_VIEW_ITEM_OFFSET = 37;
