import React from 'react';
import {
  HashRouter,
  Switch,
  Route,
} from 'react-router-dom';
import config from './config';

const Routers = () => (
  <HashRouter>
    <Switch>
      {
        config.map((route, i) => (
          <Route key={i} {...route} />
        ))
      }
    </Switch>
  </HashRouter>
);

export default Routers;
