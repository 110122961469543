import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import {
  selectApiKey,
  selectDebitedOrganization,
  selectOwnedOrganizations,
} from '../../../user/userSelectors';
import { getZonesMapsData } from '../helpers/functions/zonesMaps';
import { useGetHomepageDataQuery } from '../homepageAPI';
import type { ZonesMapData } from '../types/ui';

export default function useHomepageData(): {
  isFetching: boolean,
  zonesMaps: ZonesMapData[],
  credits: {
    isAvailable: boolean,
    balance?: number,
  },
} {
  const apiKey = useSelector(selectApiKey);
  const ownedOrganizations = useSelector(selectOwnedOrganizations);
  const debitedOrganization = useSelector(selectDebitedOrganization);
  const creditsAvailable = ownedOrganizations.length !== 0 || !debitedOrganization;
  const {
    data,
    isFetching,
  } = useGetHomepageDataQuery();
  const zonesMaps = useMemo(() => getZonesMapsData(apiKey, data), [apiKey, data]);

  return {
    isFetching,
    zonesMaps,
    credits: {
      isAvailable: creditsAvailable,
      balance: data?.credits,
    },
  };
}
