export const FEATURE = 'jdImport';

export enum Actions {
  selectOrganization = 'selectOrganization',
  selectFields = 'selectFields',
  selectImportType = 'selectImportType',
  selectImportFiltersType = 'selectImportFiltersType',
  selectImportFiltersPeriod = 'selectImportFiltersPeriod',
  importFieldsWithDataFiles = 'importFieldsWithDataFiles',
  importFieldsFieldBoundaries = 'importFieldsFieldBoundaries',
  cancelWorkflow = 'cancelWorkflow',
}
