import i18n from 'i18next';

export const getSteps = () => {
  return [
    {
      name: i18n.t('create-3d.stepper.shape-data-type'),
      description: i18n.t('create-3d.steps.1.description'),
      id: 'shapeDataType',
    },
    {
      name: i18n.t('create-3d.stepper.shape-data-source'),
      description: i18n.t('create-3d.steps.2.description'),
      id: 'shapeDataSource',
    },
    {
      name: i18n.t('general.shared.zones-map'),
      description: i18n.t('create-3d.steps.3.description'),
      id: 'zonesMap',
    },
    {
      name: i18n.t('general.shared.preview'),
      id: 'preview',
    },
  ];
};
