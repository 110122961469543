import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';

import { getNewUserKey, getPlanSubscriptionKey } from '../helpers/functions/utils/appConfig';

const NewUser = () => {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const data = {
    plan: search.get('plan'),
    period: search.get('period'),
    currency: search.get('currency'),
  };

  localStorage.setItem(getPlanSubscriptionKey(), JSON.stringify(data));
  localStorage.setItem(getNewUserKey(), true);

  return (
    <Redirect to="/" />
  );
};

export default NewUser;
