import { useSelector } from 'react-redux';

import {
  isPlanSubscriptionWorkflowEnabled,
} from '../helpers/functions/utils/appConfig';
import {
  selectOrganizations,
  selectPermissionedOrganizationAdmin,
} from '../features/user/userSelectors';

/**
 * Determines whether subscription-related features
 * (e.g. /plan-subscription and /user/subscription pages, homepage credits panel) are available.
 * @returns subscriptionAvailable
 */
export default function useSubscriptionAvailable(): boolean {
  const orgAdmin = useSelector(selectPermissionedOrganizationAdmin);
  const orgs = useSelector(selectOrganizations);
  const planSubscriptionWorkflowEnabled = isPlanSubscriptionWorkflowEnabled();

  return planSubscriptionWorkflowEnabled && (orgAdmin || orgs.length === 0);
}
