import React from 'react';
import { useSelector } from 'react-redux';
import { Trans, useTranslation } from 'react-i18next';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import InfoIcon from '@material-ui/icons/InfoOutlined';

import { getCustomerPortalLink } from '../../../../../../helpers/functions/utils/vendorConfigs/stripe';
import { getBrandName, getContactsLink } from '../../../../../../helpers/functions/utils/appConfig';
import { selectOrders } from '../../../../../user/userSelectors';
import { hasStripePayment } from '../../../../../user/helpers/functions/order';
import Link from '../../../../../../components/Link';
import { PAGES_ROOTS, getRootLink } from '../../../../../../helpers/navigation';

const NoCreditsCard = () => {
  const { t } = useTranslation();
  const brandName = getBrandName();
  const orders = useSelector(selectOrders);

  return (
    <Paper
      square
      classes={{
        root: 'restricted-access-card',
      }}
    >
      <div className="restricted-access-card__container">
        <Typography
          variant="h1"
          classes={{
            root: 'restricted-access-card__title',
          }}
        >
          {t('restricted-access.no-credits.title')}
        </Typography>
        <div className="restricted-access-card__info">
          <Typography
            classes={{
              root: 'restricted-access-card__text',
            }}
          >
            {t('restricted-access.no-credits.description.p1')}
          </Typography>
          <Typography
            classes={{
              root: 'restricted-access-card__text',
            }}
          >
            <Trans i18nKey="restricted-access.no-credits.description.p2">
              {{ brandName }}
              <a
                className="link"
                target="_blank"
                href={getContactsLink()}
              >
                link
              </a>
              text
              {
                hasStripePayment(orders)
                  ? (
                    <a
                      className="link"
                      target="_blank"
                      href={getCustomerPortalLink()}
                    >
                      link
                    </a>
                  )
                  : (
                    <Link to={getRootLink(PAGES_ROOTS.planSubscription)}>
                      link
                    </Link>
                  )
              }
              text
            </Trans>
          </Typography>
        </div>
        <Typography
          variant="body2"
          classes={{
            root: 'restricted-access-card__disclaimer',
          }}
        >
          <Trans i18nKey="restricted-access.contact-us">
            text
            <a
              className="link"
              target="_blank"
              href={getContactsLink()}
            >
              link
            </a>
            text
          </Trans>
        </Typography>
      </div>
      <InfoIcon className="restricted-access-card__icon"/>
    </Paper>
  );
};

export default NoCreditsCard;
