import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { fetchFields, loadMoreFields } from '../../../../fieldsList/fieldsListSlice';
import { getSmartSamplingTableRows } from '../../helpers/functions/tableRows';
import { generatePinsGroups } from '../../smartSamplingSlice';
import {
  selectFields,
  selectIsLoaded,
  selectLastEvaluatedKey,
} from '../../../../fieldsList/fieldsListSelectors';
import { selectLabelsExpanded } from '../../../fieldProfiler/fieldProfilerSelectors';
import { selectFieldsStatusesEntities } from '../../smartSamplingSelectors';
import { selectAreaUnit, selectApiKey } from '../../../../user/userSelectors';
import useDidMount from '../../../../../hooks/useDidMount';
import { useGetFarmsQuery } from '../../../../farms/farmsAPI';
import FieldProfilerTableComponent from '../../../fieldProfiler/components/Table';
import { DefaultFieldTableRow } from '../../../fieldProfiler/types/tableRow';
import { getTableColumns } from '../../helpers/functions/tableColumns';

export default function SmartSamplingTable() {
  const dispatch = useDispatch();
  const fieldsStatuses = useSelector(selectFieldsStatusesEntities);
  const apiKey = useSelector(selectApiKey);
  const areaUnit = useSelector(selectAreaUnit);
  const lastEvaluatedKey = useSelector(selectLastEvaluatedKey);
  const isFieldsLoaded = useSelector(selectIsLoaded);
  const fields = useSelector(selectFields);
  const labelsExpanded = useSelector(selectLabelsExpanded);

  const {
    data: farms,
    isFetching: isFarmsFetching,
  } = useGetFarmsQuery();

  const handleLoadMoreRows = () => {
    dispatch(loadMoreFields());
  };

  const onRowStartButtonClick = (row: DefaultFieldTableRow) => {
    // @ts-expect-error
    dispatch(generatePinsGroups({
      fieldUuid: row.id,
    }));
  };

  useDidMount(() => {
    dispatch(fetchFields());
  });

  const rows = getSmartSamplingTableRows({
    fieldsStatuses,
    fields,
    farms: farms || [],
    apiKey,
  });
  const columns = getTableColumns({
    areaUnit,
    onRowStartButtonClick,
  });

  return (
    <FieldProfilerTableComponent
      rows={rows}
      columns={columns}
      loadComplete={!lastEvaluatedKey}
      loading={!isFieldsLoaded || isFarmsFetching}
      labelsExpanded={labelsExpanded}
      onLoadMoreRows={handleLoadMoreRows}
    />
  );
}
