export const POPUPS = {
  addDataLayer: 'add-data-layer',
  selectDataVariable: 'select-data-variable',
  exportEquationMap: 'export-equation-map',
  planetMetadataRequest: 'planet-metadata-request',
  planetMetadataStatus: 'planet-metadata-status',
  colorSchema: 'color-schema',
  deleteColorSchema: 'delete-color-schema',
  uploadFiles: 'upload-files',
  exportSourceData: 'export-source-data',
  cleanCalibrateYield: 'clean-calibrate-yield',
  exportFieldsToJD: 'export-fields-to-JD',
  reportIssue: 'report-issue',
  createFieldPopup: 'create-field-popup',
  copyPolygons: 'copy-polygons',
  closeUpload: 'close-upload',
  changeBackgroundLayer: 'change-background-layer',
  equationTestRun: 'equation-test-run',
  createEquation: 'create-equation',
  updateEquation: 'update-equation',
  assignVariable: 'assign-variable',
  finishGeneration: 'finish-generation',
  exportToJohnDeereAsWorkPlan: 'export-to-john-deere-as-work-plan',
};
