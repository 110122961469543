import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import { errorNotify } from '../notifications/helpers/functions/notify';
import { CustomError } from '../../helpers/functions/utils/errorHandling';
import {
  getLabels,
  saveLabels as saveLabelsAPI,
  deleteLabel as deleteLabelAPI,
} from './labelsAPI';
import { selectLoaded } from './labelsSelectors';

const initialState = {
  labels: [],
  loaded: false,
};

export const fetchLabels = createAsyncThunk(
  'labels/fetchLabels',
  async (_, { dispatch }) => {
    let result = [];

    try {
      result = await getLabels();
    } catch (error) {
      errorNotify({
        error: new CustomError('[Labels] Unable to fetch labels.', {
          cause: error,
        }),
        dispatch,
      });
    }

    return result;
  },
  {
    condition: (_, { getState }) => {
      if (selectLoaded(getState())) {
        return false;
      }
    },
  },
);

export const saveLabels = createAsyncThunk(
  'labels/saveLabels',
  async (payload, { dispatch }) => {
    let result;

    try {
      result = await saveLabelsAPI(payload);
    } catch (error) {
      errorNotify({
        error: new CustomError('[Labels] Unable to save labels.', {
          cause: error,
        }),
        dispatch,
      });
    }

    return result;
  },
);

export const deleteLabel = createAsyncThunk(
  'labels/deleteLabel',
  async (payload, { dispatch }) => {
    let result;

    try {
      result = await deleteLabelAPI(payload);
    } catch (error) {
      errorNotify({
        error: new CustomError('Unable to delete label.', {
          cause: error,
        }),
        dispatch,
      });
    }

    return result;
  },
);

export const labelsSlice = createSlice({
  name: 'labels',
  initialState,
  extraReducers: (builder) => {
    builder
      .addCase(fetchLabels.fulfilled, (state, action) => {
        state.loaded = true;
        state.labels = action.payload;
      })
      .addCase(saveLabels.fulfilled, (state, action) => {
        if (action.payload) {
          state.labels.push(...action.payload);
        }
      })
      .addCase(deleteLabel.fulfilled, (state, action) => {
        if (action.payload) {
          state.labels = state.labels.filter(({ name, value }) => {
            return name !== action.payload.name
              || value !== action.payload.value;
          });
        }
      });
  },
});

export default labelsSlice.reducer;
