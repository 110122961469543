import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import Typography from '@material-ui/core/Typography';

import Button from '../../../../../../../components/Button';
import { getContactsLink } from '../../../../../../../helpers/functions/utils/appConfig';
import { prepareEmailAttribute } from '../../../../helpers/functions/authentication';

import '../common/index.scss';
import './index.scss';

const SignUpSentPanel = () => {
  const { state: locationState } = useLocation();
  const [resendClicked, setResendClicked] = useState();

  const onActionClick = () => {
    const preparedEmail = prepareEmailAttribute(locationState.email);

    setResendClicked(true);
    Auth.resendSignUp(preparedEmail);
  };

  return (
    <div className="authentication-panel sign-up-sent-panel">
      <Typography
        classes={{
          root: 'authentication-panel__header sign-up-sent-panel__header',
        }}
        variant="h1"
      >
        Confirm Registration
      </Typography>
      <Typography
        classes={{
          root: 'authentication-panel__tip',
        }}
      >
        We&apos;ve sent you a confirmation instructions to {locationState.email}.
        Please click it to confirm your email address.
      </Typography>
      <Typography
        classes={{
          root: 'authentication-panel__tip authentication-panel__tip_extra',
        }}
      >
        Haven&apos;t received our email? It might be in your spam/junk folder.
      </Typography>
      {
         resendClicked
         && (
           <Typography
             classes={{
               root: 'authentication-panel__tip authentication-panel__tip_extra',
             }}
           >
             If you still haven&apos;t received the email, please <a
               className="sign-up-sent-panel__link link"
               target="_blank"
               href={getContactsLink()}
                                                                  >
               contact us
             </a>.
           </Typography>
         )
       }
      <Button
        className="authentication-panel__link sign-up-sent-panel__action-link link"
        onClick={onActionClick}
      >
        Send instructions again
      </Button>
    </div>
  );
};

export default SignUpSentPanel;
