import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';

import AuthenticationRedirector from '../routes/redirectors/Authentication';
import PricingRedirector from '../routes/redirectors/Pricing';
import Stepper from '../features/ui/zonesOps/containers/Stepper';
import Legend from '../features/ui/zonesOps/containers/Legend';
import NotFound from '../components/NotFound';
import Link from '../components/Link';
import FieldTitle from '../features/field/containers/FieldTitle';
import {
  preselectWorkflow,
  reset as resetZonesOps,
} from '../features/ui/zonesOps/zonesOpsSlice';
import {
  reset as resetCreateAnalysis,
} from '../features/createAnalysis/createAnalysisSlice';
import {
  PAGES_ROOTS,
  getRootLink,
  getFieldLink,
} from '../helpers/navigation';
import { getDocumentTitle } from '../helpers';
import BaseTemplate from './BaseTemplate.jsx';
import useDidMount from '../hooks/useDidMount';
import {
  selectName,
  selectArea,
  selectNotFound,
} from '../features/field/fieldSelectors';

const ZonesOps = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const {
    farmUuid,
    fieldUuid,
    workflow,
  } = useParams();
  const name = useSelector(selectName);
  const area = useSelector(selectArea);
  const fieldNotFound = useSelector(selectNotFound);

  useDidMount(() => {
    document.title = getDocumentTitle(t('general.navigation.zones-maps'));

    return () => {
      dispatch(resetZonesOps());
      dispatch(resetCreateAnalysis());
    };
  });

  useEffect(() => {
    dispatch(preselectWorkflow({
      farmUuid,
      fieldUuid,
      workflow,
    }));
  }, [
    dispatch,
    farmUuid,
    fieldUuid,
    workflow,
  ]);

  const onBackButtonClick = () => {
    history.goBack();
  };

  let content = [
    <Stepper key="panel"/>,
    <Legend key="legend"/>,
  ];
  let headerProps = {
    text: t('zones-ops.header'),
    secondaryText: '',
    onBackButtonClick,
  };

  if (area && farmUuid && fieldUuid && name) {
    headerProps.secondaryText = (
      <FieldTitle
        fieldArea={area}
        fieldName={(
          <Link to={getFieldLink(farmUuid, fieldUuid)}>
            {name}
          </Link>
        )}
      />
    );
  }

  if (fieldNotFound) {
    headerProps = {};
    content = [
      <NotFound
        key="panel"
        description={t('field.not-found-description')}
        link={
          <Link to={getRootLink(PAGES_ROOTS.zonesMaps)}>{t('zones-map.not-found-link')}</Link>
        }
      />,
    ];
  }

  return (
    <AuthenticationRedirector>
      <PricingRedirector>
        <BaseTemplate
          route={PAGES_ROOTS.zonesOps}
          headerProps={headerProps}
        >
          { content }
        </BaseTemplate>
      </PricingRedirector>
    </AuthenticationRedirector>
  );
};

export default ZonesOps;
