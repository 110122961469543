import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import AddIcon from '@material-ui/icons/Add';

import PopupHeader from '../../../../components/Popups/PopupHeader';
import Button from '../../../../components/Button';
import TextField from '../../../../components/TextField';
import { saveLabels } from '../../labelsSlice';

import './index.scss';

const CreateLabelPopup = ({
  onCancel = () => {},
  onConfirm = () => {},
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [labelName, setLabelName] = React.useState('');
  const [labelValues, setLabelValues] = React.useState(['']);
  const [confirmButtonDisabled, setConfirmButtonDisabled] = React.useState(true);
  const [addValueButtonDisabled, setAddValueButtonDisabled] = React.useState(true);

  const handleLabelNameChange = (e) => {
    const hasValueName = labelValues.some((value) => value.length);

    setLabelName(e.target.value);
    setConfirmButtonDisabled(!e.target.value || !hasValueName);
  };

  const handleLabelValueChange = (e, valueIndex) => {
    let hasLabelValue = false;
    let eachLabelValueFilled = true;

    const updatedLabelValues = labelValues.map((value, index) => {
      if (index === valueIndex) {
        hasLabelValue = hasLabelValue || !!e.target.value;
        eachLabelValueFilled = eachLabelValueFilled && !!e.target.value;

        return e.target.value;
      }

      hasLabelValue = hasLabelValue || !!value;
      eachLabelValueFilled = eachLabelValueFilled && !!value;

      return value;
    });

    setLabelValues(updatedLabelValues);
    setConfirmButtonDisabled(!hasLabelValue || !labelName);
    setAddValueButtonDisabled(!eachLabelValueFilled);
  };

  const handleAddValueClick = () => {
    setLabelValues([...labelValues, '']);
    setAddValueButtonDisabled(true);
  };

  const handleConfirmClick = () => {
    const trimmedName = labelName.trim();
    const trimmedValues = labelValues.map((v) => v.trim())
      .filter((v) => !!v);

    dispatch(saveLabels(trimmedValues.map((value) => ({
      name: trimmedName,
      value,
    }))))
      .then(onConfirm);
  };

  return (
    <Dialog open>
      <PopupHeader
        title={t('general.popups.create-label.title')}
        onCancel={onCancel}
      />
      <DialogContent>
        <DialogContentText
          classes={{
            root: 'create-label-popup__content-text',
          }}
        >
          {t('general.popups.create-label.description')}
        </DialogContentText>
        <div className="create-label-popup__form">
          <TextField
            className="create-label-popup__form__name-input"
            title={t('general.popups.create-label.label-name-title')}
            value={labelName}
            placeholder={t('general.popups.create-label.label-name-placeholder')}
            onChange={handleLabelNameChange}
          />
          <div className="create-label-popup__form__value-container">
            {
              labelValues.map((value, index) => (
                <TextField
                  key={index}
                  title={index === 0 ? t('general.popups.create-label.label-value-title') : null}
                  value={value}
                  placeholder={t('general.popups.create-label.label-value-placeholder')}
                  classes={{
                    wrapper: 'create-label-popup__form__value-container__input',
                  }}
                  onChange={(e) => handleLabelValueChange(e, index)}
                />
              ))
            }
            <Button
              classes={{
                root: 'create-label-popup__form__value-container__add-button',
              }}
              startIcon={<AddIcon />}
              disabled={addValueButtonDisabled}
              onClick={handleAddValueClick}
            >
              {t('general.popups.create-label.add-value')}
            </Button>
          </div>
        </div>
      </DialogContent>
      <DialogActions
        classes={{
          root: 'create-label-popup__actions',
        }}
      >
        <Button
          onClick={onCancel}
          variant="outlined"
        >
          {t('general.controls.cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={confirmButtonDisabled}
          onClick={handleConfirmClick}
        >
          {t('general.popups.create-label.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateLabelPopup;
