import React from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';

import {
  fetchFields,
  loadMoreFields,
} from '../../../../fieldsList/fieldsListSlice';
import { selectSelectedField } from '../../zonesOpsSelectors';
import {
  selectFields,
  selectIsLoaded,
  selectLastEvaluatedKey,
} from '../../../../fieldsList/fieldsListSelectors';
import { selectLabelsExpanded } from '../../../fieldProfiler/fieldProfilerSelectors';
import { selectApiKey, selectAreaUnit } from '../../../../user/userSelectors';
import useDidMount from '../../../../../hooks/useDidMount';
import { useGetFarmsQuery } from '../../../../farms/farmsAPI';
import {
  getFieldsTableRows,
  isCollapsibleLabelsNestedTableRow,
  isDefaultFieldTableRow,
} from '../../../fieldProfiler/helpers/functions/tableRows';
import FieldProfilerTable from '../../../fieldProfiler/components/Table';
import { getBaseTableColumns } from '../../../fieldProfiler/helpers/functions/tableColumns';
import type { FieldTableRow } from '../../../fieldProfiler/types/tableRow';
import { setSelectedField } from '../../zonesOpsSlice';

export default function FieldsListTable() {
  const dispatch = useDispatch();
  const {
    data: farms,
    isFetching: isFarmsFetching,
  } = useGetFarmsQuery();

  const { fieldUuid } = useSelector(selectSelectedField);
  const apiKey = useSelector(selectApiKey);
  const areaUnit = useSelector(selectAreaUnit);
  const isFieldsLoaded = useSelector(selectIsLoaded);
  const lastEvaluatedKey = useSelector(selectLastEvaluatedKey);
  const fields = useSelector(selectFields);
  const labelsExpanded = useSelector(selectLabelsExpanded);

  const handleLoadMoreRows = () => {
    dispatch(loadMoreFields());
  };

  const handleRowClick = (row: FieldTableRow | null) => {
    if (!row) {
      return;
    }

    let uuid = '';

    if (isDefaultFieldTableRow(row)) {
      uuid = row.id;
    } else if (isCollapsibleLabelsNestedTableRow(row)) {
      uuid = row.fieldUuid;
    }

    dispatch(setSelectedField({
      farmUuid: row.farmUuid || '',
      uuid,
    }));
  };

  useDidMount(() => {
    dispatch(fetchFields());
  });

  const rows = getFieldsTableRows({
    fields,
    farms: farms || [],
    apiKey,
  });
  const columns = getBaseTableColumns({
    selectable: false,
    areaUnit,
  });

  return (
    <FieldProfilerTable
      rows={rows}
      columns={columns}
      loadComplete={!lastEvaluatedKey}
      loading={!isFieldsLoaded || isFarmsFetching}
      selectedFieldUuid={fieldUuid}
      labelsExpanded={labelsExpanded}
      onLoadMoreRows={handleLoadMoreRows}
      onRowClick={handleRowClick}
    />
  );
}
