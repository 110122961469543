import { MIN_PASSWORD_LENGTH } from '../constants/authentication';
import { HELPER_TEXTS, HelperTextType } from '../constants/helperTexts';

const validatePassword = (value) => {
  const errors = new Set();

  if (value.length < MIN_PASSWORD_LENGTH) {
    errors.add(HELPER_TEXTS.passwordLength);
  }

  if (!/\d/.test(value)) {
    errors.add(HELPER_TEXTS.passwordNumeric);
  }

  if (value !== value.trim()) {
    errors.add(HELPER_TEXTS.passwordStartEndSpaces);
  }

  return errors;
};

export const getPasswordHelperTexts = (fieldValue) => {
  const errors = validatePassword(fieldValue);

  const helperTexts = [
    {
      label: HELPER_TEXTS.passwordLength,
      type: errors.has(HELPER_TEXTS.passwordLength)
        ? HelperTextType.error
        : HelperTextType.success,
    },
    {
      label: HELPER_TEXTS.passwordNumeric,
      type: errors.has(HELPER_TEXTS.passwordNumeric)
        ? HelperTextType.error
        : HelperTextType.success,
    },
  ];

  if (errors.has(HELPER_TEXTS.passwordStartEndSpaces)) {
    helperTexts.push({
      label: HELPER_TEXTS.passwordStartEndSpaces,
      type: HelperTextType.error,
    });
  }

  return helperTexts;
};
