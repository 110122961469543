import React from 'react';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import TablePanel from '../../../../../components/TablePanel';
import Button from '../../../../../components/Button';
import { switchSelectionMode } from '../../zonesMapsSlice';
import { openExportZonesMapPopup } from '../../../../exportData/exportDataSlice';
import { selectSelectedZonesMaps } from '../../zonesMapsSelectors';

import './index.scss';

const ZonesMapsTablePanel = ({
  selectedZonesMaps = [],
  onCancelClick = () => {},
  onExportClick = () => {},
}) => {
  const { t } = useTranslation();

  return (
    <TablePanel
      elementsLeft={[
        <Typography
          key="description"
          classes={{
            root: 'zones-maps-table-panel__description',
          }}
        >
          {t('zones-maps.table-panel.description')}
        </Typography>,
      ]}
      elementsRight={[
        <Button
          key="cancel"
          variant="outlined"
          classes={{
            root: 'zones-maps-table-panel__button zones-maps-table-panel__button_cancel',
          }}
          onClick={onCancelClick}
        >
          {t('general.controls.cancel')}
        </Button>,
        <Button
          key="export"
          variant="contained"
          color="primary"
          disabled={selectedZonesMaps.length === 0}
          classes={{
            root: 'zones-maps-table-panel__button',
          }}
          onClick={onExportClick}
        >
          {t('export.export')}
        </Button>,
      ]}
      classes={{
        root: 'zones-maps-table-panel',
      }}
    />
  );
};

const mapStateToProps = (state) => {
  const selectedZonesMaps = selectSelectedZonesMaps(state);

  return {
    selectedZonesMaps,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onCancelClick: () => {
    dispatch(switchSelectionMode());
  },
  onExportClick: (selectedZonesMaps) => {
    dispatch(openExportZonesMapPopup({
      zonesMaps: selectedZonesMaps,
    }));
  },
});

const mergeProps = (propsFromState, propsFromDispatch) => ({
  ...propsFromState,
  ...propsFromDispatch,
  onExportClick: () => propsFromDispatch.onExportClick(propsFromState.selectedZonesMaps),
});

export default connect(mapStateToProps, mapDispatchToProps, mergeProps)(ZonesMapsTablePanel);
