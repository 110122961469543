import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AuthenticationRedirector from '../routes/redirectors/Authentication';
import PricingRedirector from '../routes/redirectors/Pricing';
import Table from '../features/ui/zonesMaps/containers/Table';
import ToolsPanel from '../features/ui/zonesMaps/containers/ToolsPanel';
import TablePanel from '../features/ui/zonesMaps/containers/TablePanel';
import BaseTemplate from './BaseTemplate.jsx';
import { PAGES_ROOTS } from '../helpers/navigation';
import { getDocumentTitle } from '../helpers';
import { selectSelectionMode } from '../features/ui/zonesMaps/zonesMapsSelectors';
import useDidMount from '../hooks/useDidMount';

const ZonesMaps = () => {
  const { t } = useTranslation();
  const selectionMode = useSelector(selectSelectionMode);

  useDidMount(() => {
    document.title = getDocumentTitle(t('general.navigation.zones-maps'));
  });

  return (
    <AuthenticationRedirector>
      <PricingRedirector>
        <BaseTemplate
          route={PAGES_ROOTS.zonesMaps}
          headerProps={{
            text: t('general.navigation.zones-maps'),
          }}
        >
          <ToolsPanel key="tools-panel" />
          { selectionMode && <TablePanel key="instrument-panel" /> }
          <Table key="panel" />
        </BaseTemplate>
      </PricingRedirector>
    </AuthenticationRedirector>
  );
};

export default ZonesMaps;
