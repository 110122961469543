import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import useDidMount from '../../../../../hooks/useDidMount';
import {
  toggleFieldSelection,
  setFieldsSelection,
} from '../../fieldProfilerSlice';
import {
  deleteFields,
  renameField,
  setFieldLabels,
  fetchFields,
  loadMoreFields,
} from '../../../../fieldsList/fieldsListSlice';
import { openPopup } from '../../../popups/popupsSlice';
import { sendFeatureUsage } from '../../../../../helpers/analytics';
import { PAGES_ROOTS } from '../../../../../helpers/navigation';
import { getRowsSelection } from '../../../tableView/helpers/functions/rows';
import {
  selectFields,
  selectIsLoaded,
  selectLastEvaluatedKey,
} from '../../../../fieldsList/fieldsListSelectors';
import {
  selectEditMode,
  selectLabelsExpanded,
  selectSelectedFields,
} from '../../fieldProfilerSelectors';
import { selectAreaUnit, selectApiKey } from '../../../../user/userSelectors';
import { useGetFarmsQuery } from '../../../../farms/farmsAPI';
import { selectProfileIsLoading } from '../../../../jdProfile/jdProfileSelectors';
import type { DefaultFieldTableRow, FieldTableRow } from '../../types/tableRow';
import type { Label, TransformedField } from '../../../../field/types/field';
import type { CollapsibleLabelsNestedTableRow, RowMenuItem } from '../../../tableView/types/row';
import { getTableColumns } from '../../helpers/functions/tableColumns';
import FieldProfilerTableComponent from '../../components/Table';
import {
  isCollapsibleLabelsNestedTableRow,
  isDefaultFieldTableRow,
  getFieldsTableRows,
} from '../../helpers/functions/tableRows';

export default function FieldProfilerTable() {
  const dispatch = useDispatch();
  const {
    data: farms,
    isFetching: isFarmsFetching,
  } = useGetFarmsQuery();

  const apiKey = useSelector(selectApiKey);
  const areaUnit = useSelector(selectAreaUnit);
  const fields = useSelector(selectFields);
  const selectedFields = useSelector(selectSelectedFields);
  const lastEvaluatedKey = useSelector(selectLastEvaluatedKey);
  const editMode = useSelector(selectEditMode);
  const isFieldsLoaded = useSelector(selectIsLoaded);
  const labelsExpanded = useSelector(selectLabelsExpanded);
  const isJdProfileFetching = useSelector(selectProfileIsLoading);

  const handleLoadMoreRows = () => {
    dispatch(loadMoreFields());
  };

  const openManageLabelsPopup = (row: FieldTableRow) => {
    let fieldName = '';
    let fieldUuid = '';

    if (isCollapsibleLabelsNestedTableRow(row)) {
      fieldName = row.fieldName || '';
      fieldUuid = row.fieldUuid;
    } else if (isDefaultFieldTableRow(row)) {
      fieldName = row.name.title || '';
      fieldUuid = row.id;
    }

    sendFeatureUsage('manage-labels', 'view', {
      page: PAGES_ROOTS.fields,
    });

    dispatch(openPopup({
      type: 'manage-labels',
      fieldLabels: row.labels,
      fieldName,
      onConfirm: (labels: Label[]) => {
        // @ts-expect-error
        dispatch(setFieldLabels({
          farmUuid: row.farmUuid,
          fieldUuid,
          labels,
        }));
      },
    }));
  };

  const onMenuItemClick = ({ id }: RowMenuItem, row: DefaultFieldTableRow) => {
    if (id === 'delete-field') {
      dispatch(openPopup({
        type: 'delete-field',
        entityName: row.name.title,
        onConfirm: () => {
          // @ts-expect-error
          dispatch(deleteFields([{
            farmUuid: row.farmUuid,
            uuid: row.id,
          }]));
        },
      }));
    } else if (id === 'rename-field') {
      dispatch(openPopup({
        type: 'rename-field',
        entityName: row.name.title,
        onConfirm: (name: string) => {
          // @ts-expect-error
          dispatch(renameField({
            fieldUuid: row.id,
            farmUuid: row.farmUuid,
            name,
          }));
        },
      }));
    }
  };

  const onDeleteFieldLabel = (row: CollapsibleLabelsNestedTableRow, labelIndex: number) => {
    const updatedLabels = [...row.labels];

    updatedLabels.splice(labelIndex, 1);
    // @ts-expect-error
    dispatch(setFieldLabels({
      farmUuid: row.farmUuid,
      fieldUuid: row.fieldUuid,
      labels: updatedLabels,
    }));
  };

  const onHeadCheckboxClick = (selected: boolean) => {
    const uuids = fields.map((field: TransformedField) => field.uuid);

    dispatch(setFieldsSelection({
      uuids,
      selected,
    }));
  };

  const onRowCheckboxClick = (row: DefaultFieldTableRow) => {
    dispatch(toggleFieldSelection(row.id));
  };

  const onRowDeleteClick = (row: DefaultFieldTableRow) => {
    dispatch(openPopup({
      type: 'delete-field',
      entityName: row.name.title,
      onConfirm: () => {
        // @ts-expect-error
        dispatch(deleteFields([{
          farmUuid: row.farmUuid,
          uuid: row.id,
        }]));
      },
    }));
  };

  const onStopNameEdit = (row: DefaultFieldTableRow, name: string) => {
    // @ts-expect-error
    dispatch(renameField({
      fieldUuid: row.id,
      farmUuid: row.farmUuid,
      name,
    }));
  };

  useDidMount(() => {
    dispatch(fetchFields());
  });

  const rows = getFieldsTableRows({
    fields,
    farms: farms || [],
    apiKey,
    checkedUuids: selectedFields,
    onDeleteFieldLabel,
    onAddFieldLabel: openManageLabelsPopup,
  });
  const rowsSelection = getRowsSelection(rows);
  const columns = getTableColumns({
    areaUnit,
    editMode,
    rowsSelection,
    onHeadCheckboxClick,
    onMenuItemClick,
    openManageLabelsPopup,
    onRowCheckboxClick,
    onRowDeleteClick,
    onStopNameEdit,
  });

  return (
    <FieldProfilerTableComponent
      rows={rows}
      columns={columns}
      editMode={editMode}
      loadComplete={!lastEvaluatedKey}
      loading={!isFieldsLoaded || isFarmsFetching || isJdProfileFetching}
      labelsExpanded={labelsExpanded}
      onLoadMoreRows={handleLoadMoreRows}
    />
  );
}
