import React, { Suspense } from 'react';
import { connect } from 'react-redux';

import Stepper from '../../../../../../../components/Stepper';
import Loading from '../../../../../../../components/Loading';
import { setStep } from '../../../../create3dSlice';
import Panel from '../../../Panel/ShapeDataSource';
import { getSteps } from '../../../../helpers/functions/steps';
import { selectShape } from '../../../../create3dSelectors';

const Map = React.lazy(() => import('../../../Map'));

const ShapeDataSource = ({
  nextDisabled,
  onClickNext,
  onClickBack,
}) => {
  const steps = getSteps();

  return (
    <Stepper
      showInactive
      activeStep={1}
      steps={steps}
      nextDisabled={nextDisabled}
      onClickNext={onClickNext}
      onClickBack={onClickBack}
    >
      <Panel key="panel" />
      <Suspense
        key="map"
        fallback={<Loading />}
      >
        <Map />
      </Suspense>
    </Stepper>
  );
};

const mapStateToProps = (state) => {
  const {
    uuid,
    attribute,
  } = selectShape(state);
  const nextDisabled = !uuid || !attribute;

  return {
    nextDisabled,
  };
};

const mapDispatchToProps = (dispatch) => ({
  onClickNext: () => {
    dispatch(setStep('zonesMap'));
  },
  onClickBack: () => {
    dispatch(setStep('shapeDataType'));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ShapeDataSource);
