import { PatchCollection, Recipe } from '@reduxjs/toolkit/dist/query/core/buildThunks';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import { PromiseWithKnownReason } from '@reduxjs/toolkit/dist/query/core/buildMiddleware/types';

import { fieldQueryBuilder } from './graphql/queries/queryBuilder';
import {
  Field,
  GetFieldArg,
  GetFieldsArg,
  GetFieldResponse,
  GetFieldsPageResponse,
  LastEvaluatedKey,
  FieldsPageResult,
} from './types/api';
import { errorNotify } from '../notifications/helpers/functions/notify';
import { FIELD_FRAGMENTS } from './graphql/fragments/field';
import { getSubscriptionObservable } from '../subscription/subscriptionSlice';
import { PlatformEventAction } from '../subscription/helpers/constants/action';
import { LIST_ID, TagType, emptyAPI } from '../emptyApi/emptyAPI';
import {
  CustomError,
  captureException,
} from '../../helpers/functions/utils/errorHandling';

export const allFieldsAPI = emptyAPI.injectEndpoints({
  overrideExisting: false,
  endpoints: (builder) => ({
    getAllFields: builder.query<Field[], GetFieldsArg>({
      queryFn: async (
        {
          fieldFragment,
          areaUnit,
          pageSize,
        },
        { dispatch },
        _extraOptions,
        baseQuery,
      ) => {
        let result: FieldsPageResult;

        const fetchFieldsPage = async (
          lastEvaluatedKey?: LastEvaluatedKey,
        ): Promise<FieldsPageResult> => {
          const { data, error } = await baseQuery({
            document: fieldQueryBuilder('fieldsPage', FIELD_FRAGMENTS[fieldFragment](areaUnit)),
            variables: {
              filter: {
                fieldStatuses: ['TILES_REGISTERED', 'GRIDS_CREATED'],
                lastEvaluatedKey: lastEvaluatedKey ?? null,
                pageSize,
              },
            },
          }) as GetFieldsPageResponse;

          const fieldsPage = data?.getFields || error?.data?.getFields;

          let fieldsPageResult: FieldsPageResult;

          if (fieldsPage) {
            const nextFieldsPage = fieldsPage?.lastEvaluatedKey
              ? await fetchFieldsPage(fieldsPage.lastEvaluatedKey)
              : null;

            fieldsPageResult = nextFieldsPage && 'error' in nextFieldsPage
              ? { error: nextFieldsPage.error }
              : {
                data: [
                  ...fieldsPage.fields,
                  ...(nextFieldsPage && 'data' in nextFieldsPage ? nextFieldsPage.data : []),
                ],
              };
          } else {
            captureException({
              error: new CustomError('[All fields] Unable to fetch all farms fields at once', {
                cause: error,
              }),
            });

            fieldsPageResult = {
              error,
            };
          }

          return fieldsPageResult;
        };

        try {
          result = await fetchFieldsPage();
        } catch (error) {
          errorNotify({
            error,
            dispatch,
          });

          result = {
            error,
          };
        }

        return result;
      },
      providesTags: (result) => {
        return result
          ? [
            ...result.map(({ uuid }) => ({ type: TagType.field, id: uuid })),
            { type: TagType.field, id: LIST_ID },
          ]
          : [{ type: TagType.field, id: LIST_ID }];
      },
      onCacheEntryAdded: handleCacheEntryAdded,
    }),
    getField: builder.query<Field, GetFieldArg>({
      queryFn: async (
        {
          fieldFragment,
          areaUnit,
          farmUuid,
          fieldUuid,
        },
        _api,
        _extraOptions,
        baseQuery,
      ) => {
        let result: {
          data: Field;
        } | {
          error: unknown;
        };

        const response = await baseQuery({
          document: fieldQueryBuilder('single', FIELD_FRAGMENTS[fieldFragment](areaUnit)),
          variables: {
            filter: {
              farmUuid,
              fieldUuid,
            },
          },
        }) as GetFieldResponse;

        if (response.data) {
          result = {
            data: response.data.getFields.fields[0],
          };
        } else {
          result = {
            error: response.error,
          };
        }

        return result;
      },
      providesTags: (_result, _error, { fieldUuid }) => {
        return [{ type: TagType.field, id: fieldUuid }];
      },
    }),

  }),
});

async function handleCacheEntryAdded(
  {
    fieldFragment,
    areaUnit,
  }: GetFieldsArg,
  {
    updateCachedData,
    cacheDataLoaded,
    cacheEntryRemoved,
    dispatch,
  } : {
    updateCachedData: (updateRecipe: Recipe<Field[]>) => PatchCollection,
    cacheDataLoaded: PromiseWithKnownReason<{
      data: Field[];
      meta: {} | undefined;
    }, Error>,
    cacheEntryRemoved: Promise<void>,
    dispatch: ThunkDispatch<any, any, AnyAction>,
  },
) {
  await cacheDataLoaded;

  const subscribe = async () => {
    return (await getSubscriptionObservable()).subscribe({
      next: async ({
        action,
        pathLength,
        farmUuid,
        fieldUuid,
      }) => {
        if (!farmUuid || !fieldUuid || pathLength !== 2) {
          return;
        }

        if (action === PlatformEventAction.insert || action === PlatformEventAction.modify) {
          const actionResult = dispatch(allFieldsAPI.endpoints.getField.initiate({
            fieldFragment,
            areaUnit,
            farmUuid,
            fieldUuid,
          }, { forceRefetch: true }));

          const {
            data: field,
          } = await actionResult;

          if (!field) {
            return;
          }

          try {
            updateCachedData((draft) => {
              const fieldToUpdateIndex = draft.findIndex(({ uuid }) => uuid === fieldUuid);

              if (fieldToUpdateIndex === -1) {
                draft.push(field);
              } else {
                draft[fieldToUpdateIndex] = field;
              }
            });
          } catch {
            // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`
          }
        } else if (action === PlatformEventAction.remove) {
          try {
            updateCachedData((draft) => draft.filter(({ uuid }) => uuid !== fieldUuid));
          } catch {
            // no-op in case `cacheEntryRemoved` resolves before `cacheDataLoaded`
          }
        }
      },
      error: subscribe,
    });
  };

  const subscription = subscribe();

  await cacheEntryRemoved;
  (await subscription).unsubscribe();
}

export const {
  useGetAllFieldsQuery,
} = allFieldsAPI;
