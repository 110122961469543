import React from 'react';
import { connect } from 'react-redux';

import { getEndDate } from '../../../../user/helpers/functions/order';
import { selectLastPaidOrder } from '../../../../user/userSelectors';
import { selectHasRequiredGroups } from '../../applicationShellSelectors';
import {
  getApplicationSubscriptionStatus,
  getPlanSubscriptionWorkflowStatus,
} from '../../helpers/functions/application';
import { isPlanSubscriptionWorkflowEnabled } from '../../../../../helpers/functions/utils/appConfig';

import config from './config';

import './index.scss';

const RestrictedAccessCard = ({ Component }) => {
  return (
    <Component />
  );
};

const mapStateToProps = (state) => {
  const paidOrderEndDate = getEndDate(selectLastPaidOrder(state));
  const hasRequiredGroups = selectHasRequiredGroups(state);

  let page;

  if (isPlanSubscriptionWorkflowEnabled()) {
    page = getPlanSubscriptionWorkflowStatus(paidOrderEndDate);
  } else {
    page = getApplicationSubscriptionStatus(hasRequiredGroups);
  }

  return {
    Component: config[page],
  };
};

export default connect(mapStateToProps)(RestrictedAccessCard);
