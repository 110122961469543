import React from 'react';
import { useTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';

import type { Option } from '../../../../../components/ComboBox';
import PopupHeader from '../../../../../components/Popups/PopupHeader';
import SelectTypeStep from './Steps/01-SelectType';
import SelectFiltersStep from './Steps/02-SelectFilters';
import {
  getAvailableFilterTypeOptions,
  getAvailableFilterPeriodOptions,
  isAvailableFilterPeriodRange,
  isValidFilterForm,
  getAvailableFilterPeriodFromOptions,
  getAvailableFilterPeriodTillOptions,
} from './common';
import { ImportType } from '../../helpers/constants/importType';
import {
  sendCancelWorkflowUsage,
  sendImportFieldsDataFilesUsage,
  sendImportFieldsFieldBoundariesUsage,
  sendSelectImportFiltersPeriodUsage,
  sendSelectImportFiltersTypeUsage,
  sendSelectImportTypeUsage,
} from '../../helpers/functions/analytics';
import { getImportSettings } from './helpers/functions/importSettings';

import './index.scss';

enum Steps {
  selectType = 'select-type',
  selectFilters = 'select-filters',
}

export default function ImportSettingsPopup({
  usedArea,
  maxArea,
  onCancel = () => {},
  onConfirm = () => {},
}: {
  usedArea: number,
  maxArea: number,
  onCancel: () => void,
  onConfirm: (v: ReturnType<typeof getImportSettings>) => void,
}) {
  const { t } = useTranslation();
  const [step, setStep] = React.useState(Steps.selectType);
  const [importType, setImportType] = React.useState(ImportType.fieldBoundaries);
  const [filterType, setFilterType] = React.useState<Option[]>([]);
  // TODO fix option type, when UI reworked
  const [filterPeriod, setFilterPeriod] = React.useState<Option<number | string>[]>([]);
  const [filterPeriodFrom, setFilterPeriodFrom] = React.useState<Option<number> | null>(null);
  const [filterPeriodTill, setFilterPeriodTill] = React.useState<Option<number> | null>(null);

  const goToSelectTypeStep = () => {
    setStep(Steps.selectType);
  };
  const goToSelectFiltersStep = () => {
    sendSelectImportTypeUsage();
    setStep(Steps.selectFilters);
  };

  const onImportTypeChange = (value: ImportType) => {
    setImportType(value);
  };
  const handleCancel = () => {
    sendCancelWorkflowUsage();
    onCancel();
  };
  const handleFilterTypeChange = (newValue: Option[]) => {
    sendSelectImportFiltersTypeUsage();
    setFilterType(newValue);
  };
  const handleFilterPeriodChange = (newValue: Option<number | string>[]) => {
    sendSelectImportFiltersPeriodUsage();
    setFilterPeriod(newValue);
  };
  const handleFilterPeriodFromChange = (newValue: typeof filterPeriodFrom) => {
    setFilterPeriodFrom(newValue);
  };
  const handleFilterPeriodTillChange = (newValue: typeof filterPeriodTill) => {
    setFilterPeriodTill(newValue);
  };

  const filterTypeOptions = getAvailableFilterTypeOptions(filterType, t);
  const filterPeriodOptions = getAvailableFilterPeriodOptions(filterPeriod, t);
  const isAvailablePeriodRange = isAvailableFilterPeriodRange(filterPeriod);
  const isValidForm = isValidFilterForm(filterType, filterPeriod, filterPeriodFrom, filterPeriodTill);
  const filterPeriodFromOptions = getAvailableFilterPeriodFromOptions(filterPeriodTill);
  const filterPeriodTillOptions = getAvailableFilterPeriodTillOptions(filterPeriodFrom);
  const handleGeneralConfirm = () => {
    onConfirm(getImportSettings({
      importType,
      filterType,
      filterPeriod,
      filterPeriodFrom,
      filterPeriodTill,
    }));
  };
  const handleImportFieldBoundaries = () => {
    sendImportFieldsFieldBoundariesUsage();
    handleGeneralConfirm();
  };
  const handleImportDataFiles = () => {
    sendImportFieldsDataFilesUsage();
    handleGeneralConfirm();
  };

  let content = (
    <SelectTypeStep
      type={importType}
      usedArea={usedArea}
      maxArea={maxArea}
      onTypeChange={onImportTypeChange}
      onCancel={handleCancel}
      onNext={goToSelectFiltersStep}
      onConfirm={handleImportFieldBoundaries}
    />
  );

  if (step === Steps.selectFilters) {
    content = (
      <SelectFiltersStep
        filterType={filterType}
        filterTypeOptions={filterTypeOptions}
        filterPeriod={filterPeriod}
        filterPeriodOptions={filterPeriodOptions}
        filterPeriodFrom={filterPeriodFrom}
        filterPeriodFromOptions={filterPeriodFromOptions}
        filterPeriodTill={filterPeriodTill}
        filterPeriodTillOptions={filterPeriodTillOptions}
        isAvailablePeriodRange={isAvailablePeriodRange}
        isValidForm={isValidForm}
        onFilterTypeChange={handleFilterTypeChange}
        onFilterPeriodChange={handleFilterPeriodChange}
        onFilterPeriodFromChange={handleFilterPeriodFromChange}
        onFilterPeriodTillChange={handleFilterPeriodTillChange}
        onBack={goToSelectTypeStep}
        onConfirm={handleImportDataFiles}
      />
    );
  }

  return (
    <Dialog open classes={{ root: 'import-settings-john-deere-popup' }}>
      <PopupHeader
        title={t('general.popups.import-settings-john-deere.title')}
        onCancel={handleCancel}
      />
      {content}
    </Dialog>
  );
}
