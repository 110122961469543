import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@material-ui/core/Typography';

import Link from '../../../../../components/Link';
import WalletIcon from '../Icons/wallet.svg';
import { convertNumberToFormattedString } from '../../../../../helpers/markup';
import { PAGES_ROOTS, getRootLink } from '../../../../../helpers/navigation';

import './index.scss';

export default function CreditsBalance({
  balance,
}: {
  balance?: number,
}) {
  const { t } = useTranslation();
  const formattedBalance = convertNumberToFormattedString(balance?.toFixed(2));

  return (
    <div className="credits-balance">
      <WalletIcon />
      <div className="credits-balance__content">
        <Typography
          variant="h3"
          className="credits-balance__title"
        >
          {t('homepage.credits-balance')}
        </Typography>
        <Typography className="credits-balance__quantity">
          {formattedBalance}
        </Typography>
        <Link to={getRootLink(PAGES_ROOTS.userCreditsUsage)}>
          {t('homepage.view-details')}
        </Link>
      </div>
    </div>
  );
}
