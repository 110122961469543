export enum VectorAnalysisMapType {
  general = 'GENERAL',
  spraying = 'SPRAYING',
  seeding = 'SEEDING',
  fertilizing = 'FERTILIZING',
  irrigation = 'IRRIGATION',
}

export enum AnalysisMapType {
  ramap = 'RAMAP',
  satellite = 'SATELLITE',
  yield = 'YIELD',
  soil = 'SOIL',
  topography = 'TOPOGRAPHY',
  multilayers = 'MULTILAYERS',
  zoneOperations = 'ZONE_OPERATIONS',
  asApplied = 'AS_APPLIED',
  drawn = 'DRAWN',
}

export enum DataClassificationType {
  naturalBreaks = 'NATURAL_BREAKS',
  equalInterval = 'EQUAL_INTERVAL',
  equalCount = 'EQUAL_COUNT',
  spatiallyLocalized = 'SPATIALLY_LOCALIZED',
}

export enum ZonesOperationType {
  intersect = 'INTERSECT',
}

export const MAX_RATES_VALUE = 20000000; // 20 millions
export const MAX_RATE_NAME_LENGTH = 10;
