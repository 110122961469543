import {
  createSlice,
  createAsyncThunk,
} from '@reduxjs/toolkit';
import i18n from 'i18next';

import { singleAtTheTime } from '../../../app/store/mixins/singleAtTheTime';
import { CustomError } from '../../../helpers/functions/utils/errorHandling';
import {
  errorNotify,
  warningNotify,
} from '../../notifications/helpers/functions/notify';
import { deleteAsset, renameAsset } from '../../field/fieldAPI';
import { selectAreaUnit } from '../../user/userSelectors';
import { getUserDataFetcher } from '../applicationShell/applicationShellSlice';
import { MAX_ASSETS_COUNT } from '../downloadFiles/helpers/constants/assets';
import { getFieldsMap, getZonesMapsList } from './zonesMapsAPI';
import {
  selectFilter,
  selectLastEvaluatedKey,
  selectSelectedZonesMaps,
  selectZonesMaps,
} from './zonesMapsSelectors';
import { ZONES_MAPS_BATCH_SIZE } from './helpers/constants/zonesMaps';

const initialState = {
  isLoaded: false,
  zonesMaps: [],
  lastEvaluatedKey: null,
  selectionMode: false,
  labelsExpanded: false,
  filter: {
    farm: null,
    field: null,
    type: null,
    purpose: null,
    labels: [],
    withRates: false,
  },
};

const concatErrors = (zonesMapErrors, fieldErrors) => {
  if (!zonesMapErrors && !fieldErrors) {
    return null;
  }

  return [
    ...(zonesMapErrors || []),
    ...(fieldErrors || []),
  ];
};

export const fetchZonesMapsList = ({
  farm,
  field,
  type,
  purpose,
  labels,
  withRates,
  pageSize,
  lastEvaluatedKey,
  areaUnit,
}) => async (dispatch) => {
  const {
    data: zonesMapsResponseData,
    errors: zonesMapsResponseErrors,
  } = await getZonesMapsList({
    pageSize,
    lastEvaluatedKey,
    farm,
    field,
    type,
    purpose,
    labels,
    withRates,
    areaUnit,
  });

  if (zonesMapsResponseData.zonesMaps.length === 0) {
    return zonesMapsResponseData;
  }

  const fieldUuids = [...new Set(zonesMapsResponseData.zonesMaps.map(({ fieldUuid }) => fieldUuid))];
  const {
    data: {
      fieldsMap,
      farmsNamesMap,
    },
    errors: fieldsMapResponseErrors,
  } = await getFieldsMap(fieldUuids, areaUnit);
  const requestsErrors = concatErrors(zonesMapsResponseErrors, fieldsMapResponseErrors);

  if (requestsErrors) {
    errorNotify({
      error: new CustomError('[Zones Maps] Errors during fetching zones maps list.', {
        cause: requestsErrors,
      }),
      dispatch,
    });
  }

  return {
    ...zonesMapsResponseData,
    zonesMaps: zonesMapsResponseData.zonesMaps
      .filter(({ fieldUuid }) => !!fieldsMap.get(fieldUuid))
      .map((zonesMap) => {
        const fld = fieldsMap.get(zonesMap.fieldUuid);

        return {
          ...zonesMap,
          farmUuid: fld.farmUuid,
          farmName: farmsNamesMap.get(fld.farmUuid),
          fieldName: fld.name,
          fieldArea: fld.area,
          labels: fld.labels,
          pins: (fld.notes || []).filter((pin) => pin.vectorAnalysisMap?.uuid === zonesMap.uuid),
        };
      }),
  };
};

export const tryTogglePageRowsSelection = (checked) => (dispatch, getState) => {
  if (checked) {
    const state = getState();
    const zonesMaps = selectZonesMaps(state);

    if (zonesMaps.length > MAX_ASSETS_COUNT) {
      warningNotify({
        message: i18n.t('export.notifications.max-assets-reached'),
      });

      return;
    }
  }

  dispatch(togglePageRowsSelection(checked));
};

export const tryToggleRowSelection = (uuid) => (dispatch, getState) => {
  const state = getState();
  const selectedZonesMaps = selectSelectedZonesMaps(state);
  const zonesMap = selectedZonesMaps.find((map) => map.uuid === uuid);

  if (!zonesMap && selectedZonesMaps.length + 1 > MAX_ASSETS_COUNT) {
    warningNotify({
      message: i18n.t('export.notifications.max-assets-reached'),
    });

    return;
  }

  dispatch(toggleRowSelection(uuid));
};

export const updateFilters = singleAtTheTime(createAsyncThunk(
  'zonesMaps/updateFilters',
  async (_payload, { getState, dispatch }) => {
    await getUserDataFetcher();

    const state = getState();

    return dispatch(fetchZonesMapsList({
      ...selectFilter(state),
      pageSize: ZONES_MAPS_BATCH_SIZE,
      lastEvaluatedKey: selectLastEvaluatedKey(state),
      areaUnit: selectAreaUnit(state),
    }));
  },
  {
    condition: (payload, { getState }) => {
      if (JSON.stringify(payload) === JSON.stringify(selectFilter(getState()))) {
        return false;
      }
    },
  },
));

export const loadMoreZonesMaps = singleAtTheTime(createAsyncThunk(
  'zonesMaps/loadMoreZonesMaps',
  async (_payload, { getState, dispatch }) => {
    const state = getState();

    try {
      return dispatch(fetchZonesMapsList({
        ...selectFilter(state),
        pageSize: ZONES_MAPS_BATCH_SIZE,
        lastEvaluatedKey: selectLastEvaluatedKey(state),
        areaUnit: selectAreaUnit(state),
      }));
    } catch (error) {
      errorNotify({
        error: new CustomError('[Fields] Unable to load more zones maps.', {
          cause: error,
        }),
        dispatch,
      });
    }
  },
  {
    condition: (_payload, { getState }) => {
      const state = getState();
      const lastEvaluatedKey = selectLastEvaluatedKey(state);

      return !!lastEvaluatedKey;
    },
  },
));

export const renameZonesMap = createAsyncThunk(
  'zonesMaps/renameZonesMap',
  ({
    asset,
    fieldUuid,
    name,
  }, { dispatch }) => {
    return renameAsset(asset, name, fieldUuid)
      .then(() => {
        return {
          uuid: asset.uuid,
          name,
        };
      })
      .catch((error) => {
        errorNotify({
          error: new CustomError('[Zones Maps] Unable to rename zones map.', {
            cause: error,
          }),
          dispatch,
        });
      });
  },
);

export const deleteZonesMap = createAsyncThunk(
  'zonesMaps/deleteZonesMap',
  ({
    asset,
    fieldUuid,
  }, { dispatch }) => {
    return deleteAsset(asset, fieldUuid)
      .then(() => {
        return dispatch(updateFilters());
      });
  },
);

export const zonesMapsSlice = createSlice({
  name: 'zonesMaps',
  initialState,
  reducers: {
    reset() {
      return initialState;
    },
    switchSelectionMode(state) {
      const selectionMode = !state.selectionMode;

      state.selectionMode = selectionMode;
      if (!selectionMode) {
        state.zonesMaps = state.zonesMaps.map((zonesMap) => {
          return {
            ...zonesMap,
            _selected: false,
          };
        });
      }
    },
    setLabelsExpanded(state, action) {
      state.labelsExpanded = action.payload;
    },
    toggleRowSelection(state, action) {
      state.zonesMaps = state.zonesMaps.map((zonesMap) => {
        if (zonesMap.uuid === action.payload) {
          return {
            ...zonesMap,
            _selected: !zonesMap._selected,
          };
        }

        return zonesMap;
      });
    },
    togglePageRowsSelection(state, action) {
      const startInd = state.page * state.pageSize;
      const endInd = startInd + state.pageSize;

      state.zonesMaps = state.zonesMaps.map((zonesMap, ind) => {
        if (startInd > ind || ind >= endInd) {
          return zonesMap;
        }

        return {
          ...zonesMap,
          _selected: action.payload,
        };
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(renameZonesMap.fulfilled, (state, action) => {
        state.isLoaded = true;

        if (action.payload) {
          state.zonesMaps = state.zonesMaps.map((zonesMap) => {
            if (zonesMap.uuid === action.payload.uuid) {
              return {
                ...zonesMap,
                name: action.payload.name,
              };
            }

            return zonesMap;
          });
        }
      })
      .addCase(updateFilters.pending, (state, action) => {
        const newFilter = action.meta.arg;

        state.isLoaded = false;
        state.filter = {
          ...state.filter,
          ...(newFilter || {}),
        };
        state.lastEvaluatedKey = null;
        state.page = 0;
      })
      .addCase(loadMoreZonesMaps.fulfilled, (state, action) => {
        state.lastEvaluatedKey = action.payload.lastEvaluatedKey;
        state.zonesMaps = [
          ...state.zonesMaps,
          ...action.payload.zonesMaps,
        ];
      })
      .addCase(updateFilters.fulfilled, (state, action) => {
        state.isLoaded = true;

        if (action.payload) {
          state.zonesMaps = action.payload.zonesMaps;
          state.lastEvaluatedKey = action.payload.lastEvaluatedKey;
        }
      })
      .addMatcher(
        ({ type }) => {
          return type === deleteZonesMap.pending.type
            || type === renameZonesMap.pending.type;
        },
        (state) => {
          state.isLoaded = false;
        },
      );
  },
});

export const {
  reset,
  switchSelectionMode,
  setLabelsExpanded,
  togglePageRowsSelection,
  toggleRowSelection,
} = zonesMapsSlice.actions;

export default zonesMapsSlice.reducer;
