import app from '../../../../public/configs/app.json';

export const getBrandName = () => {
  return app.brand.shortName;
};

export const getBrandFullName = () => {
  return app.brand.fullName;
};

export const getHelpLink = () => {
  return app.links.help;
};

export const getContactsLink = () => {
  return app.links.contacts;
};

export const getPolicyLink = () => {
  return app.links.policy;
};

export const getTermsLink = () => {
  return app.links.terms;
};

export const getFAQLink = () => {
  return app.links.faq;
};

export const getPalette = () => {
  return app.palette;
};

export const isPlanSubscriptionWorkflowEnabled = () => {
  return app.features.planSubscription.enabled;
};

export const isJohnDeereImportWorkflowEnabled = () => {
  return app.features.jd.import.enabled;
};

export const isJohnDeereExportWorkflowEnabled = () => {
  return app.features.jd.export.enabled;
};

export const isJohnDeereWorkflowEnabled = () => {
  return isJohnDeereImportWorkflowEnabled()
   && isJohnDeereExportWorkflowEnabled();
};

export const isJohnDeereQuickStartEnabled = () => {
  return app.features.jd.quickStart.enabled;
};

export const getFieldStabilityBlogPostLink = () => {
  return app.links.fieldStabilityBlogPost;
};

export const getDataCompatibilityBlogPostLink = () => {
  return app.links.dataCompatibilityBlogPost;
};

export const getAuthenticationRequiredGroups = () => {
  return app.features.authentication.requiredGroups;
};

export const isApplicationShellReportIssueEnabled = () => {
  return app.features.applicationShell.withReportIssue;
};

export const isSignUpEnabled = () => {
  return app.features.authentication.withSignUp;
};

export const getExportFilePostfix = () => {
  return app.features.export.filePostfix;
};

export const getExportRatesOnlyDefaultValue = () => {
  return app.features.export.ratesOnly.defaultValue;
};

export const getExportRatesOnlyShowUI = () => {
  return app.features.export.ratesOnly.showUI;
};

export const getKeyValueDBName = () => {
  return app.storage.keyValueDBName;
};

export const getNewUserKey = () => {
  return app.storage.newUserKey;
};

export const getPlanSubscriptionKey = () => {
  return app.storage.planSubscriptionKey;
};

export const getAvailableLanguages = () => {
  return app.availableLanguages;
};

export const getEquationsAdditionalCategories = () => {
  return app.features.equations.additionalCategories;
};

export const isPdfReportsEnabled = () => {
  return app.features.reports.pdf.enabled;
};

export const isFieldWorkflowAssetsGroupingEnabled = () => {
  return app.features.fieldWorkflow.assetsGrouping.enabled;
};

export const isBingMapsEnabled = () => {
  return app.features.maps.bing.enabled;
};

export const isProductFruitsEnabled = () => {
  return app.features.productFruits.enabled;
};
