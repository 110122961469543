import React, { ReactElement } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import IconButton from '@material-ui/core/IconButton';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import HelpOutlineSharpIcon from '@material-ui/icons/HelpOutlineSharp';
import BugReportIcon from '@material-ui/icons/BugReport';
import ExitToAppSharpIcon from '@material-ui/icons/ExitToAppSharp';

import { getHelpLink } from '../../../../../helpers/functions/utils/appConfig';
import Button from '../../../../../components/Button';

import './index.scss';

export default function AppShellHeader({
  withReportIssue,
  text,
  secondaryText,
  backUrl,
  onLogOutClick,
  onReportIssueClick,
  onBackButtonClick,
}: {
  withReportIssue: boolean,
  text: ReactElement,
  secondaryText: ReactElement,
  backUrl?: string,
  onLogOutClick: () => void,
  onReportIssueClick: () => void,
  onBackButtonClick?: () => void,
}) {
  const { t } = useTranslation();

  return (
    <div className="app-shell-header">
      {
        backUrl
          && (
            <IconButton
              component={Link}
              to={backUrl}
              classes={{
                root: 'app-shell-header__back-button',
              }}
            >
              <ArrowBackIcon />
            </IconButton>
          )
      }
      {
        onBackButtonClick
          && (
            <IconButton
              classes={{
                root: 'app-shell-header__back-button',
              }}
              onClick={onBackButtonClick}
            >
              <ArrowBackIcon />
            </IconButton>
          )
      }
      <div className="app-shell-header__container">
        <span className="app-shell-header__text">
          {text}
          <span className="app-shell-header__text_secondary">
            {secondaryText}
          </span>
        </span>
        <div className="app-shell-header__buttons">
          {
          withReportIssue
            && (
              <Button
                className="app-shell-header__button"
                startIcon={<BugReportIcon className="app-shell-header__button-icon" />}
                onClick={onReportIssueClick}
              >
                {t('general.navigation.report-issue')}
              </Button>
            )
          }
          <IconButton
            component="a"
            href={getHelpLink()}
            target="_blank"
            className="app-shell-header__icon-button"
            title={t('general.navigation.tutorial')}
          >
            <HelpOutlineSharpIcon />
          </IconButton>
          <IconButton
            onClick={onLogOutClick}
            className="app-shell-header__icon-button"
            title={t('general.navigation.log-out')}
          >
            <ExitToAppSharpIcon />
          </IconButton>
        </div>
      </div>
    </div>
  );
}
