import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import AuthenticationRedirector from '../routes/redirectors/Authentication';
import PricingRedirector from '../routes/redirectors/Pricing';
import ToolsPanel from '../features/ui/userSettings/containers/ToolsPanel';
import BackdropLoading from '../components/Loading/BackdropLoading';
import Panel from '../features/ui/userSettings/containers/Panel';
import { PAGES_ROOTS } from '../helpers/navigation';
import { getDocumentTitle } from '../helpers';
import BaseTemplate from './BaseTemplate.jsx';
import useDidMount from '../hooks/useDidMount';
import { selectIsLoaded } from '../features/user/userSelectors';
import { selectIsProcessing } from '../features/ui/userSettings/userSettingsSelectors';
import { selectIsLoaded as selectIsColorSchemasLoaded } from '../features/colorSchemas/colorSchemasSelectors';

const UserSettings = () => {
  const { t } = useTranslation();
  const isUserLoaded = useSelector(selectIsLoaded);
  const isSettingsProcessing = useSelector(selectIsProcessing);
  const isColorSchemasLoaded = useSelector(selectIsColorSchemasLoaded);
  const isLoaded = isUserLoaded
    && isColorSchemasLoaded
    && !isSettingsProcessing;

  useDidMount(() => {
    document.title = getDocumentTitle(t('general.navigation.settings'));
  });

  return (
    <AuthenticationRedirector>
      <PricingRedirector>
        <BaseTemplate
          route={PAGES_ROOTS.userSettings}
          headerProps={{
            text: t('general.shared.settings'),
          }}
        >
          <ToolsPanel key="tools-panel"/>
          <Panel key="panel" />
          { !isLoaded && <BackdropLoading key="loading" /> }
        </BaseTemplate>
      </PricingRedirector>
    </AuthenticationRedirector>
  );
};

export default UserSettings;
