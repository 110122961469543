import React, {
  ReactElement,
  useCallback,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import usePricingTable from '../../hooks/usePricingTable';
import PricingTable from '../../components/PricingTable';
import { hasSuccessParam } from '../../helpers/functions/navigation';
import WelcomePanel from '../../components/WelcomePanel';
import BackdropLoading from '../../../../../components/Loading/BackdropLoading';
import { getUserData } from '../../../../user/userSlice';
import { selectApplicationStatus } from '../../../applicationShell/applicationShellSelectors';
import { APPLICATION_STATUSES } from '../../../applicationShell/helpers/constants/application';
import useInterval from '../../../../../hooks/useInterval';
import { selectAreaUnit } from '../../../../user/userSelectors';
import PricingTableToggle from '../../components/PricingTableToggle';
import { transformAreaUnitToPricingTableType } from '../../helpers/functions/pricingTableType';

const USER_DATA_POLL_INTERVAL = 2000;

export default function PlanSubscriptionPanel() {
  const dispatch = useDispatch();
  const location = useLocation();
  const areaUnit = useSelector(selectAreaUnit);
  const subscriptionSuccess = hasSuccessParam(location.search);
  const applicationStatus = useSelector(selectApplicationStatus);

  const [pricingTableType, setPricingTableType] = useState(transformAreaUnitToPricingTableType(areaUnit));
  const pricingTable = usePricingTable(pricingTableType);

  // user data doesn't updated immediately, so we need to poll BE
  const poller = useCallback((id) => {
    if (!subscriptionSuccess) {
      clearInterval(id);
      return;
    }

    if (applicationStatus !== APPLICATION_STATUSES.AVAILABLE) {
      dispatch(getUserData());
    } else {
      clearInterval(id);
    }
  }, [dispatch, applicationStatus, subscriptionSuccess]);

  useInterval(poller, USER_DATA_POLL_INTERVAL);

  let result: ReactElement;

  if (subscriptionSuccess) {
    result = applicationStatus === APPLICATION_STATUSES.AVAILABLE
      ? <WelcomePanel />
      : <BackdropLoading />;
  } else {
    result = (
      <PricingTable>
        <PricingTableToggle
          pricingTableType={pricingTableType}
          onPricingTableTypeChange={setPricingTableType}
        />
        {pricingTable}
      </PricingTable>
    );
  }

  return result;
}
