import ShapeDataType from './Steps/ShapeDataType';
import ShapeDataSource from './Steps/ShapeDataSource';
import ZonesMap from './Steps/ZonesMap';
import Preview from './Steps/Preview';

const config = {
  shapeDataType: ShapeDataType,
  shapeDataSource: ShapeDataSource,
  zonesMap: ZonesMap,
  preview: Preview,
};

export default config;
