import i18n from 'i18next';

import { getRootLink, PAGES_ROOTS } from '../../../../../helpers/navigation';
import { NAME } from '../../../jdIntegration/helpers/constants';
import { getIntegrationUrl } from './navigation';

export const getHeaderProps = (pathname: string) => {
  let result: {
    text: string,
    backUrl?: string,
  } = {
    text: i18n.t('general.navigation.integrations'),
  };

  if (pathname.startsWith(getIntegrationUrl('jd'))) {
    result = {
      text: getIntegrationTitle(NAME),
      backUrl: getRootLink(PAGES_ROOTS.userIntegrations),
    };
  }

  return result;
};

export const getIntegrationTitle = (name: string) => {
  return `${name} ${i18n.t('integrations.marketplace.integration')}`;
};
