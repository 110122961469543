import { createElement } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../../../app/store/helpers/functions';
import {
  getAcresPricingTableId,
  getHectaresPricingTableId,
  getPubKey,
  isTestPricing,
} from '../../../../helpers/functions/utils/vendorConfigs/stripe';
import useNotify from '../../../notifications/hooks/useNotify';
import { warningNotify } from '../../../notifications/helpers/functions/notify';
import { PricingTableType } from '../helpers/constants';
import { selectUuid } from '../../../user/userSelectors';

export default function usePricingTable(pricingTableType: PricingTableType) {
  const { t } = useTranslation();
  const testPricing = isTestPricing();
  const userUuid = useAppSelector(selectUuid);

  useNotify({
    condition: testPricing,
    notifyOnce: true,
    notifier: () => warningNotify({
      message: t('plan-subscription.notifications.test-pricing'),
    }),
  });

  return createElement(
    'stripe-pricing-table',
    {
      'pricing-table-id': pricingTableType === PricingTableType.hectares
        ? getHectaresPricingTableId()
        : getAcresPricingTableId(),
      'publishable-key': getPubKey(),
      'client-reference-id': userUuid,
    },
  );
}
