import { readEnvVariable } from '.';

export const getAcresPricingTableId = () => {
  return readEnvVariable('STRIPE_AC_PRICING_TABLE_ID');
};

export const getHectaresPricingTableId = () => {
  return readEnvVariable('STRIPE_HA_PRICING_TABLE_ID');
};

export const getPubKey = () => {
  return readEnvVariable('STRIPE_PUB_KEY');
};

export const isTestPricing = () => {
  const pubKey = getPubKey();

  return pubKey.includes('test');
};

export const getCustomerPortalLink = () => {
  return readEnvVariable('STRIPE_CUSTOMER_PORTAL_LINK');
};
