import React, { Component } from 'react';
import clsx from 'clsx';
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import ClearIcon from '@material-ui/icons/Clear';
import SearchIcon from '@material-ui/icons/Search';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import Tooltip from '../Tooltip';

import './index.scss';

class EnhancedTextField extends Component {
  id = Math.random();

  prepareValue = (e) => {
    const {
      InputProps = {},
    } = this.props;

    const value = parseInt(e.target.value, 10);
    const min = InputProps.inputProps && InputProps.inputProps.min;
    const max = InputProps.inputProps && InputProps.inputProps.max;

    if (min != null && value < min) {
      e.target.value = min;
    }

    if (max != null && value > max) {
      e.target.value = max;
    }

    return e;
  };

  getEndAdornment = () => {
    const {
      type = 'text',
      endAdornment,
      onEndAdornmentClick = () => {},
      ...props
    } = this.props;

    if (type === 'search') {
      return (
        <InputAdornment
          position="end"
          classes={{
            root: clsx('textfield__input__clear-icon', {
              'textfield__input__clear-icon-visible': (props.value || '').length,
            }),
          }}
        >
          <IconButton
            size="small"
            onClick={onEndAdornmentClick}
          >
            <ClearIcon fontSize="small" />
          </IconButton>
        </InputAdornment>
      );
    }

    if (endAdornment) {
      return (
        <InputAdornment position="end">
          {endAdornment}
        </InputAdornment>
      );
    }

    return null;
  };

  render() {
    const {
      placeholder = '',
      required = false,
      title = '',
      classes = {},
      InputProps = {},
      variant = 'default',
      type = 'text',
      endAdornment,
      infoTooltip,
      helperText,
      HelperTextProps = {},
      onChange = () => {},
      onEndAdornmentClick,
      ...props
    } = this.props;
    const {
      wrapper: wrapperClass,
      title: titleClass,
      tooltipLabel: tooltipLabelClass,
      tooltip: tooltipClass,
      ...otherClasses
    } = classes;
    const {
      classes: inputPropsClasses,
      ...otherInputProps
    } = InputProps;
    const {
      root: rootInputPropsClass = '',
      input: inputInputPropsClass = '',
      notchedOutline: nothedOutlineInputPropsClass = '',
      marginDense: marginDenseInputPropsClass = '',
      adornedStart: adornedStartInputPropsClass = '',
      error: errorInputPropsClass = '',
      ...otherInputPropsClasses
    } = inputPropsClasses || {};

    return (
      <div className={clsx('textfield__wrapper', wrapperClass)}>
        {
          title
          && (
            <label
              htmlFor={`textfield_${this.id}`}
              className={clsx('textfield__label', titleClass)}
            >
              {title}
              {required && <span className="required-asterisk"> *</span>}
              {
                infoTooltip
                  && (
                    <Tooltip
                      classes={{
                        tooltip: tooltipClass,
                        label: clsx('textfield__tooltip-label', tooltipLabelClass),
                      }}
                      tooltip={infoTooltip}
                    >
                      <InfoOutlinedIcon fontSize="small" />
                    </Tooltip>
                  )
              }
            </label>
          )
        }
        <TextField
          error={!!helperText}
          helperText={helperText}
          id={`textfield_${this.id}`}
          variant="outlined"
          size="small"
          placeholder={placeholder}
          type={type}
          classes={{
            root: `textfield ${variant === 'small' ? 'textfield_small' : ''}`,
            ...otherClasses,
          }}
          InputProps={{
            classes: {
              root: clsx('textfield__input-root', rootInputPropsClass),
              input: clsx('textfield__input-input', inputInputPropsClass),
              notchedOutline: clsx('textfield__outline', nothedOutlineInputPropsClass),
              marginDense: clsx('textfield__input-root_margin-dense', marginDenseInputPropsClass),
              adornedStart: clsx('textfield__input_adorned-start', adornedStartInputPropsClass),
              error: clsx('textfield__input-root_error', errorInputPropsClass),
              ...otherInputPropsClasses,
            },
            startAdornment: type === 'search'
              ? (
                <SearchIcon className="textfield__input__search-icon"/>
              )
              : null,
            endAdornment: this.getEndAdornment(),
            ...otherInputProps,
          }}
          FormHelperTextProps={HelperTextProps}
          onChange={(e) => onChange(this.prepareValue(e))}
          {...props}
        />
      </div>
    );
  }
}

export default EnhancedTextField;
