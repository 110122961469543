export const selectAssetType = ({ create3d }) => create3d.assetType;

export const selectShape = ({ create3d }) => create3d.shape;

export const selectZonesMapUuid = ({ create3d }) => create3d.zonesMapUuid;

export const selectStep = ({ create3d }) => create3d.step;

export const selectLoading = ({ create3d }) => create3d.loading;

export const selectSettings = ({ create3d }) => create3d.settings;
