import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Legend from '../../../../../components/Legend';
import AssetLegendContent from '../../../../../components/Legend/AssetLegendContent';
import { selectAreaUnit } from '../../../../user/userSelectors';
import { sendFeatureUsage } from '../../../../../helpers/analytics';
import { PAGES_ROOTS } from '../../../../../helpers/navigation';
import { resetLegendJohnDeere } from '../../jdExportSlice';
import { selectLegendZonesMap } from '../../jdExportSelectors';

const ExportDataLegend = ({
  isOpen,
  loading,
  legend,
  field,
  apiKey,
  areaUnit,
  onLegendClose,
}) => {
  const type = (legend || {})._type;

  useEffect(() => {
    if (!type) {
      return;
    }

    sendFeatureUsage('legend', 'view', {
      type,
      screen: PAGES_ROOTS.exportDataJohnDeere.compareLayers,
    });
  }, [type]);

  if (!isOpen) {
    return null;
  }

  return (
    <Legend
      loading={loading}
      title={legend.name}
      content={(
        <AssetLegendContent
          item={legend}
          field={field}
          apiKey={apiKey}
          areaUnit={areaUnit}
        />
      )}
      onClose={onLegendClose}
    />
  );
};

const mapStateToProps = (state) => {
  const zonesMap = selectLegendZonesMap(state);
  const { field } = zonesMap || {};

  return {
    isOpen: !!zonesMap,
    loading: !field,
    legend: zonesMap,
    field: field || {},
    areaUnit: selectAreaUnit(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLegendClose: () => {
    dispatch(resetLegendJohnDeere());
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportDataLegend);
