import React, { ReactElement } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import AppShellHeaderComponent from '../../components/Header';
import { signOut } from '../../../../user/userSlice';
import {
  isApplicationShellReportIssueEnabled,
} from '../../../../../helpers/functions/utils/appConfig';
import { openPopup } from '../../../popups/popupsSlice';
import { POPUPS } from '../../../popups/helpers/constants/popups';
import { successNotify } from '../../../../notifications/helpers/functions/notify';

export default function AppShellHeader({
  text,
  secondaryText,
  backUrl,
  onBackButtonClick,
}: {
  text: ReactElement,
  secondaryText: ReactElement,
  backUrl: string,
  onBackButtonClick: () => void,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const handleSignOutClick = () => {
    dispatch(signOut());
  };

  const handleReportIssueClick = () => {
    dispatch(openPopup({
      type: POPUPS.reportIssue,
      onConfirm: () => {
        successNotify({
          message: t('general.notifications.report-issue-success'),
        });
      },
    }));
  };

  return (
    <AppShellHeaderComponent
      withReportIssue={isApplicationShellReportIssueEnabled()}
      text={text}
      secondaryText={secondaryText}
      backUrl={backUrl}
      onBackButtonClick={onBackButtonClick}
      onLogOutClick={handleSignOutClick}
      onReportIssueClick={handleReportIssueClick}
    />
  );
}
