import { useDispatch } from 'react-redux';

import { resetToSelectedField as resetZonesOpsToSelectedField } from '../zonesOpsSlice';
import { reset as resetCreateAnalysis } from '../../../createAnalysis/createAnalysisSlice';

export default function useCreateAnotherAnalysis() {
  const dispatch = useDispatch();

  return () => {
    dispatch(resetZonesOpsToSelectedField());
    dispatch(resetCreateAnalysis());
  };
}
