import React, {
  useEffect,
  useMemo,
  useState,
} from 'react';
import IconButton from '@material-ui/core/IconButton';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import DoubleChevronRightIcon from '../Icons/doubleChevronRight.svg';
import DoubleChevronLeftIcon from '../Icons/doubleChevronLeft.svg';
import ListPanel from '../../features/labels/components/ListPanel';
import type { ButtonMenuItem } from '../ButtonMenu/types';
import type { ListPanelItem } from '../../features/labels/components/ListPanel/types';

import './index.scss';

export default function ItemsPicker({
  availableItemsTitle,
  addedItemsTitle,
  addAvailableItemButtonLabel,
  emptyAddedItemsLabel,
  items,
  addedItemsValues,
  availableItemMenuItems,
  required = false,
  onAddItemClick,
  onAvailableItemMenuItemClick,
  onAddedItemsChange,
}: {
  availableItemsTitle: string,
  addedItemsTitle: string,
  addAvailableItemButtonLabel?: string,
  emptyAddedItemsLabel?: string;
  items: ListPanelItem[],
  addedItemsValues: Set<string>,
  availableItemMenuItems?: ButtonMenuItem[],
  required?: boolean,
  onAddItemClick?: () => void,
  onAvailableItemMenuItemClick?: (m: ButtonMenuItem, i: ListPanelItem, ind: number) => void,
  onAddedItemsChange: (i: Set<string>) => void,
}) {
  const [availableItemsValues, setAvailableItemsValues] = useState<Set<string>>(new Set());
  const [availableActiveItemsValues, setAvailableActiveItemsValues] = useState<Set<string>>(new Set());
  const [addedActiveItemsValues, setAddedActiveItemsValues] = useState<Set<string>>(new Set());

  const handleAvailableItemClick = (_e: unknown, item: ListPanelItem) => {
    setAvailableActiveItemsValues(() => {
      const itemsSet = new Set<string>(availableActiveItemsValues);

      if (itemsSet.has(item.value)) {
        itemsSet.delete(item.value);
      } else {
        itemsSet.add(item.value);
      }

      return itemsSet;
    });
  };

  const handleAddedItemClick = (_e: unknown, item: ListPanelItem) => {
    setAddedActiveItemsValues(() => {
      const itemsSet = new Set<string>(addedActiveItemsValues);

      if (itemsSet.has(item.value)) {
        itemsSet.delete(item.value);
      } else {
        itemsSet.add(item.value);
      }

      return itemsSet;
    });
  };

  const handleAddAllClick = () => {
    const itemsToAdd = new Set<string>();

    items.forEach((item) => {
      if (availableItemsValues.has(item.value)) {
        itemsToAdd.add(item.value);
      }
    });

    onAddedItemsChange(new Set([
      ...addedItemsValues,
      ...itemsToAdd,
    ]));
    setAvailableItemsValues(new Set(
      [...availableItemsValues].filter((availableItem) => !itemsToAdd.has(availableItem)),
    ));
    setAvailableActiveItemsValues(new Set());
  };

  const handleAddItemsClick = () => {
    onAddedItemsChange(new Set([
      ...addedItemsValues,
      ...availableActiveItemsValues,
    ]));
    setAvailableItemsValues(new Set(
      [...availableItemsValues].filter((availableItem) => !availableActiveItemsValues.has(availableItem)),
    ));
    setAvailableActiveItemsValues(new Set());
  };

  const handleRemoveItemsClick = () => {
    setAvailableItemsValues(new Set([
      ...availableItemsValues,
      ...addedActiveItemsValues,
    ]));
    onAddedItemsChange(new Set(
      [...addedItemsValues].filter((addedItem) => !addedActiveItemsValues.has(addedItem)),
    ));
    setAddedActiveItemsValues(new Set());
  };

  const handleRemoveAllClick = () => {
    const itemsToAdd = new Set<string>();

    items.forEach((item) => {
      if (addedItemsValues.has(item.value)) {
        itemsToAdd.add(item.value);
      }
    });

    setAvailableItemsValues(new Set([
      ...availableItemsValues,
      ...itemsToAdd,
    ]));
    onAddedItemsChange(new Set(
      [...addedItemsValues].filter((addedItem) => !itemsToAdd.has(addedItem)),
    ));
    setAddedActiveItemsValues(new Set());
  };

  const availableItems = useMemo(() => {
    return items.filter(({ value }) => availableItemsValues.has(value));
  }, [availableItemsValues, items]);

  const addedItems = useMemo(() => {
    return items.filter(({ value }) => addedItemsValues.has(value));
  }, [addedItemsValues, items]);

  useEffect(() => {
    const itemsValues = new Set<string>();

    items.forEach((item) => {
      if (!addedItemsValues.has(item.value)) {
        itemsValues.add(item.value);
      }
    });

    setAvailableItemsValues(itemsValues);
  }, [items, addedItemsValues]);

  return (
    <div className="items-picker">
      <ListPanel
        title={availableItemsTitle}
        addButtonLabel={addAvailableItemButtonLabel}
        items={availableItems}
        activeItemsValues={availableActiveItemsValues}
        menuItems={availableItemMenuItems}
        onItemClick={handleAvailableItemClick}
        onAddClick={onAddItemClick}
        onMenuItemClick={onAvailableItemMenuItemClick}
      />
      <div className="items-picker__controls">
        <IconButton
          color="primary"
          classes={{
            root: 'items-picker__controls-button',
            label: 'items-picker__controls-button-label',
          }}
          onClick={handleAddAllClick}
          disabled={availableItemsValues.size === 0}
        >
          <DoubleChevronRightIcon />
        </IconButton>
        <IconButton
          color="primary"
          classes={{
            root: 'items-picker__controls-button',
          }}
          onClick={handleAddItemsClick}
          disabled={availableActiveItemsValues.size === 0}
        >
          <ChevronRightIcon />
        </IconButton>
        <IconButton
          color="primary"
          classes={{
            root: 'items-picker__controls-button',
          }}
          onClick={handleRemoveItemsClick}
          disabled={addedActiveItemsValues.size === 0}
        >
          <ChevronLeftIcon />
        </IconButton>
        <IconButton
          color="primary"
          classes={{
            root: 'items-picker__controls-button',
            label: 'items-picker__controls-button-label',
          }}
          onClick={handleRemoveAllClick}
          disabled={addedItemsValues.size === 0}
        >
          <DoubleChevronLeftIcon />
        </IconButton>
      </div>
      <ListPanel
        title={addedItemsTitle}
        emptyAddedItemsLabel={emptyAddedItemsLabel}
        items={addedItems}
        activeItemsValues={addedActiveItemsValues}
        required={required}
        onItemClick={handleAddedItemClick}
      />
    </div>
  );
}
