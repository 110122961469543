import React from 'react';
import { withTranslation } from 'react-i18next';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';

import PopupHeader from '../PopupHeader';
import Button from '../../Button';
import TextField from '../../TextField';

import './index.scss';

const DeleteFields = ({
  t,
  fieldNames = [],
  onCancel = () => {},
  onConfirm = () => {},
}) => {
  const confirmWord = t('general.popups.delete-confirm-word');
  const [confirmButtonDisabled, setConfirmButtonDisabled] = React.useState(true);

  const handleConfirmInputChange = (e) => {
    setConfirmButtonDisabled(e.target.value !== confirmWord);
  };

  return (
    <Dialog open>
      <PopupHeader
        title={t('general.popups.delete-fields.title')}
        onCancel={onCancel}
      />
      <DialogContent>
        <DialogContentText
          component="div"
          classes={{
            root: 'delete-fields-popup__content-text',
          }}
        >
          <span>
            {t('general.popups.delete-fields.description', { confirmWord })}
          </span>
          <ul className="delete-fields-popup__fields-list">
            {
              fieldNames.map((name, fieldIndex) => (
                <li key={fieldIndex}>- {name}</li>
              ))
            }
          </ul>
        </DialogContentText>
        <TextField
          placeholder={confirmWord}
          onChange={handleConfirmInputChange}
        />
      </DialogContent>
      <DialogActions
        classes={{
          root: 'delete-field-popup__actions',
        }}
      >
        <Button
          onClick={onCancel}
          variant="outlined"
        >
          {t('general.controls.cancel')}
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={confirmButtonDisabled}
          onClick={onConfirm}
        >
          {t('general.controls.delete')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withTranslation()(DeleteFields);
