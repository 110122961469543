import { API, graphqlOperation } from '@aws-amplify/api';

import { getFieldsLabelsMap } from './helpers/functions/labels';
import { VALID_FIELD_STATUSES } from '../field/helpers/constants/field';
import getFieldsQuery from './graphql/queries/getFields.gql';
import deleteFieldsMutation from './graphql/mutations/deleteFields.gql';
import addFieldsLabelsMutation from './graphql/mutations/addFieldsLabels.gql';
import deleteFieldsLabelsMutation from './graphql/mutations/deleteFieldsLabels.gql';

export const getFields = async ({
  farmUuid,
  pageSize,
  filter,
  lastEvaluatedKey,
  areaUnit,
}) => {
  try {
    const response = await API.graphql(graphqlOperation(getFieldsQuery, {
      filter: {
        farmUuid,
        pageSize,
        labels: filter.labels,
        fieldName: filter.name,
        lastEvaluatedKey,
        fieldStatuses: VALID_FIELD_STATUSES,
      },
      areaUnit,
    }));

    return response.data.getFields;
  } catch (error) {
    if (error.data) {
      return error.data.getFields;
    }
    throw error;
  }
};

export const deleteFields = async (fields) => {
  return API.graphql(graphqlOperation(deleteFieldsMutation, { input: fields }));
};

export const addFieldsLabels = async (fieldsLabels) => {
  return API.graphql(graphqlOperation(addFieldsLabelsMutation, {
    input: {
      fields: fieldsLabels,
    },
  }))
    .then(({ data }) => {
      return getFieldsLabelsMap(data.addFieldLabels);
    });
};

export const deleteFieldsLabels = async (fieldsLabels) => {
  return API.graphql(graphqlOperation(deleteFieldsLabelsMutation, {
    input: {
      fields: fieldsLabels,
    },
  }))
    .then(({ data }) => {
      return getFieldsLabelsMap(data.deleteFieldLabels);
    });
};
