import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import i18n from 'i18next';
import isValid from 'date-fns/isValid';
import differenceInDays from 'date-fns/differenceInDays';
import Typography from '@material-ui/core/Typography';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';

import PopupHeader from '../../../../../components/Popups/PopupHeader';
import Button from '../../../../../components/Button';
import Datepicker from '../../../../../components/Datepicker';
import BackdropLoading from '../../../../../components/Loading/BackdropLoading';
import { requestMetadata } from '../../../../planetMetadata/planetMetadataSlice';

import './index.scss';

const validateRange = (dateFrom, dateTill) => {
  const result = {
    isValid: true,
    message: '',
  };

  if (!isValid(dateFrom)) {
    result.isValid = false;
  }

  if (!isValid(dateTill)) {
    result.isValid = false;
  }

  const period = differenceInDays(dateTill, dateFrom);

  if (period > 15) {
    result.isValid = false;
    result.message = i18n.t('general.popups.planet-metadata-request.errors.maximum-range', {
      period,
    });
  }

  return result;
};

/**
 * @param {Object} params
 * @param {Object} params.field
 * @param {string} params.field.uuid
 * @param {string} params.field.farmUuid
 * @param {string} params.field.name
 * @param {() => void} params.onConfirm
 * @param {() => void} params.onCancel
 */
const PlanetMetadataRequestPopup = ({
  field,
  onConfirm,
  onCancel,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [dateFrom, setDateFrom] = useState(new Date());
  const [dateTill, setDateTill] = useState(new Date());
  const [isLoading, setIsLoading] = useState(false);
  const validationResult = validateRange(dateFrom, dateTill);

  const handleRequest = () => {
    setIsLoading(true);
    dispatch(requestMetadata({
      field,
      startDate: dateFrom,
      endDate: dateTill,
    }))
      .then(() => {
        onConfirm();
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  return (
    <Dialog
      open
      classes={{
        paper: 'planet-metadata-request',
      }}
    >
      <PopupHeader
        title={t('general.popups.planet-metadata-request.title')}
        onCancel={onCancel}
      />
      <DialogContent>
        <DialogContentText className="planet-metadata-request__content-text">
          {t('general.popups.planet-metadata-request.description')}
        </DialogContentText>
        <div className="planet-metadata-request__date-range">
          <Datepicker
            title={t('credits-usage.date-from')}
            value={dateFrom}
            maxDate={dateTill}
            onChange={(value) => setDateFrom(value)}
          />
          <Datepicker
            title={t('credits-usage.date-till')}
            value={dateTill}
            minDate={dateFrom}
            maxDate={new Date()}
            onChange={(value) => setDateTill(value)}
          />
        </div>
        {
          validationResult.message
          && (
            <Typography
              variant="body2"
              className="planet-metadata-request__error-message"
            >
              {validationResult.message}
            </Typography>
          )
        }
      </DialogContent>
      <DialogActions className="planet-metadata-request__actions">
        <Button
          variant="outlined"
          onClick={onCancel}
        >
          {t('general.controls.cancel')}
        </Button>
        <Button
          variant="contained"
          color="primary"
          disabled={!validationResult.isValid}
          onClick={handleRequest}
        >
          {t('general.controls.request')}
        </Button>
      </DialogActions>
      {isLoading && <BackdropLoading />}
    </Dialog>
  );
};

export default PlanetMetadataRequestPopup;
