import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Legend from '../../../../../components/Legend';
import AssetLegendContent from '../../../../../components/Legend/AssetLegendContent';
import { sendFeatureUsage } from '../../../../../helpers/analytics';
import { isRequiredDataLoaded } from '../../../../../helpers/components/legend';
import { PAGES_ROOTS } from '../../../../../helpers/navigation';
import { setLegend } from '../../crossLayerSlice';
import { fetchVamapAssets } from '../../../../field/fieldSlice';
import { selectLegend } from '../../crossLayerSelectors';
import {
  selectField,
  selectFullyLoaded,
} from '../../../../field/fieldSelectors';
import { selectApiKey, selectAreaUnit } from '../../../../user/userSelectors';

const CrossLayerLegend = ({
  isOpen,
  legend,
  loading,
  field,
  areaUnit,
  apiKey,
  onLegendClose,
  requestAssets,
}) => {
  const type = (legend || {})._type;

  useEffect(() => {
    if (!type) {
      return;
    }

    sendFeatureUsage('legend', 'view', {
      type,
      screen: PAGES_ROOTS.zonesOps,
    });
  }, [type]);

  useEffect(() => {
    if (!isOpen) {
      return;
    }

    requestAssets(legend, field);
  }, [isOpen, legend]); // eslint-disable-line react-hooks/exhaustive-deps

  if (!isOpen) {
    return null;
  }

  return (
    <Legend
      loading={loading}
      title={legend.name}
      content={(
        <AssetLegendContent
          item={legend}
          field={field}
          areaUnit={areaUnit}
          apiKey={apiKey}
        />
      )}
      onClose={onLegendClose}
    />
  );
};

const mapStateToProps = (state) => {
  const legend = selectLegend(state);
  const field = selectField(state);
  const loading = !isRequiredDataLoaded(legend, field, selectFullyLoaded(state));

  return {
    isOpen: !!legend,
    loading,
    legend,
    field,
    areaUnit: selectAreaUnit(state),
    apiKey: selectApiKey(state),
  };
};

const mapDispatchToProps = (dispatch) => ({
  onLegendClose: () => {
    dispatch(setLegend(null));
  },
  requestAssets: (vamap, field) => {
    dispatch(fetchVamapAssets({
      farmUuid: field.farmUuid,
      fieldUuid: field.uuid,
      vamap,
    }));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(CrossLayerLegend);
