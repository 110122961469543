import {
  getRootLink,
  getSearchParam,
  PAGES_ROOTS,
} from '../../../../../helpers/navigation';
import { View } from '../constants/authentication';
import { SEARCH_PARAMS } from '../constants/navigation';

export const getDefaultRoute = () => {
  return getRootLink(PAGES_ROOTS.homepage);
};

export const getCode = (search) => {
  return getSearchParam(search, SEARCH_PARAMS.code);
};

export const getEmail = (search) => {
  return getSearchParam(search, SEARCH_PARAMS.email);
};

export const getRedirectedFrom = (search) => {
  return getSearchParam(search, SEARCH_PARAMS.redirectedFrom);
};

export const buildRedirectedFrom = (value) => {
  return `?${SEARCH_PARAMS.redirectedFrom}=${value}`;
};

export const isSignUpRelatedView = (view) => {
  return view === View.signUp
    || view === View.signUpSent
    || view === View.signUpConfirm;
};
