export const getFieldsFilter = ({
  pageSize,
  filter,
  lastEvaluatedKey,
  areaUnit,
  farm,
}) => {
  return {
    pageSize,
    filter,
    lastEvaluatedKey,
    areaUnit,
    farmUuid: (farm || {}).uuid,
  };
};
