import { reset } from '../../createEquationBasedAnalysisSlice';
import { selectIsCurrentEquationSelected } from '../../../../equations/equationsSelectors';
import { getSteps } from '../../helpers/functions/steps';
import { useAppDispatch, useAppSelector } from '../../../../../app/store/helpers/functions';
import useStepComponent from '../../../zonesOps/hooks/useStepComponent';

export default function StepperEquationBased() {
  const dispatch = useAppDispatch();

  const equationSelected = useAppSelector(selectIsCurrentEquationSelected);

  const stepComponent = useStepComponent({
    getStepsFn: getSteps(equationSelected),
    resetFn: () => {
      dispatch(reset());
    },
  });

  return stepComponent;
}
