import React from 'react';
import {
  useTranslation,
  Trans,
} from 'react-i18next';

import { getContactsLink } from '../../../../../helpers/functions/utils/appConfig';
import Button from '../../../../../components/Button';

import './index.scss';

const AreaLimitReachedNotification = ({
  usedArea,
  maxArea,
}:{
  usedArea: number;
  maxArea: number;
}) => {
  const { t } = useTranslation();

  return (
    <div className="area-limit-error">
      <div>
        <div className="area-limit-error__title">
          <Trans i18nKey="general.area-limit-error.title">
            You have exceeded the field HA/AC limit (used
            <span className="area-limit-error__title-highlighted">{{ usedArea: usedArea.toFixed(2) }}</span>
            of {{ maxArea: maxArea.toFixed(2) }}).
          </Trans>
        </div>
        <div className="area-limit-error__description">
          {t('general.area-limit-error.jd-import.description')}
        </div>
      </div>
      <Button
        variant="contained"
        color="primary"
        component="a"
        href={getContactsLink()}
        target="_blank"
        classes={{
          root: 'area-limit-error__upgrade-link',
        }}
      >
        {t('general.area-limit-error.jd-import.upgrade')}
      </Button>
    </div>
  );
};

export default AreaLimitReachedNotification;
