import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';

import LogoHugeIcon from '../Icons/logoHuge.svg';
import Button from '../../../../../components/Button';
import { PAGES_ROOTS, getRootLink } from '../../../../../helpers/navigation';

import './index.scss';

export default function WelcomePanel() {
  const { t } = useTranslation();

  return (
    <div className="welcome-panel">
      <div className="welcome-panel__bg-image"></div>
      <Paper className="welcome-panel-card" square>
        <div>
          <LogoHugeIcon />
          <Typography className="welcome-panel-card__title">
            {t('order-status.paid.title')}
          </Typography>
          <Typography className="welcome-panel-card__info">
            {t('order-status.paid.info')}
          </Typography>
          <Button
            className="welcome-panel-card__action-button"
            variant="contained"
            color="primary"
            component={Link}
            to={getRootLink(PAGES_ROOTS.createField)}
          >
            {t('order-status.draw-field')}
          </Button>
          <Button
            className="welcome-panel-card__action-button"
            variant="contained"
            color="primary"
            component={Link}
            to={getRootLink(PAGES_ROOTS.upload)}
          >
            {t('order-status.upload-fields')}
          </Button>
        </div>
      </Paper>
    </div>
  );
}
