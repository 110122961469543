import { API, graphqlOperation } from '@aws-amplify/api';

import saveUserDataMutation from './graphql/mutations/saveUserData.gql';
import saveUserDetailsMutation from './graphql/mutations/saveUserDetails.gql';
import getUserDataQuery from './graphql/queries/getUserData.gql';
import getUserTotalAreaQuery from './graphql/queries/getUserTotalArea.gql';
import getUserOrdersQuery from './graphql/queries/getUserOrders.gql';
import saveOrganizationMutation from './graphql/mutations/saveOrganization.gql';

/**
 *
 * @param {Object} data
 * @param {string} [data.identity]
 * @param {string} [data.language]
 * @param {string} [data.areaUnit]
 * @param {string} [data.acceptedTermsAndConditions]
 */
export const saveData = async ({
  identity,
  language,
  areaUnit,
  acceptedTermsAndConditions,
}) => {
  return API.graphql(graphqlOperation(saveUserDataMutation, {
    input: {
      ...(identity ? { identity } : null),
      ...(language ? { language } : null),
      ...(areaUnit ? { areaUnit } : null),
      ...(acceptedTermsAndConditions ? { acceptedTermsAndConditions } : null),
    },
  }))
    .then(({ data }) => {
      return data.saveUserData;
    });
};

export const saveDetails = async (details) => {
  return API.graphql(graphqlOperation(saveUserDetailsMutation, {
    input: details,
  }))
    .then(({ data }) => {
      return data.saveUserDetails;
    });
};

export const getAllData = async () => {
  return API.graphql(graphqlOperation(getUserDataQuery))
    .then(({ data }) => {
      return data.getUserData;
    });
};

export const getOrders = async () => {
  return API.graphql(graphqlOperation(getUserOrdersQuery))
    .then(({ data }) => {
      return data.getUserData.orders;
    });
};

export const saveOrganization = async (anOrganization) => {
  return API.graphql(graphqlOperation(saveOrganizationMutation, {
    input: anOrganization,
  }))
    .then(({ data }) => {
      return data.saveOrganization;
    });
};

export const getUserTotalArea = async () => {
  return API.graphql(graphqlOperation(getUserTotalAreaQuery))
    .then(({ data }) => {
      return data.getUserData;
    });
};
