import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Auth } from '@aws-amplify/auth';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import EmailSharpIcon from '@material-ui/icons/EmailSharp';
import LockSharpIcon from '@material-ui/icons/LockSharp';

import TwoSidePanel from '../../../../components/TwoSidePanel';
import Button from '../../../../../../../components/Button';
import TextField from '../../../../../../../components/TextField';
import Link from '../../../../../../../components/Link';
import FederatedLogInPanel from '../common/FederatedLogIn';
import { getAuthLink } from '../../../../../../../helpers/navigation';
import { errorNotify } from '../../../../../../notifications/helpers/functions/notify';
import { CustomError } from '../../../../../../../helpers/functions/utils/errorHandling';
import { AuthChallenge, View } from '../../../../helpers/constants/authentication';
import { LOG_IN_ERRORS } from '../../../../helpers/constants/errors';
import {
  buildRedirectedFrom,
  getDefaultRoute,
  getRedirectedFrom,
} from '../../../../helpers/functions/navigation';
import { prepareEmailAttribute } from '../../../../helpers/functions/authentication';
import { isSignUpEnabled } from '../../../../../../../helpers/functions/utils/appConfig';

import '../common/index.scss';
import './index.scss';

const LogInPanel = ({
  onLoading,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const isSignUpAllowed = isSignUpEnabled();

  const [passwordVisible, setPasswordVisible] = useState(false);
  const [formState, setFormState] = useState({
    password: '',
    email: '',
  });
  const [formErrors, setFormErrors] = useState({
    password: '',
    email: '',
  });

  const handlePasswordInputButtonClick = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleFieldChange = (field, value) => {
    setFormState((prevFormState) => {
      return {
        ...prevFormState,
        [field]: value,
      };
    });
    setFormErrors((prevFormErrors) => {
      return {
        ...prevFormErrors,
        [field]: '',
      };
    });
  };

  const onLogInClick = () => {
    const preparedEmail = prepareEmailAttribute(formState.email);

    onLoading(true);
    setFormErrors({
      password: '',
      email: '',
    });

    Auth.signIn(preparedEmail, formState.password)
      .then((user) => {
        const redirectedFrom = getRedirectedFrom(location.search);

        if (user.challengeName === AuthChallenge.newPasswordRequired) {
          onLoading(false);
          history.push({
            pathname: getAuthLink(View.newPassword),
            search: redirectedFrom ? buildRedirectedFrom(redirectedFrom) : '',
            state: {
              user,
            },
          });
        } else {
          const url = redirectedFrom || getDefaultRoute();

          history.replace(url);
        }
      })
      .catch((e) => {
        const error = LOG_IN_ERRORS.find((err) => {
          return err.regexp.test(e.message);
        });

        onLoading(false);

        if (error) {
          setFormErrors((prevFormErrors) => {
            return {
              ...prevFormErrors,
              [error.field]: error.helperText,
            };
          });
        } else {
          errorNotify({
            error: new CustomError('[UI Login] Unable to login user.', {
              cause: e,
            }),
            message: e.message,
            dispatch,
          });
        }
      });
  };

  const logInButtonDisabled = () => {
    const hasRequiredFields = formState.password && formState.email;
    const hasErrors = Object.values(formErrors)
      .some((error) => {
        return error.length !== 0;
      });

    return !hasRequiredFields || hasErrors;
  };

  const renderForm = () => {
    return (
      <form className="log-in-panel__form">
        <Typography
          classes={{
            root: 'authentication-panel__tip',
          }}
        >
          Use your email and password
        </Typography>
        <TextField
          value={formState.email}
          error={!!formErrors.email}
          helperText={formErrors.email}
          placeholder="Email"
          InputProps={{
            classes: {
              error: 'textfield__input_error',
            },
            startAdornment: <EmailSharpIcon
              fontSize="small"
              className="authentication-panel__input-icon authentication-panel__input-icon_start"
                            />,
          }}
          FormHelperTextProps={{
            classes: {
              root: 'authentication-panel__input-helper-text',
            },
          }}
          onChange={(e) => handleFieldChange('email', e.target.value)}
        />
        <TextField
          value={formState.password}
          error={!!formErrors.password}
          helperText={formErrors.password}
          placeholder="Password"
          type={passwordVisible ? 'text' : 'password'}
          InputProps={{
            classes: {
              adornedEnd: 'textfield__input_adorned-end',
              error: 'textfield__input_error',
            },
            startAdornment: <LockSharpIcon
              fontSize="small"
              className="authentication-panel__input-icon authentication-panel__input-icon_start"
                            />,
            endAdornment: (
              <IconButton
                className="authentication-panel__input-icon-button"
                size="small"
                disableRipple
                onClick={handlePasswordInputButtonClick}
              >
                {
                  passwordVisible
                    ? (
                      <VisibilityOffIcon
                        fontSize="small"
                        className="authentication-panel__input-icon"
                      />
                    )
                    : (
                      <VisibilityIcon
                        fontSize="small"
                        className="authentication-panel__input-icon"
                      />
                    )
                }
              </IconButton>
            ),
          }}
          FormHelperTextProps={{
            classes: {
              root: 'authentication-panel__input-helper-text',
            },
          }}
          onChange={(e) => handleFieldChange('password', e.target.value)}
        />
        <Link
          className="authentication-panel__link log-in-panel__link"
          to={getAuthLink(View.forgotPassword)}
        >
          Forgot password?
        </Link>
        <Button
          data-test="log-in-button"
          className="authentication-panel__button"
          variant="contained"
          color="primary"
          disabled={logInButtonDisabled()}
          onClick={onLogInClick}
        >
          Log in
        </Button>
        {isSignUpAllowed
          && (
            <div className="authentication-panel__account-option">
              <Typography
                className='authentication-panel__tip'
              >
                Don’t have an account?
              </Typography>
              <Link
                className="authentication-panel__link"
                to={getAuthLink(View.signUp)}
              >
                Sign up
              </Link>
            </div>
          )}
      </form>
    );
  };

  const renderPanel = () => {
    if (isSignUpAllowed) {
      return (
        <TwoSidePanel
          left={<FederatedLogInPanel />}
          right={renderForm()}
        />
      );
    }
    return renderForm();
  };

  return (
    <div className="authentication-panel log-in-panel">
      <Typography
        classes={{
          root: 'authentication-panel__header',
        }}
        variant="h1"
      >
        Log in
      </Typography>
      {renderPanel()}
    </div>
  );
};

export default LogInPanel;
