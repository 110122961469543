import { createSelector } from '@reduxjs/toolkit';

import { RootState } from '../../../app/store/helpers/types';
import { LoadStatus } from '../../../helpers/constants/utils/loadStatus';

export const selectType = ({ createBatchEquationBasedAnalysis }: RootState) => {
  return createBatchEquationBasedAnalysis.type;
};

export const selectGridSize = ({ createBatchEquationBasedAnalysis }: RootState) => {
  return createBatchEquationBasedAnalysis.gridSize;
};

export const selectEquationMapsData = ({ createBatchEquationBasedAnalysis }: RootState) => {
  return createBatchEquationBasedAnalysis.equationMapsData;
};

export const selectUseInterpolatedData = ({ createBatchEquationBasedAnalysis }: RootState) => {
  return createBatchEquationBasedAnalysis.useInterpolatedData;
};

export const selectEquationMapsDataByStatuses = createSelector(
  selectEquationMapsData,
  (_: RootState, statuses?: LoadStatus[]) => statuses,
  (equationMapsData, statuses) => {
    const allowedStatuses = new Set(statuses ?? [
      LoadStatus.loading,
      LoadStatus.success,
      LoadStatus.error,
    ]);

    return Object.values(equationMapsData)
      .filter((equationMapData) => {
        return equationMapData.uuid && allowedStatuses.has(equationMapData.generationStatus);
      });
  },
);

export const selectEquationMapData = createSelector(
  selectEquationMapsData,
  (_: RootState, fieldUuid: string) => fieldUuid,
  (equationMapsData, fieldUuid) => equationMapsData[fieldUuid],
);

export const selectListFilterSearchTerm = ({ createBatchEquationBasedAnalysis }: RootState) => {
  return createBatchEquationBasedAnalysis.listFilter.searchTerm;
};

export const selectListFilterFarmUuid = ({ createBatchEquationBasedAnalysis }: RootState) => {
  return createBatchEquationBasedAnalysis.listFilter.farmUuid;
};

export const selectListFilterSortOrder = ({ createBatchEquationBasedAnalysis }: RootState) => {
  return createBatchEquationBasedAnalysis.listFilter.sortOrder;
};

export const selectEditMode = ({ createBatchEquationBasedAnalysis }: RootState) => {
  return createBatchEquationBasedAnalysis.editMode;
};

export const selectFullScreenEditor = ({ createBatchEquationBasedAnalysis }: RootState) => {
  return createBatchEquationBasedAnalysis.fullScreenEditor;
};

export const selectTitleTemplate = ({ createBatchEquationBasedAnalysis }: RootState) => {
  return createBatchEquationBasedAnalysis.titleTemplate;
};
