import React from 'react';
import { useTranslation } from 'react-i18next';

import Autocomplete from '../../../../components/Autocomplete';
import AntSwitch from '../../../../components/AntSwitch';
import FarmSelect from '../../../farms/containers/FarmSelect';
import { useAppDispatch, useAppSelector } from '../../../../app/store/helpers/functions';
import { fetchLabels } from '../../../labels/labelsSlice';
import {
  transformFilterUpdates,
  transformFilterValue,
} from '../../../../helpers/components/autocomplete';
import { selectLabelsMap } from '../../../labels/labelsSelectors';
import { selectFarm, selectFilter } from '../../fieldsListSelectors';
import { selectLabelsExpanded } from '../../../ui/fieldProfiler/fieldProfilerSelectors';
import { fetchFields } from '../../fieldsListSlice';
import { setLabelsExpanded } from '../../../ui/fieldProfiler/fieldProfilerSlice';
import type { AutocompleteItem } from '../../../ui/autocomplete/types/autocomplete';
import type { TransformedFarm } from '../../../farms/types/farm';
import { sendFeatureUsage } from '../../../../helpers/analytics';

import './index.scss';

export default function FieldsListFilter({
  withLabelsExpandSwitcher = true,
  withCreateFarm,
}: {
  withLabelsExpandSwitcher?: boolean;
  withCreateFarm?: boolean;
}) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  // @ts-expect-error
  const labelsMap = useAppSelector(selectLabelsMap);
  const farm = useAppSelector(selectFarm);
  const filter = useAppSelector(selectFilter);
  const labelsExpanded = useAppSelector(selectLabelsExpanded);

  const handleGetLabelsOptions = () => {
    dispatch(fetchLabels());
  };

  const handleFilterChange = (value: AutocompleteItem[]) => {
    const filterUpdates = transformFilterUpdates(value, labelsMap);

    sendFeatureUsage('fields', 'search', {
      labels: (filterUpdates.labels || []).length,
      name: filterUpdates.name != null,
    });

    dispatch(fetchFields({
      filter: filterUpdates,
    }));
  };

  const handleFarmChange = (value: TransformedFarm | null) => {
    dispatch(fetchFields({
      farm: value,
    }));
  };

  const handleLabelsSwitchClick = (checked: boolean) => {
    dispatch(setLabelsExpanded(checked));
  };

  return (
    <div className="fields-list-filter">
      <FarmSelect
        withCreateFarm={withCreateFarm}
        selectedFarmUuid={farm?.uuid}
        onFarmChange={handleFarmChange}
      />
      <Autocomplete
        limitTags={4}
        placeholder={t('general.controls.filter-by-label-or-name')}
        placeholderWithValue={t('field-profiler.tools-panel.add-filter')}
        searchLabel={t('field-profiler.tools-panel.search')}
        allValuesLabel={t('field-profiler.tools-panel.all-values')}
        emptyLabel={t('field-profiler.tools-panel.empty')}
        options={labelsMap}
        value={transformFilterValue(filter)}
        classes={{
          root: 'fields-list-filter__labels',
        }}
        data-test="labels-filter"
        onChange={handleFilterChange}
        onGetOptions={handleGetLabelsOptions}
      />
      {
        withLabelsExpandSwitcher
        && (
          <div className="fields-list-filter__switch-wrapper">
            <AntSwitch
              isChecked={labelsExpanded}
              label={t('field-profiler.tools-panel.expand-all-labels')}
              onValueChange={handleLabelsSwitchClick}
            />
          </div>
        )
      }
    </div>
  );
}
